.wrapper {
  pointer-events: all !important;
  cursor: pointer !important;
  transition: all 300ms;
}

.wrapper:hover {
  transform: scale(1.1) !important;
}

.subtitle {
  min-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
