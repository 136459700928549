.backgroundImage {
  background-image: url('/images/policyimage-p-600.jpg');
  background-position: left center;
  background-size: cover;
  min-height: 470px;
}

.disclaimer {
  margin-top: 20px;
  color: #707070;
  font-family: 'Outfit', sans-serif;
  font-size: 13px;
  font-style: italic;
  padding: 0 10px;
}
@media (min-width: 1400px) {
  .backgroundImage {
    min-height: 600px;
  }
}
@media (min-width: 1800px) {
  .backgroundImage {
    min-height: 700px;
  }
}
@media (min-width: 2100px) {
  .backgroundImage {
    background-position: center 35%;
  }
}
@media (max-width: 479px) {
  .backgroundImage {
    min-height: 300px !important;
    height: 300px;
  }
}
@media (max-width: 375px) {
  .backgroundImage {
    min-height: 250px !important;
    height: 250px;
  }
}

.otherTabContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  margin-top: 16px;
}

@media (min-width: 991px) {
  .otherTabContent {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.otherSection {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  font-weight: bold;
  margin-bottom: 8px;
}

.otherSection p {
  font-size: 16px;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .otherSection p {
    font-size: 18px;
  }
}
