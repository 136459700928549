.af-view .af-class-phase-1-header .af-class-p2-header-content {
  max-height: 60%;
  overflow: auto;

  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 991px) and (min-width: 480px) {
  .af-view .af-class-p2-header-content {
    position: relative;
    left: 0%;
    top: auto;
    width: 100%;
    transform: translate(0px, 44px);
  }

  .af-view .af-class-phase-2-dev-phase {
    position: relative;
    width: 100%;
    transform: translate(0px, 35%);
  }
  .af-class-phase-1-header {
    height: 100vh;
    min-height: 500px;
    margin-bottom: 75px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
@media (max-width: 479px) {
  .af-class-p2-header-content {
    margin-top: -130px;
  }
}
@media (min-width: 992px) and (max-width: 1075px) {
  .af-class-p2-header-content {
    transform: translate(0px, 0px) !important;
  }
}
@media (min-width: 992px) {
  .af-class-p2-header-content {
    overflow: scroll;
  }
}
@media (min-width: 480px) {
  .af-view .af-class-phase-1-header {
    min-height: 800px;
  }
}
