ul.af-class-list-compact-list-container.w-list-unstyled > span {
  flex: 1 1 auto !important;
  height: auto !important;
}
@media (max-width: 767px) {
  .af-class-spotlight-billlboard-list-container {
    align-items: center !important;
  }
  .af-class-spotlight-bb-list-benefit-list {
    padding: 0 20px !important;
  }
}
@media (max-width: 991px) {
  .af-class-spotlight-bb-list-benefit-list {
    justify-content: space-around !important;
  }
  .af-view .af-class-div-block-42 {
    flex-basis: auto;
  }
}
