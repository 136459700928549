.notselected {
  box-shadow: none !important;
  cursor: pointer;
  transition: 125ms;
}

.notselected:hover {
  background-color: rgba(155, 155, 155, 0.12);
  opacity: 0.8;
}

.disabled {
  opacity: 0.6;
  cursor: auto;
}

.disabled:hover {
  background-color: white;
  opacity: 0.6;
}
.tab {
  text-align: center;
}
.comingSoon {
  font-weight: 900;
}
