@media (max-width: 479px) {
  .af-view .af-class-container-2.w-container {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: none;
    padding-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="number"],
  input[type="email"] {
    font-size: 16px !important;
  }
}

body {
  overflow-x: hidden;
}