.eventTitle {
  color: #76b535 !important;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 15px;
}
.mobileImage {
  display: none;
}
.mainImage {
  min-width: 250px;
  /* width: auto; */
}
@media (max-width: 767px) {
  .topWrapper {
    padding-top: 20px !important;
    padding-right: 30px !important;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .mobileImage {
    display: inline-block;
    order: 1;
  }
  .mainImage {
    display: none;
  }
}
@media (max-width: 767px) and (-ms-high-contrast: none) {
  .mobileImage {
    max-height: 500px;
  }
}
@media (max-width: 479px) and (-ms-high-contrast: none) {
  .mobileImage {
    max-height: 300px;
  }
}

