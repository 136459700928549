.explore,
.header,
.wrapper {
  pointer-events: all !important;
}

.listContainer {
  max-height: 300px;
  overflow-y: scroll;
}
.wrapper {
  position: relative;
  z-index: 100;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100vw !important;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
  width: 360px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.4);
}

@media(min-width: 721px) {
  .wrapper {
    display: none;
  }
}

