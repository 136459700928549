.button {
  position: relative;
}

.button > .buttonText {
  transform: translate(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}

.button:hover > .buttonArrow {
  position: absolute;
  right: 5px;
  left: 85%;
}
.subtitle {
  color: #4c698f !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
  line-height: 12px !important;
  margin-bottom: -5px !important;
  text-transform: uppercase !important;
}
.otherImage {
  min-height: 400px;
}
.title > span {
  font-size: 38px !important;
}

@media (max-width: 479px) {
  .otherImage {
    min-height: auto;
  }
  .title {
    font-size: 38px !important;
    margin-bottom: 30px !important;
    margin-top: 10px !important;
  }
}
