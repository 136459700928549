.toolTipContainer {
    display: inline-block;
    position: relative;
}

.toolTipTextTop {
    display: block;
    position: absolute;
    bottom: 45px;
    left: -70px;
    width: 300px;
    border: 1px solid #727272;
    border-radius: 5px;
    z-index: 3;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 400;
    color: #3f3f3f;
    background: white;
}
.toolTipTextBottom {
    display: block;
    position: absolute;
    top: 35px;
    right: 2px;
    width: 300px;
    border: 1px solid #727272;
    border-radius: 5px;
    z-index: 3;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 400;
    color: #3f3f3f;
    background: white;
}
.toolTipTextMobile {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    border: 1px solid #727272;
    border-radius: 5px;
    z-index: 10001;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 400;
    color: #3f3f3f;
    background: white;
}
.toolTipArrowTop {
    display: block;
    position: absolute;
    bottom: 35px;
    width: 20px;
    height: 20px;
    z-index: 3;
    border-left: 1px solid #787878;
    border-bottom: 1px solid #787878;
    transform: rotate(-45deg);
    background: white;
}
.toolTipArrowBottom {
    display: block;
    position: absolute;
    top: 25px;
    left: 30px;
    width: 20px;
    height: 20px;
    z-index: 3;
    border-right: 1px solid #787878;
    border-top: 1px solid #787878;
    transform: rotate(-45deg);
    background: white;
}
.toolTipClose {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 25px;
    transition: all .15s;
    cursor: pointer;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: gray;
    opacity: .8;
    z-index: 10000;
}
/* .noScroll {
    position: fixed !important;
} */