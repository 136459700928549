.blogTag {
  background-color: #E5EDF8;
  display: inline-block;
  margin-right: 15px;
}
.tagsContainer {
  all: unset !important;
  width: 100% !important;
}
.textInput {
  background-color: transparent;
  border: none;
  width: 85%;
  text-align: center;
}

.magnifyingGlass {
  width: 15px;
  height: auto;
}
.dropdown {
  justify-content: center !important;
  border: unset !important;
  margin-left: unset !important;
}
.searchBar {
  margin-bottom: 5px !important;
}
.featuredPostContainer {
  cursor: pointer;
  max-height: 70%;
  overflow: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media (min-width: 991px) {
  .searchBar {
    padding-bottom: 5px !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .dropdown {
    width: 180px !important;
  }
  .magnifyingGlass, .textInput {
    transform: translateY(-3px);
  }
  .searchBar {
    width: 20% !important;
    border-radius: 20px!important;
  }
}

@media (max-width: 479px) {
  .featuredPostContainer {
    width: 100%!important;
    border-radius: 0!important;
    bottom: 0!important;
    background-color: white !important;
  }
  .searchBar {
    margin-top: 20px;
  }
  .af-class-blog-card--featured
  .af-class-blog-card--publish-info  {
    flex-direction: row !important;
  }
  .featuredPostContainer {
    cursor: pointer;
    max-height: 100%;
    overflow: visible
  }
}

.mobileHeaderImage {
  height: 200px;
  width: 100%;
}
@media (min-width: 479px) {
  .mobileHeaderImage {
    display: none;
  }
}
@media (min-width: 480px) and (max-width: 991px) {
  .mobileHeaderImage {
  transform: translate(0px, 44px);
  height: 380px;
  }
}
