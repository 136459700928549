.listContainer {
  align-items: flex-start;
}
.quotesWrapper {
  align-self: center;
}

.listContainer > span {
    margin: 15px 15px 15px 15px;
}
.emptyContainer {
  justify-content: center;
  max-width: none !important;
  align-self: center;
}
.emptyTitle {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 24px;
}
.emptyTextBlurb {
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 300;
}
.emptyButton {
  padding: 8px 15px;
  border-radius: 3px;
  background: #7ba4da;
  color: white;
  align-items: baseline;
  display: flex;
  width: max-content;
  margin: 0 auto;
}
.emptyContentWrapper {
  padding: 60px;
  background: #fafafa;
  max-width: 90%;
  text-align: center;
}
.emptyContainer .listContainer {
  justify-content: center;
}
.buttonArrow {
  width: 7px !important;
  margin-left: 10px;
}
.emptyButton:hover {
  background: #628BC1;
}
@media (max-width: 767px) {
  .emptyContentWrapper {
    padding: 40px;
  }
  .emptyTitle {
    line-height: 28px;
  }
}

@media (max-width: 479px) {
  .emptyContainer {
    margin-bottom: 30px;
  }
}
