@media print {
  /* CONTAINERS */
  .container_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 40px;
    min-width: 350px;
    font-family: 'Outfit', sans-serif;
  }

  /* ROWS */
  .row_1_1 {
    display: flex;
    border-bottom: 1px solid #d2d2d2;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 100px !important;
    padding-bottom: 10px;
  }

  .row_1_2 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-height: 250px;
  }

  .row_1_3 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-height: 400px;
    margin-bottom: 10px;
  }

  .row_1_4 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  /* COLUNMS */

  /* CONTENT ROW 1*/
  .logo {
    /* height: 100px !important; */
    max-width: 20%;
  }
  .content_1_1_1_apartment_address {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Outfit', sans-serif;
    color: #3f3f3f;
    text-decoration: none;
    padding: 20px;
  }
  .addressRow {
    font-size: 16px;
    margin-bottom: 0px;
    display: block;
  }
  .addressPhone {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 5px;
    display: block;
  }
  /* CONTENT ROW 2*/
  .content_1_2_1_apartment_greeting {
    width: 65%;
    font-family: 'Outfit', sans-serif;
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .content_1_2_2_apartment_specials_1 {
    color: #333;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    width: 30%;
    padding: 25px 15px 15px;
    flex: 0 1 auto;
    border-radius: 3px;
    background-color: #fff;
  }

  .specialsLabel {
    display: flex;
    width: auto;
    align-items: center;
    border-radius: 20px;
    background-color: #09c18a;
    color: #fff;
    margin: 0 0 5px;
    padding: 6px 6px 6px 12px;
    max-width: 160px;
    justify-content: flex-start;
  }
  .specialsImg {
    width: 20px;
  }
  .content_1_2_2_apartment_specials_1_1 {
    font-size: 14px;
    line-height: 25px;
    color: #3f3f3f;
    font-weight: 400;
    display: block;
  }

  .content_1_2_2_apartment_specials_1_2 {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  /* CONTENT ROW 3*/
  .floorplanCard {
    width: 60%;
    max-height: 400px;
    border: 1px solid red;
  }
  .leaseDetails {
    width: 50%;
    border: 1px solid red;
  }

  .paymentBreakdown {
    width: 50%;
    height: 400px;
    border: 1px solid red;
  }

  .leaseAddOns {
    width: 50%;
    height: 400px;
    border: 1px solid red;
  }
  .paymentHeader {
    flex: 1 1;
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
  .bottomColumnLeft {
    width: 50%;
    padding-right: 20px;
    border-right: 1px solid #d2d2d2;
  }
  .bottomColumnRight {
    width: 50%;
    padding-left: 30px;
  }
}
