.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}
.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  position: absolute;
  right: 30px;
  left: 80%;
}
.highlight::after {
  top: 52%;
}
.button:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .button {
    position: absolute !important;
    right: 5%;
    margin-top: 5px !important;
  }
}
@media (max-width: 767px) {
  .button {
    position: initial !important;
    left: initial !important;
    transform: translate(0) !important;
  }
}
@media (min-width: 1600px) {
  .button {
    position: relative !important;
    right: -20%;
    margin-top: 5px !important;
  }
}
@media (-ms-high-contrast: none) {
  .button {
    margin-top: -15px !important;
  }
}
