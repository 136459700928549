.wrapper {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  padding-bottom: 15px;
  max-width: 850px;
  margin: -75px auto;
}

.paddingWrapper {
  padding: 0 50px;
}

@media (max-width: 479px) {
  .paddingWrapper {
    padding: 0 30px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.otsdkshowsettings {
  text-decoration: underline !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  border: none !important;
  padding: unset !important;
  font-family: Outfit, sans-serif !important;
  color: #3f3f3f !important;
  font-size: 15px !important;
  line-height: 27px !important;
  padding-bottom: 15px !important;
  background-color: transparent !important;
}
