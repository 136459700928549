.af-class-initiative-descriptive-block.af-class-highlight:hover,
.af-class-initiative-descriptive-block.af-class-highlight:focus {
  cursor: pointer;
  overflow: hidden;
}
.af-class-initiative-descriptive-block.af-class-highlight:hover > div.af-class-initiative-block-small-heading,
.af-class-initiative-descriptive-block.af-class-highlight:focus > div.af-class-initiative-block-small-heading {
  font-size: 18px !important;
  line-height: 23px !important;
  margin-top: 10px !important;
}

.af-class-initiative-descriptive-block.af-class-highlight:hover > .af-class-paragraph.af-class-light,
.af-class-initiative-descriptive-block.af-class-highlight:focus > .af-class-paragraph.af-class-light {
  display: block;
}
@media all and (-ms-high-contrast:none) {
  .af-class-initiative-descriptive-block.af-class-highlight {
    min-height: 310px;
  }
  .af-view .af-class-initiative-grid-image-block {
    min-height: 310px;
  }
}
@media (max-width: 991px) and (-ms-high-contrast: none) {
  .af-view .af-class-div-block-86,
  .af-view .af-class-div-block-90 {
    flex-basis: auto;
  }
  .af-view .af-class-initiative-grid-heading-wrap {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .af-class-initiative-descriptive-block.af-class-highlight {
    overflow: hidden;
  }

  .af-class-initiative-descriptive-block.af-class-highlight > div.af-class-initiative-block-small-heading {
    font-size: 18px !important;
    line-height: 23px !important;
    margin-top: 10px !important;
  }

  .af-class-initiative-descriptive-block.af-class-highlight > .af-class-paragraph.af-class-light {
    display: block;
  }
}
