.af-class-initiative-grid-block {
    min-width: 290px;
}

.af-class-initiative-grid {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
}
.af-view .af-class-initiative-descriptive-block.af-class-highlight,
.af-view .af-class-initiative-header-block.af-class-highlight {
    box-shadow: inset 0 5px 0 0 #76B534;
}
.af-class-initiative-grid {
    padding-bottom: 0px !important;
    padding-top: 50px !important;
}

.af-class-initiative-grid-wrap > span:nth-child(2) > div > div {
    background-size: contain !important;
}
