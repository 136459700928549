.socialPropertyContactPage {
  width: 30px;
  height: 30px;
}

.container:focus {
  box-shadow: inset 0px 0px 0px 3px rgb(77, 144, 254);
  outline: none;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 125ms;
}
.container > img {
  height: 40px !important;
  width: 40px !important;
}

.container:hover {
  opacity: 0.8;
}

.label {
  color: white;
  white-space: nowrap;
  font-size: 26px;
  position: relative;
  bottom: 5px;
}

.icon {
  fill: white;
  height: 46px;
  width: 46px;
  margin-right: 10px;
}
