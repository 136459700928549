.af-class-grid-hover--card-content-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 300px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
}

.af-class-hover-grid-action-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(4, 3, 3, 0.82);
}

.af-class-grid-hover--card-content-wrap:hover
  .af-class-hover-grid-action-overlay,
.af-class-grid-hover-card-container:focus
  .af-class-grid-hover--card-content-wrap
  .af-class-hover-grid-action-overlay {
  opacity: 1;
}

.af-class-hover-grid-action-box-text {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}
.af-class-hover-grid-action-box-link {
  position: absolute;
  width: 100%;
  z-index: 1;
  color: #333;
  background-color: #ecd925;
  padding: 10px;
  text-align: center;
  display: block;
  font-weight: bold;
  bottom: 0;
}
.grid-hover-action-arrow {
  margin-left: 5px;
}

@media only screen and (max-width: 425px) {
  .af-class-grid-hover--card-content-wrap {
    height: 200px !important;
  }
}
