.floorplanCardContainer {
  max-width: 100% !important;
  border-radius: 10px !important;
}
.propertyName,
.clickableLink,
.apply,
.details {
  transition: all 125ms;
  cursor: pointer;
}
.notClickableLink:hover {
  cursor: default;
}
.propertyName:hover,
.clickableLink:hover,
.details:hover,
.apply:hover {
  opacity: 0.8;
}

.plant {
  object-fit: contain;
  width: 300px;
  height: 250px;
}

.unitName {
  text-align: right;
}
.apply {
  background-color: #018094;
}
.expiredContainer {
  width: 95%;
  margin: auto;
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: repeating-linear-gradient(
    -55deg,
    #fafafa,
    #eee 1px,
    #fafafa 3px,
    #fafafa 7px
  );
}

.expired {
  color: #686868;
  font-style: italic;
  font-weight: 700;
}

.propertyImage {
  min-width: 30%;
}
.notClickableLink p {
  margin-bottom: 0;
}

.details {
  align-items: center;
}
.buttonArrow {
  margin-left: 5px;
  margin-top: 2px;
  transition: transform 300ms ease-in-out;
}
.details:hover > .buttonArrow {
  transform: translateX(5px);
}
.bottomContainer > div {
  justify-content: center !important;
  width: 50%;
}
.beds,
.baths,
.sqft,
.floor {
  white-space: normal;
}
@media (max-width: 991px) {
  .propertyImage {
    flex-basis: auto !important;
  }
  .details {
    font-size: 14px !important;
  }
  .details > div:first-child {
    margin-top: 0px;
  }
}
@media (max-width: 767px) {
  .propertyImage {
      height: 200px;
  }
}

@media (max-width: 991px) and (-ms-high-contrast:none){
  .details {
    font-size: 12px !important;
  }
}
@media (max-width: 991px) and (-ms-high-contrast:none){
  .plant {
    width: 300px !important;
  }
}

@media (max-width: 479px) {
  .beds,
  .baths,
  .sqft,
  .floor {
    font-weight: 400 !important;
  }
  .quoteDataItem {
    width: 33% !important;
  }
  .bottomContainer {
    justify-content: space-between;
  }
  .bottomContainer > div {
    width: 50% ;
    height: 50px;
    justify-content: center !important;
  }
  .details {
    font-size: 12px !important;
  }
}
