.dropdownWrapper {
  padding: 0;
  margin: 1em 0 3em 0;
  width: fit-content;
  position: relative;
}

.dropdownHeader {
  padding: 1em;
  width: fit-content;
  font-size: 20px;
  border: 2px solid #909090;
  cursor: pointer;
  background-color: white;
  position: relative;
  z-index: 17;
}

.dropdownError {
  color: red;
  font-size: 14px;
  position: absolute;
  width: max-content;
  left: 0;
  top: -2em;
}

.dropdownArrow::before {
  font-family: 'webflow-icons' !important;
  color: #7ba4da;
  content: '\E603';
  margin-left: 0.5em;
  display: inline-block;
  transition: all 200ms;
}

.dropdownArrow[active='true']::before {
  transform: rotateZ(-180deg);
}

.dropdown {
  width: max-content;
  height: max-content;
  border: 2px solid #909090;
  min-width: 100%;
  list-style: none;
  position: absolute;
  background-color: white;
  overflow: hidden;
  transition: all 200ms;
  margin-top: -2px;
  z-index: 16;
  padding: 1em 1.5em 0.5em 1.5em;
  transform: translateY(-50%) scaleY(0);
}

.dropdown[active='true'] {
  transform: translateY(0) scaleY(100%);
}

.dropdownItem {
  margin: 0 0 0.5em 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropdownItemCustomCheckbox {
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  border: 2px solid #90a2b0;
  border-radius: 2px;
  display: inline-block;
  overflow: hidden;
}

.dropdownItemCustomCheckbox[ischecked='true'] {
  background-color: #90a2b0;
}

.dropdownItemCustomCheckbox[ischecked='true']::after {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  content: '✔';
  margin-top: -3px;
  font-size: 20px;
  line-height: 100%;
}

.dropdownItemLabel {
  display: inline-block;
  margin: 0 0 0 1em;
  font-size: 16px;
  font-weight: 400;
}

.capitalize {
  text-transform: capitalize;
}

@media only screen and (max-width: 720px) {
  .dropdownWrapper {
    width: 90%;
    padding: 0;
    margin: 3em 5%;
  }

  .dropdownHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown {
    min-width: unset;
    width: 100%;
  }
}
