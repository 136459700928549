.carousel .slide {
  background-color: transparent !important;
}

.af-class-poi_slide_next_arrow,
.af-class-poi_slide_prev_arrow {
  z-index: 1;
}

.af-class-slider-property-list-items,
.af-class-div-block-46 {
  width: 100%;
  display: block !important;
}
.af-class-div-block-46 {
  max-width: 600px !important;
}

sliderPropertyListItemViewList0 {
  display: flex;
  flex-wrap: wrap;
}
sliderpropertylistitemviewlist0 .af-class-slider-property-list-title {
  line-height: 30px !important;
}

sliderPropertyListItemViewList0 > span {
  flex: 1 0 50% !important;
  height: auto !important;
}
.af-class-slider-property-showcase .carousel .slide > div > img {
  height: 760px;
}
.af-class-slider-property-controls-wrapper {
  z-index: 9999;
}
.af-class-slider-property-headers {
  height: 565px;
  margin-top: 30px !important;
  padding-top: 40px !important;
}

.af-class-button.af-class-boss.af-class-yellow.af-class-w-inline-block.tour {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 30px;
  padding: 12px 10px;
  background-color: #ecd925;
  font-weight: 800;
  width: 160px;
  text-align: left;
  font-size: 14px;
  transition: all 110ms;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  cursor: pointer;
  z-index: 10000;
}

.af-class-boss-right-arrow.tour-arrow {
  transform: translate(10px, -50%);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.tour-lable {
  transition: all 300ms ease-in-out;
  margin-left: 17px;
  font-family: 'Outfit', sans-serif;
}

.af-class-button.af-class-boss.af-class-yellow.af-class-w-inline-block.tour:hover > .af-class-boss-right-arrow.tour-arrow {
  transform: translate(135px, -50%);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.af-class-button.af-class-boss.af-class-yellow.af-class-w-inline-block.tour:hover > .tour-lable {
  transition: all 300ms ease-in-out;
  transform: translateX(-10px);
}

@media only screen and (min-width: 768px) {
  .af-view .af-class-slider_property_content_wrapper {
    position: relative;
  }
  .af-view .af-class-slider-property-controls-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }
  .af-view .af-class-property_slider_pagination_wrapper {
    bottom: 0;
  }
}
@media only screen and (min-width: 2000px) {
  .af-class-slider-property-headers {
    height: 95%;
  }
}

@media only screen and (max-width: 767px) {
  .af-class-slider-property-showcase .carousel .slide > div > img {
    height: 375px !important;
  }
  .af-view .af-class-property_slide_next_arrow {
    position: absolute;
    right: 0;
    width: 60px;
  }
  .af-view .af-class-property_slide_prev_arrow {
    position: absolute;
    left: 0;
    width: 60px;
  }
  .af-view .af-class-slider-property-showcase {
    padding-bottom: 0px;
  }
  .af-view .af-class-slider-property-image-wrapper {
    min-height: unset;
  }
  .af-view .af-class-slider-property-headers {
    margin-top: 0px !important;
  }
  .af-class-div-block-45 {
    padding-right: 20px !important;
  }
}

@media all and (max-width: 479px) {
  .af-view .af-class-property_slide_next_arrow {
    right: 0;
    left: initial;
  }
}

@media only screen and (min-width: 1900px) {
  .carousel.carousel-slider {
    max-width: none;
  }
}

@media only screen and (min-width: 1024px) {
  .af-class-div-block-46 {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .af-class-slider-property-controls-wrapper {
    margin-right: 10px;
  }
}

@media all and (-ms-high-contrast: none) {
  .af-class-slider-poi-content-wrapper {
    flex: 1 auto !important;
  }
}

@media all and (-ms-high-contrast: none) {
  .af-class-slider-poi-content-wrapper {
    flex: 1 auto !important;
  }
  #development-carousel .slider-wrapper.axis-horizontal {
    margin-left: -25%;
  }
}
