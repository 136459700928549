.property {
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.imageWrapper {
  width: 35%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.content {
  padding: 10px;
}

.name {
  font-family: 'Outfit', sans-serif;
  margin-bottom: 0;
  margin-top: 0;
}

.description {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
}

.unitsPhrase,
.sqftPhrase {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 0;
}

.unitsCount,
.sqftCount {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
}

.viewProperty {
  font-family: 'Outfit', sans-serif;
  background-color: #1fc48b;
  color: white;
  display: inline-block;
  padding: 5px 20px;
  margin-top: 10px;
  text-decoration: none;
  text-transform: uppercase;
}
@media screen and (max-width: 1200px) {
  .property {
    flex-direction: column;
  }
  .imageWrapper {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 479px) {
  .imageWrapper {
    display: none;
  }
  .content {
    padding: 20px 30px;
  }
  .property {
    width: 80%;
    display: inline-block;
    margin: 0 10px;
    min-width: 80%;
  }
}
