.image {
  width: 450px;
  height: 600px;
  object-fit: cover;
  margin-left: -10vw;
  padding-right: 20px;
}
.culture {
  padding-right: 30px !important;
  background-color: #f1f5fb;
  background-image: none !important;
  height: 350px;
  min-height: 350px !important;
}
.culture > div:nth-child(2) {
  display: none;
}
.cultureTitle {
  width: 100%;
  max-width: 900px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #3f3f3f;
  font-size: 36px !important;
  line-height: 42px !important;
  font-weight: 700 !important;
}
.cultureTitle::after {
  background-color: #ecd925;
  content: '';
  display: block;
  top: 50px;
  height: 5px;
  position: absolute;
  width: 50px;
}

.culturePrev .button,
.cultureNext .button {
  background: #fff;
}
.cultureDots {
  right: 20% !important;
}

.sliderWrapper {
  max-width: 60vw;
  flex: 1;
  flex-basis: 60%;
  max-height: 800px;
  overflow: hidden;
}
.blurbFactLink {
  font-family: 'Outfit', sans-serif;
  color: #4c698f !important;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  text-decoration: none;
  font-weight: 700 !important;
}

@media only screen and (max-width: 991px) {
  .sliderWrapper {
    min-height: unset !important;
  }
  .cultureDots {
    right: 5px !important;
  }
  .culture {
    padding: 50px 30px 50px 30px !important;
    height: auto !important;
  }
}
@media only screen and (max-width: 767px) {
  .sliderWrapper {
    max-width: 100vw;
  }
  .cultureNext {
    bottom: 0% !important;
    top: -20% !important;
    left: 0;
  }
  .culturePrev {
    bottom: 0% !important;
    top: -20% !important;
    right: 0;
  }
  .cultureDots {
    height: 100%;
    top: -55% !important;
    right: auto !important;
    z-index: 1;
    transform: none !important;
  }
  .image {
    margin-left: 0;
    padding-right: 0;
  }
  .culture {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .culturePage .sliderWrapper {
    max-height: 35vw;
  }
  .cultureBlurb {
    width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .image {
    width: 420px;
    height: 400px;
  }
  .cultureNext,
  .culturePrev {
    top: -10% !important;
    width: 40px;
  }
  .cultureDots {
    top: -5% !important;
  }
  .cultureTitle::after {
    width: 80px;
  }
  .sliderWrapper {
    order: -1;
  }
}

@media only screen and (max-width: 991px) and (min-width: 481px) {
  .image {
    width: 480px;
    height: 625px;
  }
}

@media only screen and (min-width: 992px) {
  .image {
    width: 430px;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .culturePage .sliderWrapper {
    max-height: 35vw;
  }
  .cultureBlurb {
    width: 90%;
  }
}
@media (min-width: 1800px) {
  .cultureTitle,
  .cultureBlurb {
    padding-left: 30%;
  }
}
.button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 20px;
}

.leftArrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #d2d2d2;
  border-radius: 50%;
  margin-left: 10px;
  transition: all 125ms;
  cursor: pointer !important;
}

.selectedDot {
  transform: scale(1.2);
  background-color: #3f3f3f;
}

@media all and (-ms-high-contrast: none) {
  img.image {
    box-shadow: 0 0 0 1px !important;
  }
  img.image[data-object-fit='contain'] {
    object-fit: contain !important;
  }
  img.image[data-object-fit='cover'] {
    object-fit: cover !important;
  }
  .sliderWrapper {
    max-width: 60vw;
  }
  .image {
    width: auto !important;
    margin-left: -40vw;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 767px) {
  .sliderWrapper {
    max-width: 100vw;
  }
}
