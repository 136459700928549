.addonsContainer {
  padding-top: 30px;
  max-width: 400px;
}
.optionWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: space-between;
}
.name {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  color: #aeaeae;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
}

.fee {
  position: relative;
  z-index: 1;
  margin-left: auto;
  padding-left: 10px;
  flex: 0 1 auto;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.background {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  z-index: 0;
  height: 1px;
  border: 1px dotted #d2d2d2;
}
.sectionTitle {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid #d2d2d2;
  /* border-bottom: 1px solid #d2d2d2; */
  padding-top: 15px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
