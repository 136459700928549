.af-view .af-class-floorplan-lease-term-container {
  width: 60px;
}

@media (max-width: 479px) {
  .af-class-floorplan-unit-column-container .af-class-floorplan-unit-column {
    flex-basis: 32%;
  }
  .af-class-floorplan-unit-column-label {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
    font-size: 14px !important;
  }
}
@media (max-width: 734px) {
  .af-class-ctas {
    margin-left: auto;
  }
}
@media (min-width: 992px) and (max-width: 1307px) {
  .af-class-ctas {
    margin-left: auto;
  }
}
