@media (min-width: 1600px){
  .af-class-property-intro-copy {
    padding-bottom: 330px !important;
  }
  .af-class-image-20 {
    width: 100%;
  }
  .af-class-property-intro-copy-container {
    max-width: 545px !important;
  }
}

@media (min-width:480px) and (max-width: 991px){
  .af-class-property-intro-copy {
    padding-bottom: 240px !important;
  }
}
@media (max-width: 767px) and (-ms-high-contrast: none) {
  .af-class-property-intro-copy-container {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 479px) and (-ms-high-contrast: none) {
  .af-class-property-intro-copy {
    flex-basis: auto !important;
  }
}
.af-class-property-intro-image-column {
  flex-basis: 60% !important;
}
.af-class-property-intro-floorplan-wrapper .af-class-div-block-133 {
  padding: 25px 30px 25px 50px !important;
}
