.button {
    cursor: pointer;
    transition: all 100ms;
}

.button:hover {
    opacity: 0.8;
}

.question {
    font-size: 14px!important;
}
.itemWrapper:hover {
    cursor: pointer;
}
