.image {
  object-fit: cover;
  margin-left: calc(-10% - 60px);
  padding-right: 20px;
  height: 100%;
}

.amenitityToolTipHide {
  display: none;
}

.amenitityToolTipShow {
  display: inline;
  position:absolute;
  z-index:100;
  background-color:lightyellow;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}

.sliderWrapper {
  flex: 1;
  height: 100%;
}
@media (min-width: 1100px) {
  .sliderWrapper {
    height: 500px;
  }
}

.sliderWrapper > div:first-child,
.sliderWrapper > div:first-child > div {
  height: 100% !important;
}
@media (max-width: 1099px) {
  .sliderWrapper {
    max-width: 100vw;
  }
}

@media only screen and (max-width: 993px) {
  .sliderWrapper {
    max-width: 100vw;
  }
}

.button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 20px;
}

.button:focus {
  outline: none;
}

.right,
.left {
  cursor: pointer;
  transition: all 150ms;
  margin-top: 0 !important;
  width: 10px;
}
.right:hover,
.left:hover {
  opacity: 0.8;
}

.dot {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 7px;
  transition: all 250ms;
  padding: 0;
}

.fade0 {
  transform: scale(1.6);
}

.fade1 {
  opacity: 0.7;
  transform: scale(1.3);
}

.fade2 {
  opacity: 0.4;
}
@media (max-width: 479px) {
  .image {
    max-height: 275px;
  }
}
@media (max-width: 991px) and (-ms-high-contrast: none) {
  .right,
  .left,
  .dot {
    flex-shrink: 0;
    margin: 3px;
    width: 4px;
    height: 4px;
  }
}
@media (max-width: 445px) and (-ms-high-contrast: none) {
  .dot {
    display: none;
  }
}
@media(-ms-high-contrast: none) {
  .image {
    height: auto;
    width: auto !important;
    max-height: 700px;
    margin-left: calc(-60% - 60px) !important;
  }
}

