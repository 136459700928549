.dot {
  cursor: pointer;
}

.sliderContainer {
  background-color: transparent !important;
  top: 25px;
  width: 100% !important;
  min-height: 40px !important;
}

.sliderContainer {
  padding-bottom: 25px !important;
}
