.map {
  background-image: none !important;
  display: none;
}
.address > p {
  margin-bottom: 0px;
}
.mapContainer {
  width: 100% !important;
  background-image: none !important;
  padding: 0 !important;
  display: flex !important;
  flex-direction: row;
}

.phoneTitle {
  font-weight: 900 !important;
  font-size: 14px !important;
  margin: 0 !important;
  line-height: 20px !important;
}

@media (max-width: 797px) {
  .mapContainer {
    flex-direction: column;
    height: auto;
  }
}

.mapElement {
  height: 100% !important;
  width: 100% !important;
  min-height: 45vh;
  width: 50%;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}

.mapElement > span {
  display: block !important;
}

.contactUs {
  display: inline-block;
}

.contactUs:hover {
  cursor: pointer;
}
.phone > a {
  text-decoration: none;
  color: #333333 !important;
  white-space: nowrap;
}
.buttonArrow {
  padding-left: 5px;
  transition: all 300ms ease-in-out;
}
.contactUs:hover > .buttonArrow {
  transform: translate(2px, 0px);
}

.markerLabel {
  position: absolute;
  top: -20px;
  left: -85px;
  text-align: center;
  width: 200px;
  font-size: 13px;
  color: #333333;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
}

.clickableImage {
  position: relative;
  transition: all 150ms;
  object-fit: contain;
}

.clickableImage:hover {
  opacity: 0.88;
  /* transform: scale(1.2); */
}

.markerContainer {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100% 100% 100% 0;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.markerIcon {
  width: 40%;
  height: 40%;
  border-radius: 50%;
  background-color: white;
  opacity: 0.95;
}

@media (max-width: 479px) {
  .mapElement {
    min-height: 400px !important;
  }
}

@media (max-width: 1500px) {
  .phoneTitle {
    margin-top: -0.5em !important;
  }
}

.note {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  white-space: normal;
  margin-top: 8px;
  line-height: 20px;
}

.tourText {
  max-width: 50em;
}

.button {
  position: relative;
  cursor: pointer;
  max-width: 173px;
}
.buttonText {
  color: white;
}

.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.buttonArrow {
  width: 13px;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  position: absolute;
  left: 83%;
}
