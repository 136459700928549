.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapper > span {
  flex: 0 1 auto !important;
}

.container {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
}

.image {
  flex: 1 0 40%;
  height: auto;
  background-image: url('/images/katie-treadway-171141-unsplash.jpg');
  background-position: center center;
  background-size: cover;
}

@media (max-width: 730px) {
  .image {
    display: none;
  }
}
@media (max-width: 479px) {
  .form {
    padding: 20px !important;
  }
}

.form {
  flex: 1 0 60%;
  padding: 50px;
}

.formRow {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

.forgot {
  color: #008d94;
  font-family: 'Outfit', sans-serif;
  transition: all 125ms;
  cursor: pointer;
  font-weight: 700;
}

.forgot:hover {
  opacity: 0.8;
}

.submitButton {
  padding: 12px 40px 17px;
  border-radius: 3px;
  background-color: #008d94;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  transition: all 125ms;
  width: 50%;
}

.submitButton:hover {
  opacity: 0.8;
}

.cancelButton {
  padding: 9px 25px;
  border-radius: 3px;
  background-color: #eee;
  color: #727272;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paragraph {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  margin-bottom: 15px;
}

.title {
  margin-bottom: 5px !important;
}

.input {
  border-radius: 3px;
  margin-top: 20px;
  padding-top: 21px;
  padding-bottom: 21px;
}

.formContainer {
  min-height: 500px;
  max-width: 500px;
  margin: auto;
  margin-top: 80px;
  padding-left: 45px;
}

.error {
  display: block !important;
}

.formToggle {
  margin-left: 5px;
  font-weight: 700;
  cursor: pointer;
  color: #008d94;
}

.formToggle:hover {
  opacity: 0.8;
}
.createAccount {
  margin-top: 20px;
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .formContainer {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 600px;
  }
}
@media (-ms-high-contrast: none) and (min-width: 730px) and (max-width: 1024px) {
  .formContainer {
    margin: 0 10% 0 0;
    padding-right: 10%;
  }
}

.loadingButton {
  background-color: white;
}
@media all and (-ms-high-contrast: none) {
  .input {
    height: auto;
  }
}
