.progressBar {
  right: 49.5% !important;
}
.address > p {
  margin-bottom: 0px;
}
.socialWrapper {
  display: flex;
  padding: 10px 0px;
  border-bottom: rgb(204, 204, 204) 1px solid;
  border-top: rgb(204, 204, 204) 1px solid;
  margin-top: 10px;
  width: 200px;
}
.displayNone {
  display: none;
}
.singleline {
  white-space: nowrap;
}
.subtitle {
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.container > img {
  height: 25px;
  filter: grayscale(100);
}
.container {
  display: flex;
  padding: 5px 10px 5px 0px;
  cursor: pointer;
}
.container:hover > img {
  filter: grayscale(0);
}
.mobileHeaderImage {
  height: 300px;
  width: 100%;
}
.breadCrumbContainer {
  top: 75px !important;
}
.crunchContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: all 150ms;
  align-items: center;
}
.crunchContainer:hover {
  opacity: 0.8;
}
.phoneNumber > a {
  text-decoration: none;
  color: #3f3f3f !important;
  font-weight: 500;
}
@media (min-width: 992px) {
  .mobileHeaderImage {
    display: none;
  }
}
@media (min-width: 480px) and (max-width: 991px) {
  .mobileHeaderImage {
    transform: translate(0px, 44px);
    height: 380px;
  }
}
