.highlightWords {
  position: relative;
  z-index: 1;
  /* line-height: 63px; */
}
.highlightWords:after {
  content: "";
  display: block;
  top: 25%;
  left: -4%;
  bottom: 3%;
  right: -4%;
  position: absolute;
  z-index: -1;
}
