.image > img,
.image {
  height: 100% !important;
  max-height: none !important;
  object-fit: cover !important;
  object-position: center center !important;
}

.imageCaption {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(51, 51, 51, 0.8);
  color: white;
  height: 40px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 300;
  min-width: 170px;
  padding: 0 20px;
}

.imageCounter {
  margin-left: 10px;
  font-size: 14px;
}

.captionThumbs {
  right: 350px;
}

.apply {
  cursor: pointer;
  transition: all 150ms;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  flex: 1 1 auto;
  max-width: 40%;
}

.apply:hover {
  opacity: 0.78;
}

.disclaimerContainer {
  width: 20px;
  display: inline-block;
  position: relative;
}

@media (min-width: 768px) {
  .infoIcon:hover ~ .toolTipArrow {
    display: block !important;
  }

  .infoIcon:hover + .priceDisclaimer {
    display: block !important;
  }

  .infoIcon:hover + .priceDisclaimerMobile {
    display: block !important;
  }
}

.showDisclaimer {
  display: block !important;
}

.pricingWrapper {
  position: relative;
}

.priceDisclaimer {
  display: none;
  width: 250px;
  border: 1px solid #727272;
  padding: 20px;
  position: absolute;
  z-index: 1;
  background: white;
  bottom: 30px;
  left: -10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 400;
  color: #3f3f3f;
}

.priceDisclaimerMobile {
  display: none;
  width: 250px;
  border: 1px solid #727272;
  padding: 20px;
  position: absolute;
  z-index: 1;
  background: white;
  bottom: 30px;
  left: -225px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 400;
  color: #3f3f3f;
}

.toolTipArrow {
  display: none;
  left: -2px;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 20px;
  z-index: 3;
  border-left: 1px solid #787878;
  border-bottom: 1px solid #787878;
  transform: rotate(-45deg);
  background: white;
}

@media only screen and (max-width: 767px) {
  .close {
    right: 0 !important;
  }
}

.close {
  border-radius: 0 0 0 2px !important;
  left: auto !important;
  top: 0 !important;
  right: 350px;
  height: 36px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.rightWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.crunchContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: all 150ms;
  align-items: center;
}

.topWrapper {
  padding: 5px 15px 15px;
}

.starsContainer > span,
.starsContainer > div {
  height: auto !important;
  flex-basis: auto !important;
  display: flex !important;
}

.crunchContainer:hover {
  opacity: 0.8;
}

.specials {
  margin: 0 0 5px !important;
  padding: 6px 6px 6px 12px !important;
  max-width: 160px;
  justify-content: center;
}

.specials > div {
  font-family: 'Outfit', sans-serif;
}

.phone {
  margin-top: 10px;
  margin-bottom: 5px;
}

.phone > a {
  text-decoration: none;
  color: #3f3f3f !important;
}

.reviews {
  padding-left: 7px;
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.visit {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 10px;
  padding: 12px 10px;
  background-color: #ecd925;
  font-weight: 800;
  width: 100%;
  text-align: left;
  font-size: 14px;
  transition: all 110ms;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.buttonArrow {
  transition: all 120ms;
  width: 8px;
}

.selfTour:hover > .buttonArrow {
  transform: translate(230px, -50%);
  transition: all 300ms ease-in-out;
}
.visit:hover > .buttonArrow {
  transform: translate(135px, -50%);
  transition: all 300ms ease-in-out;
}

.selfTour:hover > .selfTourLabel,
.visit:hover > .visitLabel {
  transform: translateX(-10px);
  transition: all 300ms ease-in-out;
}

.selfTour > .buttonArrow,
.visit > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.selfTour > .selfTourLabel,
.visit > .visitLabel {
  transform: translateX();
  transition: all 300ms ease-in-out;
}

.selfTourLabel,
.visitLabel {
  margin-top: -3px;
  margin-left: 17px;
  color: white !important;
}

.thumbContainer {
  flex: 1 0 350px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  max-height: 100%;
  overflow: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  transition: all 170ms;
  -webkit-scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.thumbHeaderFirst {
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 10px;
  line-height: 5px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.thumbHeader {
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 10px;
  line-height: 5px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.thumbWithHeaderContainer {
  flex: 1 0 150px;
}

.thumbWithHeaderContainerFirst {
  flex: 1 0 150px;
  margin-top: 15px;
}

.container > div > div {
  max-width: none !important;
}

.address > p {
  margin-bottom: 0px;
}

.thumbItem {
  padding: 25px 25px 0 25px;
  position: relative;
  width: 100%;
  flex: 1 0 150px;
}

.sliderThumb {
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: 125ms;
}

.inactiveThumb:hover {
  opacity: 0.76;
}

.inactiveThumb {
  opacity: 0.5;
}

.galleryVideo > div > iframe {
  width: 100% !important;
  margin: 0 !important;
}

.buttonContainer > div:first-child {
  flex-direction: row-reverse;
}

.buttonContainer > div {
  cursor: pointer;
  justify-content: center !important;
}

@media only screen and (max-width: 1200px) {
  .bannerContainer {
    width: auto;
    align-items: stretch !important;
  }

  .buttonContainer {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .button {
    justify-content: space-around !important;
    font-size: 12px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .specials {
    margin-top: 10px !important;
  }
  .buttonContainer {
    justify-content: flex-start !important;
  }
}

@media (min-width: 768px) and (max-width: 880px) {
  .thumbContainer {
    flex: 1 0 215px;
  }

  .close {
    right: 215px;
  }

  .sliderThumb {
    height: 105px;
  }
}

@media only screen and (max-width: 767px) {
  .thumbWithHeaderContainer,
  .thumbWithHeaderContainerFirst {
    flex: 1 0 275px;
  }

  .thumbHeaderFirst {
    margin-bottom: -15px;
  }

  .thumbHeader {
    transform: translateY(10px);
  }

  .thumbContainer {
    flex-direction: row;
    flex: 1 0 150px;
    max-width: 100%;
    overflow: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    top: 40px;
  }

  .thumbContainerOpen {
    margin-bottom: 40px;
  }

  .thumbItem {
    flex: 1 0 275px !important;
    height: 150px !important;
  }

  .name {
    font-size: 24px !important;
  }

  .container {
    flex-direction: column;
    height: auto !important;
    margin-bottom: -5% !important;
    max-width: 100%;
    align-items: flex-start !important;
    margin-top: 0 !important;
  }

  .bannerContainer {
    top: 0 !important;
  }

  .breadcrumbContainer {
    transform: translate(0px, 0px) !important;
    overflow: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    background-color: #f2f2f2;
  }

  .crunchContainer {
    white-space: nowrap;
  }

  .image {
    max-width: 771px;
  }

  .imageCaption {
    top: 335px;
    width: 100%;
    justify-content: space-between;
    position: static;
    bottom: 190px;
  }

  .imageCaption.captionThumbs {
    right: 0;
    bottom: 190px;
    top: initial;
  }

  .captionMoveIn {
    top: 311px;
  }

  .buttonArrow {
    display: none;
  }

  .visit:hover > .visitLabel {
    transform: translateX(0);
  }

  .visit {
    width: 90%;
    max-width: 232px;
    justify-content: center;
    align-items: center;
    order: 2;
  }

  .visitLabel {
    margin-left: 0px;
  }
  .apply {
    margin-left: 0 !important;
    margin-top: 10px !important;
    width: 90%;
    max-width: 232px;
    order: 3;
  }
  .selfTour {
    margin-top: 10px !important;
    width: 90%;
    max-width: 232px;
  }
  .selfTourWrapperMobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    order: 1 !important;
  }
}

@media only screen and (max-width: 515px) {
  .buttonContainer {
    background-color: #eaeaea;
    justify-content: space-between !important;
  }

  .buttonContainer > a {
    flex: 1 0 auto;
    margin-right: 10px !important;
  }

  .button > svg {
    display: none;
  }

  .buttonContainer > a:last-child {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 515px) and (max-width: 767px) {
  .buttonContainer {
    flex-direction: row !important;
    justify-content: center !important;
    background-color: #eaeaea;
  }

  .buttonContainer > a {
    margin-right: 0 10px !important;
  }
  .button {
    margin-bottom: 15px;
  }
  /* This .oneButton style is overrding the .buttonContainer,
   so it MUST remain AFTER the .buttonContainer due to CSS cascading rules.*/
  .oneButton {
    justify-content: flex-start !important;
  }
}

@media only screen and (min-width: 280px) and (max-width: 280px) {
  .imageCaption {
    margin-top: -140px !important;
  }

  .buttonContainer {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 320px) {
  .imageCaption {
    margin-top: -120px !important;
  }

  .buttonContainer {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 360px) {
  .imageCaption {
    margin-top: -95px !important;
  }

  .buttonContainer {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 375px) {
  .imageCaption {
    margin-top: -90px !important;
  }

  .buttonContainer {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 384px) and (max-width: 384px) {
  .imageCaption {
    margin-top: -85px !important;
  }

  .buttonContainer {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 411px) and (max-width: 411px) {
  .imageCaption {
    margin-top: -70px !important;
  }

  .buttonContainer {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 414px) and (max-width: 414px) {
  .imageCaption {
    margin-top: -70px !important;
  }

  .buttonContainer {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 428px) and (max-width: 428px) {
  .imageCaption {
    margin-top: -60px !important;
  }

  .buttonContainer {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 540px) and (max-width: 540px) {
  .imageCaption {
    margin-top: 5px !important;
  }

  .buttonContainer {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 479px) {
  .breadcrumbContainer {
    transform: translate(0px, 0px) !important;
    overflow: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .crunchContainer {
    white-space: nowrap;
  }

  .imageCaption {
    width: 100%;
    justify-content: flex-start;
    height: 40px;
    top: 335px;
  }

  .captionMoveIn {
    top: 335px;
  }

  .imageCounter {
    position: absolute;
    right: 20px;
  }

  .bannerContainer {
    transform: none !important;
    background-color: #eaeaea;
  }

  .visit {
    margin-top: 15px;
  }

  .container {
    margin-bottom: -50% !important;
  }

  .name {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1075px) {
  .container {
    height: calc(100vh - 140px) !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1074px) {
  .container {
    height: calc(100vh - 210px) !important;
  }
}

.thumbItem:focus {
  box-shadow: inset 0px 0px 0px 5px rgb(77, 144, 254);
  outline: none;
}

.close:focus {
  box-shadow: inset 0px 0px 0px 3px rgb(77, 144, 254);
  outline: none;
}

.galleryCategoryRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: sticky;
  top: 0;
  height: 40px;
  z-index: 10;
}

.categoryMobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .categoryDesktop {
    display: none;
  }

  .galleryCategoryRow {
    position: absolute;
    top: auto;
    bottom: 150px;
  }

  .categoryMobile {
    display: flex;
  }

  .selfTourWrapper {
    display: none;
  }
}

.galleryCategoryButton {
  flex: 1;
  padding: 5px;
  margin: 3px;
  color: #fefefe;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@media (-ms-high-contrast: none) {
  .container > div > div {
    max-width: 100% !important;
  }

  .thumbWithHeaderContainerFirst > .thumbItem {
    height: 100%;
  }
}

.specialText {
  padding: 20px 40px 0 20px;
  font-size: 16px;
  font-weight: 400;
}

.specialExpDate {
  padding: 0 20px 20px 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
}

.infoText {
  padding: 20px 40px 20px 20px;
  font-size: 14px;
  font-weight: 400;
}

.widgetClose {
  width: 80px;
  color: white;
  background: #333;
  border-radius: 100px;
  padding: 2px 8px;
  position: fixed;
  bottom: 75px;
  right: calc(5% + 170px);
  z-index: 1000;
  text-align: center;
  cursor: pointer;
}

.selfTour {
  font-size: 14px;
  color: #f5f5f5;
  text-decoration-line: none;
  font-weight: bold;
  padding: 10px 15px;
  margin: 12px;
  transition: all 0.2s;

  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #4c698f;
  font-weight: 800;
  text-align: left;
  transition: all 110ms;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.selfTour:hover {
  background-color: #4c698f;
  color: #f5f5f5;
}

.selfTourWrapper {
  background: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #f6f6f6;
}

.bottomRowMobile {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selfTourWrapperMobile {
  order: 4;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -5px;
}

@media (max-width: 479px) {
  .widgetClose {
    right: 40%;
  }
}
