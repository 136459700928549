.contentEmpty {
  align-items: center !important;
  justify-content: center !important;
  padding-right: 0 !important;
}

.title {
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

.blurb {
  order: 3;
  margin-top: 5px;
  font-family: 'Outfit', sans-serif;
}

.detailsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 992px) {
  .detailsContainer {
    left: 5%;
  }
}

.subtitle {
  position: relative;
  margin-bottom: 10px !important;
}

@media (max-width: 479px) {
  .subtitle::after {
    width: 50%;
    height: 2px;
    left: -40px;
  }
}
.subtitle > span {
  border-bottom: 3px solid #ecd925;
  padding-bottom: 6px;
}
