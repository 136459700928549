.backContainer {
    cursor: pointer;
}

.backContainer:hover {
    opacity: 0.88;
}

.floor {
    padding: 0 12px;
}
.unitImage {
    max-height: 250px;
}
@media all and (-ms-high-contrast: none) {
    .unitImage {
        max-width: 275px !important;
        max-height: 250px !important;
    }
  }
