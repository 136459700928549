.sliderContainer {
  background-image: none !important;
  position: relative;
}

.image {
  height: 100% !important;
  width: 100% !important;
  max-height: 231.63px;
  object-fit: cover !important;
}

@media screen and (max-width: 1364px) {
  .image {
    height: 100%;
    max-height: 251.63px;
  }
}

.arrows {
  cursor: pointer;
  transition: all 300ms;
  display: block !important;
}

.title {
  cursor: pointer;
  transition: all 150ms;
}

.title:hover {
  color: rgba(0, 0, 0, 0.6);
}

.arrows:hover {
  transform: translate(0px, -50%) scale(1.15) !important;
}
.specials {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.specials:hover {
  cursor: pointer;
}
.pricingContainer {
  display: flex !important;
  align-items: baseline;
  justify-content: flex-start;
}

.sliderContainer > div:first-child {
  height: 100% !important;
  overflow: clip;
}
.disclaimerContainer {
  position: relative;
  margin: 0 auto;
  width: 20px;
  display: inline-block;
}
.pricingLink {
  max-width: 95%;
  display: flex;
  align-items: baseline;
}
/* .infoIcon {
  position: absolute;
  bottom: -3px;
} */
@media (min-width: 768px) {
  .infoIcon:hover ~ .toolTipArrow {
    display: block !important;
  }
  .infoIcon:hover + .priceDisclaimer {
    display: block !important;
  }
}
.showDisclaimer {
  display: block !important;
}
.priceDisclaimer {
  display: none;
  width: 250px;
  border: 1px solid #727272;
  padding: 20px;
  position: absolute;
  z-index: 2;
  background: white;
  bottom: 30px;
  left: -10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 400;
}
.toolTipArrow {
  display: none;
  left: -2px;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 20px;
  z-index: 3;
  border-left: 1px solid #787878;
  border-bottom: 1px solid #787878;
  transform: rotate(-45deg);
  background: white;
}
@media (max-width: 991px) {
  .sliderContainer {
    padding: 0 !important;
  }
  .fireIcon {
    margin-top: 0 !important;
  }
}

.dot {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 7px;
  transition: all 250ms;
  background-color: white;
}

.dotContainer {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.fade0 {
  transform: scale(1.6);
}

.fade1 {
  opacity: 0.7;
  transform: scale(1.3);
}

.fade2 {
  opacity: 0.4;
}

.specialText {
  padding: 20px 40px 0 20px;
  font-size: 16px;
  font-weight: 400;
}
.specialExpDate {
  padding: 0 20px 20px 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
}
.infoText {
  padding: 20px 40px 20px 20px;
  font-size: 14px;
  font-weight: 400;
}
