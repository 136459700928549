.homePageContainer {
  position: fixed !important;
  background-color: transparent !important;
  border-bottom: none !important;
  top: 0px;
  left: 0;
  right: 0;
}
.homePageMobileButton > div {
  color: white !important;

}
.homepageLabels,
.homepageLabels > div {
  color: white !important;
}

.homePageLinks > a {
  /* color: white !important; */
  display: none;
}
.homePageNav {
  background: transparent !important;
}
.navwrapper {
  background: white !important;
  color: black !important;
}

.link {
  cursor: pointer;
}
.hasAlertBanner {
  top: 50px;
}
@media (max-width: 767px) {
  .hasAlertBanner {
    top: 60px
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .homepageResidentPortal {
    width: unset !important;
  }
}
@media (max-width: 479px) {
  .homepageLabels,
  .homepageLabels > div {
    color: #000 !important;
  }
  .homePageNav {
    top: 77px !important;
    background: white !important;
  }
}
@media only screen and (min-width: 480px) {
  .navwrapper {
    color: white !important;
  }
}

.languageDropdown {
  padding: 5px 20px 5px 5px !important;
}
