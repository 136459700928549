.af-class-list-detailed-lists-column {
    padding-top: 10px !important;
}
@media all and (-ms-high-contrast:none) {
    .af-class-list-detailed-image-column {
        flex: 1 0 auto;
        width: unset !important;
    }
    .af-view .af-class-list-detailed-lists-column {
        flex: 1 0 auto;
    }
    .af-view .af-class-div-block-29 {
        max-width: unset !important;
    }
    .af-view .af-class-list_detailed {
        overflow: hidden;
    }
}
@media (min-width: 1920px) {
    .af-view .af-class-list-detailed-image-content {
        max-width: 650px;
    }
}
@media (min-width: 1600px) {
    .af-view .af-class-list-detailed-lists-column {
        justify-content: flex-start;
    }
    .af-view .af-class-list-detailed-list-content {
        max-width: 80%;
    }
}
@media (min-width: 992px) and (max-width: 1100px) {
    .af-view .af-class-list-detailed-lists-column {
        padding-left: 0;
    }
}
@media all and (-ms-high-contrast:none) and (max-width: 767px) {
    .af-view .af-class-list-detailed-image-column {
        flex-basis: auto !important;
    }
}
