.backgroundImage {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.text {
  min-width: 255px;
}
@media (max-width: 479px) {
  .text {
    min-width: unset;
  }
}
