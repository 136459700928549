.subregionTextBoxHeader {
  font-size: 16px;
  line-height: 16px;
  margin: 0 0 20px 0;
  border-bottom: 2px solid #c1ba50;
  padding: 15px 0;
  width: fit-content;
}

.subregionTextBoxDescription {
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
}

.subregionTextBoxDescription > p {
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
}

.subregionTextBoxDescription > p > a {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}
