.nav-home-control-shadow {
  -webkit-transition: 400ms ease-in 200ms; /* property duration timing-function delay */
  -moz-transition: 400ms ease-in 200ms;
  -o-transition: 400ms ease-in 200ms;
  transition: 400ms ease-in 200ms;
  background-color: rgba(0, 0, 0, 0.82) !important;
}

.nav-home-control-normal {
  -webkit-transition: 400ms ease-in 200ms; /* property duration timing-function delay */
  -moz-transition: 400ms ease-in 200ms;
  -o-transition: 400ms ease-in 200ms;
  transition: 400ms ease-in 200ms;
  background-color: transparent !important;
}

.af-class-nav-top-menulink,
.af-class-nav-link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.af-class-nav-top-menulink2 {
  background: #fff;
  color: #000 !important;
  opacity: 0.9;
  text-align: center;
  width: 85%;
  padding: 4% 8%;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: 500 !important;
}
.w-dropdown-link {
  font-family: 'Outfit', sans-serif;
}

.nav-menu-active .af-class-nav-link,
.nav-menu-active .af-class-nav-top-menulink {
  position: relative;
}

.af-class-bottom-menu-dropdown-toggle.nav-menu-active > .af-class-nav-bottom-link-label {
  border-bottom: 3px solid #ecd925;
}

.nav-menu-active .af-class-nav-link::before,
.nav-menu-active .af-class-nav-top-menulink::before {
  border-bottom: 3px solid #ecd925;
  content: ' ';
  height: calc(100% + 3px);
  position: absolute;
  width: 100%;
}
a.af-class-nav-top-menulink.nav-menu-active,
.af-view .af-class-nav-bottom-link-label.af-class-sustainability.nav-menu-active {
  border-bottom: 3px solid #ecd925;
}
.af-view .af-class-nav-menu {
  background-color: transparent;
}
@media only screen and (min-width: 480px) {
  .af-view .af-class-nav-top-menulink {
    text-transform: uppercase !important;
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 479px) {
  .af-view .af-class-nav-top-menulink {
    color: #3f3f3f !important;
  }
}
@media only screen and (max-width: 767px) {
  .af-view .af-class-nav-bottom-link-label.af-class-sustainability {
    margin: auto;
    padding-top: 20px;
  }
  .af-view .af-class-menu-button.w-nav-button {
    background-color: transparent;
  }
  .af-view [class^='w-icon-'],
  .af-view [class*=' w-icon-'] {
    color: black;
  }
  .af-view .w-nav-brand {
    margin-right: auto;
  }
  .w-dropdown-toggle {
    padding: 0px 0px 0px 0px !important;
  }
  .w-dropdown-btn .w-dropdown-link {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 40px;
  }
  .af-class-nav-bottom-menu-link-wrap .w-icon-dropdown-toggle {
    margin-right: auto;
  }
  .w-nav[data-collapse='small'] .w-dropdown-list {
    position: absolute;
    min-width: 160px;
    z-index: 99;
  }
  .af-view .af-class-bottom-nav-menu-list {
    padding-right: 20px;
    padding-left: 20px;
  }
  .w-dropdown-link {
    padding: 10px;
  }
  .af-view .w-nav[data-collapse='small'] .w-nav-menu.show {
    display: block !important;
    position: absolute;
    left: 0;
    background-color: white;
  }

  .af-view .af-class-bottom-menu-dropdown-toggle {
    text-align: center;
  }
  .af-view .w-nav[data-collapse='small'] .w-nav-menu {
    order: 3;
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .af-view .w-nav[data-collapse='small'] .w-nav-menu {
    top: 77px;
  }
}
@media only screen and (max-width: 479px) {
  .af-view .w-nav[data-collapse='small'] .w-nav-menu {
    top: 60px;
    background-color: white;
  }
  .af-class-top-nav-menu-list .w-dropdown-link {
    padding: 10px 20px;
  }
  .af-view .af-class-nav-top-menu-link-wrap {
    max-width: 95%;
  }
}
.af-class-dropdown-list-3.w-dropdown-list.w--open {
  z-index: 1;
}
.af-class-dropdown-5.w-dropdown {
  z-index: unset;
}
.lang-dropdown {
  width: 100%;
  background-color: white;
}
