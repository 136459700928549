.wrapper {
  position: absolute !important;
  bottom: 35px !important;
  pointer-events: auto !important;
  display: flex !important;
  width: 90% !important;
  max-width: 800px !important;
}
.interest {
  cursor: pointer !important;
  flex: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.interest:hover {
  opacity: 0.8;
}

.icon {
  object-fit: contain;
  fill: white !important;
}

.label {
  padding-left: 0px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.iconContainer {
  width: 30px;
  height: 30px;
  margin-right: 10px !important;
  border-radius: 50%;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
@-moz-document url-prefix() {
  .interest {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 1085px) {
  .interest {
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
}

@media (max-width: 1260px) and (min-width: 922px) {
  .label {
    font-size: 12px;
  }
}

@media (max-width: 922px) and (min-width: 720px) {
  .label {
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .wrapper {
    width: 95% !important;
  }
}
@media (max-width: 991px) {
  .wrapper {
    bottom: 0px !important;
  }
}
@media (max-width: 720px) {
  .label {
    font-size: calc(5px + 1.4vw);
  }

  .wrapper {
    bottom: 0;
    overflow: hidden !important;
  }
}
@media (max-width: 479px) {
  .wrapper {
    width: 100% !important;
  }

  .interest {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
