.af-class-about-page-header.af-class-corporate-gov {
    height: 400px !important;
    min-height: 0px;
    background-position: center 10% !important;
}
.af-class-about-page-header.af-class-corporate-gov.sustainability {
    height: 600px !important;
}
@media (max-width: 470px) {
    .af-class-about-page-header.af-class-corporate-gov {
        height: 300px !important;
    }
    .af-class-about-page-header.af-class-corporate-gov.sustainability {
        height: 400px !important;
    }
}