.header {
  padding-top: 170px;
  position: relative;
  box-sizing: content-box;
}

.title {
  height: auto;
  box-sizing: border-box;
  font-weight: bold;
  margin: 0.67em 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  width: 100%;
  padding-left: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 72px;
  line-height: 82px;
  text-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

.placard {
  position: relative !important;
  height: auto !important;
  max-height: 250px;
}

@media (max-width: 991px) {
  .placard {
    max-height: none !important;
  }
}

.selfTour {
  font-size: 14px;
  color: #f5f5f5;
  text-decoration-line: none;
  font-weight: bold;
  padding: 10px 15px;
  margin: 12px;
  transition: all 0.2s;

  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #4c698f;
  font-weight: 800;
  text-align: left;
  transition: all 110ms;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.selfTour:hover {
  background-color: #4c698f;
  color: #f5f5f5;
}

.selfTourWrapper {
  background: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid #f6f6f6;
  order: 5;
  width: 99%;
  margin-left: -45%;
}

.bottomRowMobile {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selfTourWrapperMobile {
  order: 4;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -5px;
}

@media (-ms-high-contrast: none) {
  .placard {
    height: 250px !important;
  }
}

.movein {
  position: absolute !important;
  width: 100% !important;
  top: -40px;
  max-width: initial !important;
}

.address > p {
  margin-bottom: 0px !important;
}

.starsContainer > div,
.starsContainer > span {
  height: auto !important;
  flex-basis: auto !important;
  display: flex !important;
}
@media screen and (max-width: 480px) {
  .starsWithSpecials {
    align-self: flex-start !important;
  }
}

.applyButton {
  cursor: pointer;
  transition: all 150ms;
}

.applyButton:hover {
  opacity: 0.88;
}

.pricingWrapper,
.price {
  /* position: relative; */
  display: flex;
  align-items: center;
  width: auto;
}

.title,
.address,
.phone,
.beds,
.reviews,
.price {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.reviews {
  padding-left: 7px;
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.phone > a {
  color: #3f3f3f;
  text-decoration: none;
}

.button {
  order: 3;
  background-image: none !important;
  position: relative;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 12px 10px;
  background-color: #ecd925;
  font-weight: 800;
  width: 100%;
  text-align: left;
  font-size: 14px;
  transition: all 110ms;
  color: white;
  text-rendering: optimizeLegibility;
}

.buttonText {
  margin-top: -3px;
  margin-left: 15px;
  font-family: 'Outfit', sans-serif;
}

.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.buttonArrow {
  width: 8px;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}

.button:hover > .buttonArrow {
  position: absolute;
  right: 5px;
  left: 85%;
}

.dailyText {
  font-size: 14px;
  font-weight: 400;
}

.specials {
  padding: 6px 6px 6px 12px !important;
  transform: translate(0px) !important;
  display: flex;
  flex-direction: row-reverse;
}

.specials > div {
  font-family: 'Outfit', sans-serif;
}

.breadcrumbContainer {
  position: absolute;
  display: flex;
  left: 0px;
  top: 0 !important;
  padding: 5px 10px 7px;
  background-color: hsla(0, 0%, 100%, 0.64);
  -webkit-transform: none;
  transform: none;
  align-items: center;
}

.visit {
  order: 2;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 10px;
  padding: 12px 10px;
  background-color: #ecd925;
  font-weight: 800;
  text-align: left;
  font-size: 14px;
  transition: all 110ms;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  width: 90%;
  max-width: 232px;
}

.selfTour:hover > .buttonArrow {
  transform: translate(230px, -50%);
  transition: all 300ms ease-in-out;
}

.visit:hover > .buttonArrow {
  transform: translate(160px, -50%);
  transition: all 300ms ease-in-out;
}

.selfTour:hover > .selfTourLabel,
.visit:hover > .visitLabel {
  transform: translateX(-10px);
  transition: all 300ms ease-in-out;
}

.selfTour > .buttonArrow,
.visit > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.selfTour > .selfTourLabel,
.visit > .visitLabel {
  transform: translateX();
  transition: all 300ms ease-in-out;
}

.selfTourLabel,
.visitLabel {
  margin-top: -3px;
  margin-left: 17px;
  color: white !important;
}

.crunchContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: all 150ms;
  align-items: center;
}

.disclaimerContainer {
  position: relative;
}

.infoIcon {
  width: 20px;
}

@media (min-width: 768px) {
  .infoIcon:hover ~ .toolTipArrow {
    display: block !important;
  }

  .infoIcon:hover + .priceDisclaimer {
    display: block !important;
  }

  .infoIcon:hover + .priceDisclaimerMobile {
    display: block !important;
  }
}

.showDisclaimer {
  display: block !important;
}

.pricingWrapper {
  position: relative;
}

.priceDisclaimer {
  display: none;
  width: 250px;
  border: 1px solid #727272;
  padding: 20px;
  position: absolute;
  z-index: 1;
  background: white;
  bottom: 30px;
  left: -10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 400;
  color: #3f3f3f;
}

.priceDisclaimerMobile {
  display: none;
  width: 250px;
  border: 1px solid #727272;
  padding: 20px;
  position: absolute;
  z-index: 1;
  background: white;
  bottom: 30px;
  left: -225px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 400;
  color: #3f3f3f;
}

.toolTipArrow {
  display: none;
  left: -2px;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 20px;
  z-index: 3;
  border-left: 1px solid #787878;
  border-bottom: 1px solid #787878;
  transform: rotate(-45deg);
  background: white;
}

@media (max-width: 1076px) {
  /* .breadcrumbContainer {
    top: 144px;
  } */
}

@media (max-width: 991px) {
  .title {
    margin-bottom: 50px !important;
  }
}

@media (max-width: 767px) {
  .breadcrumbContainer {
    transform: translate(0px, -4px) !important;
    overflow: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    max-width: 100%;
  }

  .crunchContainer {
    white-space: nowrap;
  }

  .apply {
    margin-left: 0 !important;
    margin-top: 10px !important;
    width: 90%;
    max-width: 232px;
    order: 3;
  }
  .selfTour {
    margin-top: 10px !important;
    width: 90%;
    max-width: 232px;
  }
  .selfTourWrapperMobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    order: 1 !important;
  }
}

@media (min-width: 480px) {
  .address {
    margin-bottom: 7px !important;
  }
}

@media (max-width: 479px) {
  .header {
    background-size: contain !important;
    background-position-x: 0 !important;
    background-position-y: 0 !important;
  }

  .selfTourWrapper {
    display: none;
  }
}

@media (max-width: 480px) {
  .starsContainer {
    align-self: center;
    justify-content: center !important;
    width: 49%;
  }

  .apply {
    cursor: pointer;
    transition: all 150ms;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    flex: 1 1 auto;
    /*    max-width: 40%;*/
    order: 4;
  }

  .apply:hover {
    opacity: 0.78;
  }

  .breadcrumbContainer {
    padding-top: 3px;
    padding-bottom: 3px;
    width: 100%;
    /* top: 134px; */
    overflow: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .crunchContainer {
    white-space: nowrap;
  }

  .title {
    top: 0% !important;
    text-align: center;
    padding-left: 0 !important;
  }

  .titleWithSelfTourMobile {
    top: 10% !important;
  }

  .specials {
    margin-bottom: initial !important;
  }

  .address {
    max-height: 33px !important;
    margin-bottom: 7px !important;
  }

  .visit:hover > .visitLabel {
    transform: translateX(0);
  }

  .visit {
    width: 100%;
    /*    max-width: 160px;*/
    justify-content: center;
    align-items: center;
  }

  .visitLabel {
    margin-left: 0px;
  }

  .button:hover > .buttonText {
    transform: translateX(0);
  }

  .button {
    max-width: 231px;
    justify-content: center;
  }

  .buttonText {
    margin-left: 0px;
  }
}

@media (min-width: 540px) and (max-width: 540px) {
  .selfTourWrapper {
    margin-left: -40% !important;
  }
}

.specialContainer {
  display: flex;
  width: auto;
  align-items: center;
  border-radius: 20px;
  background-color: #06845e;
  color: #fff;
}

.specialText {
  padding: 20px 40px 0 20px;
  font-size: 16px;
  font-weight: 400;
}

.specialExpDate {
  padding: 0 20px 20px 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
}

.infoText {
  padding: 20px 40px 20px 20px;
  font-size: 14px;
  font-weight: 400;
}

.specialContainer {
  display: flex;
  width: auto;
  align-items: center;
  border-radius: 20px;
  background-color: #06845e;
  color: #fff;
}

.specialText {
  padding: 20px 40px 0 20px;
  font-size: 16px;
  font-weight: 400;
}

.specialExpDate {
  padding: 0 20px 20px 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
}

.infoText {
  padding: 20px 40px 20px 20px;
  font-size: 14px;
  font-weight: 400;
}

.mobileDisclaimerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.8;
  z-index: 99;
}

.mobileDisclaimerTextWrapper {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  border: 1px solid #727272;
  border-radius: 5px;
  z-index: 1001;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 400;
  color: #3f3f3f;
  background: white;
}

.toolTipClose {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 25px;
  transition: all 0.15s;
  cursor: pointer;
}

.mobileDisclaimerContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  margin-top: 50%;
}

.mobilePriceDisclaimerText {
  padding: 20px 40px 20px 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Outfit', sans-serif;
}

.widgetClose {
  width: 80px;
  color: white;
  background: #333;
  border-radius: 100px;
  padding: 2px 8px;
  position: fixed;
  bottom: 75px;
  right: calc(5% + 170px);
  z-index: 1000;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 479px) {
  .widgetClose {
    right: 40%;
  }
}

@media (max-width: 320px) {
  #ppcb_container {
    width: 10px;
  }
}

@media (min-width: 280px) and (max-width: 280px) {
  .starsContainer {
    align-self: normal !important;
  }
}

@media (min-width: 320px) and (max-width: 320px) {
  .starsContainer {
    align-self: normal !important;
  }
}

@media (min-width: 360px) and (max-width: 360px) {
  .starsContainer {
    align-self: normal !important;
  }
}

@media (min-width: 375px) and (max-width: 375px) {
  .starsContainer {
    align-self: normal !important;
  }
}

@media (min-width: 380px) and (max-width: 380px) {
  .starsContainer {
    align-self: normal !important;
    justify-content: left !important;
  }
}

@media (min-width: 384px) and (max-width: 384px) {
  .starsContainer {
    align-self: normal !important;
    justify-content: left !important;
  }
}

@media (min-width: 411px) and (max-width: 411px) {
  .starsContainer {
    align-self: normal !important;
  }
}

@media (min-width: 414px) and (max-width: 414px) {
  .starsContainer {
    align-self: normal !important;
  }
}

@media (min-width: 428px) and (max-width: 428px) {
  .starsContainer {
    align-self: normal !important;
    justify-content: left !important;
  }
}
