.areaColumn {
  flex-grow: 1;
}
.areaColumn:first-child {
  border-right: 1px solid hsla(0, 0%, 100%, 0.15);
}
.areaColumn:last-child {
  border-left: 1px solid hsla(0, 0%, 100%, 0.15);
}
.onlyColumn {
  flex-grow: 1;
}
@media (max-width: 480px) {
  .areaColumn {
    border: none;
  }
  .areaColumn:first-child {
    border-right: none;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
  }
  .areaColumn:last-child {
    border-left: none;
    border-top: 1px solid hsla(0, 0%, 100%, 0.15);
  }
}

.link {
  cursor: pointer;
  transition: all 125ms;
}

.link:hover {
  opacity: 0.8;
}
