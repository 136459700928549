/*DESKTOP*/
.af-portfolio-environmental-wrapper {
    margin-bottom: 90px;
}

.af-class-div-block-118 .af-portfolio-environmental {
    padding-bottom: 0px !important;
}

.af-portfolio-environmental {
    text-align:center;
}

.af-portfolio-environmental-infographic-data-wrapper {
    display:flex;

    flex-direction:row;

    margin-left: 18%;

    margin-bottom: 40px;
}

.af-view .af-portfolio-environmental  {
    display: flex;
    flex-direction: column;

}

.af-view .af-portfolio-environmental h2 {
    margin-left: 5%;
}

.af-view .af-portfolio-environmental .af-class-paragraph {
    text-align: center;
    width: 54%;
    margin-left: 26%;
}

/*LEFT COLUMN*/
.af-portfolio-environmental-infographic-data-wrapper-left {
    display:flex;

    flex-direction:column;

}

.af-portfolio-environmental-infographic-data-wrapper-left img {
    height: 300px;
    width: 150px;
    padding-top: 12px;
}






/*CENTER COLUMN*/
.af-portfolio-environmental-infographic-data-wrapper-center {
    display: flex;

    flex-direction: row;

    width: 920px; 

    align-items: center;
    justify-content: left; 
    
}

.af-portfolio-environmental-infographic-data-wrapper-center-mobile {
    display: none;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-column-1 {
    display: flex;

    flex-direction: column;

    justify-content: left;

    margin-left: 15px;
    margin-right: 70px;

}

.af-portfolio-environmental-infographic-data-wrapper-center-item-column-2 {
    display: flex;

    flex-direction: column;

    justify-content: center;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-row {
    display: flex;

    flex-direction: column;

    width: 490px;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-row img {
    display:none;   
}

.af-class-div-fact-details-item-report-image-wrapper {
    margin-top: 12px !important;
}



.af-portfolio-environmental-infographic-data-wrapper-center-item-column-2 .af-portfolio-environmental-infographic-data-wrapper-center-item-row:nth-child(1) {
    margin-top: 30px;
    padding-bottom: 0px;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-column-2 .af-portfolio-environmental-infographic-data-wrapper-center-item-row:nth-child(2) {
    margin-top: 35px;
    padding-bottom: 0px;
}

.af-portfolio-environmental-infographic-data-item-attention{
    margin-top: 49px;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-progress-label {
    margin-right: 2px;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-progress-value {
    font-weight: bold;

    margin-right: 5px;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-progress-date-label {
    margin-right: 3px;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-goal-label {
    margin-right: 2px;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-goal-value {
    font-weight: bold;

    margin-right: 5px;
}

.af-portfolio-environmental-infographic-data-wrapper-center-item-goal-date-label {
    margin-right: 3px;
}


/*RIGHT COLUMN*/
.af-portfolio-environmental-infographic-data-wrapper-right {
    display:flex;

    flex-direction:column;

    justify-content: center;
}

.af-portfolio-environmental-infographic-data-wrapper-right img {
    height: 185px;
    width: 150px;
}


@media (min-width: 1900px) and (max-width: 1924px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 907px !important;    
    }  


    .af-class-div-fact-details-item-report-version-archived-wrapper{
        margin-top: 28px !important;
    }
}

@media (min-width: 1281px) and (max-width: 2400px) {
    .af-class-div-fact-details-item-report-version-archived-wrapper{    
        margin-top: 28px !important;
    }
}

@media (min-width: 1280px) and (max-width: 1280px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental {
        margin-left: 5% !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left: 15% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 415px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 480px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 18px;
    }
}

@media (min-width: 1152px) and (max-width: 1152px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental {
        margin-left: 0% !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left: 2% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 415px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 480px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 18px;
    }
}

@media (min-width: 1024px) and (max-width: 1024px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left: 12% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 415px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 480px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 100px !important;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 18px;
    }
}

@media (min-width: 912px) and (max-width: 912px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental {
        margin-left: 23% !important;
    }

   .af-portfolio-environmental-infographic-data-wrapper {
        margin-left: 28% !important;
    }  


    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 320px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 380px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

   .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 105px !important;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 15px;
    }
}

@media (min-width: 834px) and (max-width: 834px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental {
        margin-left:4% !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left:4% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 305px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 370px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 14px;
    } 
}

@media (min-width: 820px) and (max-width: 820px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental {
        margin-left:15% !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left:20% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 305px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 370px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 14px;
    } 
}

@media (min-width: 810px) and (max-width: 810px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental {
        margin-left:2% !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left:2% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 305px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 370px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 14px;
    } 
}

@media (min-width: 768px) and (max-width: 768px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
   .af-portfolio-environmental {
        margin-left:10% !important;
    }


    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left:12% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 275px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 340px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 12px;
    }
}

@media (min-width: 744px) and (max-width: 744px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
   .af-portfolio-environmental {
        margin-left:3% !important;
    }


    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left:2% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 275px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 340px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 12px;
    }
}

@media (min-width: 730px) and (max-width: 730px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
   .af-portfolio-environmental {
        margin-left:3% !important;
    }


    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left:1% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 275px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 340px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 275px;
        width: 125px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 115px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 12px;
    }
}

@media (min-width: 600px) and (max-width: 600px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
   .af-portfolio-environmental {
        margin-left:3% !important;
    }


    .af-portfolio-environmental-infographic-data-wrapper {
        margin-left:2% !important;
    }  

    .af-portfolio-environmental-infographic-data-wrapper-center {
        width: 275px !important;    
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-item-row {

       width: 340px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-left img {
        height: 270px;
        width: 80px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right img {
        width: 90px;
    }

    /*STEP 3: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-item-header {
        font-size: 12px;
    }
}


/*MOBILE*/

@media (min-width: 500px) and (max-height: 768px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile {
        padding-left: 45px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 250px;
    }   

    .af-portfolio-environmental-wrapper:nth-child(2) {
        font-size: 22px !important;
    }  

    
    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
        line-height: 20px !important;
    }

    .af-portfolio-environmental-wrapper:nth-child(1) div h2 {
        font-size: 22px !important;
    }  


}


@media (max-width: 540px) {
    /*STEP 0: RESET DESKTOP VALUES*/
    .af-portfolio-environmental {
        margin-left: 0% !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper{
        display: flex !important;
        flex-direction: row !important;
        
        margin-left: 0% !important;

        margin-bottom: 0px !important;
    }

    /*STEP 1: HIDE DESKTOP [LEFT & RIGHT] COLUMNS */
    .af-portfolio-environmental-infographic-data-wrapper-left {
        display:none;
    }

    .af-portfolio-environmental-infographic-data-wrapper-right {
        display:none;
    }


    /*STEP 2: SHOW MOBILE ICONS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row {
        margin-bottom: 25px;

  
    }

    /*STEP 3: HIDE [CENTER] COLUMN DESTOP */
    .af-portfolio-environmental-infographic-data-wrapper-center {
        display: none;
    }

    /*STEP 4: SHOW [CENTER] COLUMN MOBILE */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile {        
        display: block;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-column {
        display: flex;
        flex-direction: column;
    }

    /*STEP 5: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 60px;
    }

    /*STEP 6: ADJUST IMAGES */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row img {
        height: 70px;
        width: 160px;
    }

    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 95px !important;
    }

    /*STEP 7: ADJUST TEXT */
    .af-class-div-fact-details-item-report-version-main {
        border-bottom-width: 0px !important;
    }

    .af-portfolio-environmental-infographic-data-mobile-item-header {
        font-size: 18px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-progress-label {
        margin-right: 2px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-progress-value {
        font-weight: bold;

        margin-right: 5px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-progress-date-label {
        margin-right: 3px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-goal-label {
        margin-right: 2px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-goal-value {
        font-weight: bold;

        margin-right: 5px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-goal-date-label {
        margin-right: 3px;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    .af-portfolio-environmental-infographic-data-item-attention{
        font-size: 10px;
        margin-left: 15px;
        margin-top: 10px;
    }
}

@media (min-width: 540px) and (max-height: 720px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile {
        padding-left: 40px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 250px;
    }   

    .af-portfolio-environmental-wrapper:nth-child(2) {
        font-size: 22px !important;
    }  

    
    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
        line-height: 20px !important;
    }

    .af-portfolio-environmental-wrapper:nth-child(1) div h2 {
        font-size: 22px !important;
    }  
}

@media (min-width: 428px) and (max-width: 428px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-column {
        margin-left: 14px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 165px;
    }  

    .af-portfolio-environmental-wrapper div .af-portfolio-environmental {
        padding-right: 0px !important;
    }

    .af-portfolio-environmental:nth-child(1) {
        padding-left: 0px !important;
    }
    
    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
         line-height: 20px !important;
    }

    .af-portfolio-environmental h2 {
        font-size: 18px !important;
    }

    .af-portfolio-environmental .af-class-paragraph {
        font-size: 16px !important;
    }


    /*STEP 3: ADJUST IMAGES */
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }

}

@media (min-width: 412px) and (max-width: 414px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-column {
        margin-left: 14px;
    }

    .af-portfolio-environmental:nth-child(1) {
        padding-left: 5px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 197px;
    }  
    
    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
         line-height: 20px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 3: ADJUST IMAGES */
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }
}

@media (min-width: 390px) and (max-width: 393px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-column {
        margin-left: 14px;
    }

    .af-portfolio-environmental:nth-child(1) {
        padding-left: 10px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 175px;
    }    

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }

    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
         line-height: 20px !important;
    }

    /*STEP 3: ADJUST IMAGES */
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }
}


 /*IPHONES */
@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) {
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 130px !important;
        width: 155px !important;
    }
}


@media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) 
{
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 115px !important;
        width: 155px !important;
    }
}

@media (min-width: 375px) and (max-width: 375px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental {
        margin-left: 0% !important;
    }


   .af-portfolio-environmental-wrapper {
        margin-left: 5px !important;
    } 


    .af-portfolio-environmental:nth-child(1) {
        padding-left: 50px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile {
       padding-left: 0px !important; 
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-column {
        margin-left: 12px;
    } 

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 159px;
    }    

    .af-class-div-block-118 .af-portfolio-environmental h2 {
        line-height: 0px !important;
    }    


    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
        line-height: 20px !important;
    }
 
    .af-class-div-block-118 .af-portfolio-environmental h2 {
        line-height: 0px !important;
    } 

    .af-class-div-fact-details-item-report-version-main a div {
        font-size: 12px !important;

        padding-top: 2.25px !important;
    }

    .af-class-div-fact-details-item-report-version-archived-wrapper .af-class-div-fact-details-item-report-version-archived-label {
        font-size: 12px !important;
    }

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 10px !important;
    }
    
    /*STEP 3: ADJUST IMAGES */
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 112px;
        width: 135px !important;
    }
}

@media (min-width: 360px) and (max-width: 360px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-column {
        margin-left: 14px;
    }


    .af-portfolio-environmental:nth-child(1) {
        padding-left: 60px !important;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile {
       padding-left: 15px !important; 
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 147px;
    }    

    .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 13px !important;
    }

    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
         line-height: 20px !important;
    }

    /*STEP 3: ADJUST IMAGES */
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 135px;
        width: 175px !important;
    }
}

@media (min-width: 320px) and (max-width: 320px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 102px;
    } 

   .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 12px !important;
    }

    
    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
         line-height: 20px !important;
    }


    .af-class-div-fact-details-item-report-version-main a div {
        font-size: 10px !important;
    }

    .af-class-div-fact-details-item-report-version-archived-wrapper div {
        font-size: 10px !important;
    }

    /*STEP 3: ADJUST IMAGES */
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 125px;
        width: 180px !important;
    }
}

@media (min-width: 280px) and (max-width: 280px) {
    /*STEP 1: ADJUST PADDING/MARGINS */
    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(odd) {
        margin-left: 15px;
    }

    .af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row:nth-child(even) {
        margin-left: 92px;
    } 

   .af-class-div-fact-details-item-report-image-wrapper {
        margin-top: 20px !important;
    }

    
    /*STEP 2: ADJUST TEXT */
    .af-portfolio-environmental-infographic-data-mobile-item-header{
         line-height: 20px !important;
    }
    
    .af-class-div-fact-details-item-report-version-main a div {
        font-size: 12px !important;
    }

    /*STEP 3: ADJUST IMAGES */
    .af-class-div-fact-details-item-report-image-wrapper img {
        height: 165px;
        width: 225px !important;
    }
}




