.dropdown {
  justify-content: center !important;
  border: unset !important;
  margin-left: unset !important;
  display: block !important;
  margin-top: 0px !important;
}
@media (max-width: 767px) {
  .bigImage {
    margin: 0;
  }
}