.photoCredits {
  position: absolute;
  bottom: -20px;
}
.photoCredits > a {
  text-decoration: none;
}
.photoCredits > a:hover {
  opacity: 0.8;
}
.headerImage {
  position: relative;
}
.backToHome {
  cursor: pointer;
  align-items: flex-start !important;
}
.blogTag {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px !important;
}
.tagsContainer {
  all: unset !important;
  width: 100% !important;
}
.shareIcon {
  padding-left: 10px;
  display: inline-block;
  cursor: pointer;
  transition: all 125ms;
}

.shareIcon:hover {
  opacity: 0.8;
}
.iconWrapper {
  display: flex;
  flex-direction: row;
}

.arrow {
  top: 0 !important;
  width: 20px;
  height: 25px;
  padding-left: 2px;
}
@media (max-width: 767px) {
  .headerImage {
    margin-bottom: 40px;
  }
}
