.wrapper {
  cursor: pointer;
}

.wrapper:hover {
  opacity: 0.88;
}

.selected {
  border: 1px solid #018094;
  background-color: #fafafa;
}
