.authorWrapperImage {
  padding-right: 10px;
}

.authorWrapperLinkFont {
  font-family: 'Outfit', sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: black;
}

.authorWrapperLinkFont:hover {
  color: gray;
}

.blogContainer > * > a,
.blogContainer > * > * > a {
  color: #4c698f;
  text-decoration: none;
}
.blogContainer > h2 > a:hover {
  opacity: 0.8;
}
.blogContainer > h2,
.blogContainer > h3,
.blogContainer > h4 {
  border-left: 5px solid #ecd925;
  padding-left: 15px;
  transform: translateX(-20px);
}
.noImage {
  width: 100% !important;
}
.blogContainer > div[data-oembed-type='video'] {
  display: flex;
  justify-content: center;
  margin: 10px;
}
@media (max-width: 479px) {
  .noImage {
    padding-top: 40px !important;
  }
  .blogContainer > div[data-oembed-type='video'] > iframe {
    height: auto;
  }
  .blogContainer > div[data-oembed-type='rich'] > iframe {
    min-width: 250px !important;
  }
  .blogContainer > div {
    max-width: 100vw;
    overflow: hidden;
  }
}
