.react-date-picker__inputGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 12px;
}
.react-date-picker__wrapper {
  z-index: 99;
}
