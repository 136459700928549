.wrapper {
  width: calc(50vw - 10px);
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  margin: 0 5px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

.image {
  height: 100%;
  object-fit: cover;
}

.preLeaseDate {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin: 0px !important;
}

.preLeaseDate > p {
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
}

.sliderWrapper {
  position: relative;
  height: 180px;
}

.sliderController {
  position: absolute;
  top: 50%;
  width: 25px !important;
  cursor: pointer;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  z-index: 15;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.sliderController:hover {
  transform: translate(0px, -50%) scale(1.15) !important;
}

.leftArrow {
  left: 10px;
  right: auto;
}

.rightArrow {
  left: auto;
  right: 10px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 1px;
}

.infoPanel {
  padding: 14px 7px;
  position: relative;
  flex-grow: 1;
  flex-basis: 1px;
  display: flex;
  flex-direction: column;
}

.infoTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #686868;
}

.infoBodyRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 6px;
  flex-grow: 1;
  flex-basis: 1px;
}

.infoContactsCol {
  display: flex;
  flex-direction: column;
  color: #686868;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  margin-right: 10px;
}

.infoDetailsCol {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  min-width: fit-content;
  height: 100%;
}

.infoAddress {
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  margin: 0;
}

.infoPhone {
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  margin: 0;
  color: #4c698f;
}

.perksContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 10%;
}

.perkIcon {
  width: 20px;
  height: 20px;
}

.beds {
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  color: #686868;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.pricingButton,
.pricingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  padding: 0;
}

.pricing {
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  color: #4477c1;
  margin: 0;
}

.specialsBanner {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 15px;
  border-bottom-left-radius: 3px;
  background-color: #00c58a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.specialsText {
  font-family: 'Outfit', sans-serif;
  font-size: 8px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.disclaimerContainer {
  position: relative;
  height: 11px;
  width: 11px;
  margin-right: 2px;
}

.disclaimerContainer img {
  height: 11px;
  width: 11px;
  position: absolute;
  left: 0;
  top: 2px;
}

.showDisclaimer {
  display: block !important;
}

.priceDisclaimer {
  display: none;
  width: calc(50vw - 10px);
  border: 1px solid #727272;
  padding: 20px;
  position: absolute;
  z-index: 16;
  background: white;
  bottom: 30px;
  left: calc(-25vw + 15px);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 400;
}

.toolTipArrow {
  display: none;
  left: -2px;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 20px;
  z-index: 16;
  border-left: 1px solid #787878;
  border-bottom: 1px solid #787878;
  transform: rotate(-45deg);
  background: white;
}

.linkWrapper {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  color: white;
  padding: 7px 0;
}

.viewProperty::after {
  font-family: 'webflow-icons';
  content: '\E600';
  margin-left: 10px;
}

.specialModal {
  position: absolute;
  bottom: 3em;
  background-color: white;
  color: rgb(63, 63, 63);
  font-size: 12px;
  padding: 1.5em;
  width: calc(50vw - 10px);
  border: 1px solid rgb(63, 63, 63);
  border-radius: 10px;
  right: 0;
  display: none;
  z-index: 20;
}

.specialModal::after {
  content: ' ';
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -16px;
  right: 15px;
  background-color: white;
  transform: rotate(45deg);
  border: 1px solid rgb(63, 63, 63);
  border-top: 1px solid white;
  border-left: 1px solid white;
}

.specialExpiration {
  text-align: right;
  font-weight: 700;
}

.specialModalClose {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  height: 1.5em;
  width: 1.5em;
}

@media (max-width: 720px) {
  .wrapper {
    display: flex;
  }

  .infoIcon:hover ~ .toolTipArrow {
    display: block !important;
  }

  .infoIcon:hover + .priceDisclaimer {
    display: block !important;
  }

  .infoIcon:hover ~ .toolTipArrow {
    display: block !important;
  }

  .preLeaseDate {
    flex-direction: column;
    align-items: flex-end;
  }
}
