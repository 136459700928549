.af-class-list-blocks-container > span {
    flex: 0 1 25% !important;
    height: auto !important;
}
.af-view .af-class-list-blocks-header {
    margin-top: 0px !important;
}
@media (max-width: 767px) {
    .af-class-list-blocks-container > span {
        flex-basis: 200px !important;
    }
}
@media (max-width: 479px) {
    .af-class-list-blocks-container > span {
        flex-basis: 100% !important;
    }
    .af-view .af-class-list-block-label {
        padding-left: unset;
        width: 55%;
        text-align: left;
        font-size: 18px;
        overflow: hidden;
      }
}
