.wrapper {
  width: 100%;
  background-image: linear-gradient(180deg, #3b3b3c, #333);
  padding: 15px 0;
  border-bottom: 1px solid white;
}
.text {
  margin: auto;
  width: 90%;
  display: block;
  color: white;
  max-width: 1200px;
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
  font-size: 14px;
}
.link {
  text-decoration: none;
  color: white;
  margin-left: 2px;
}

.coverageLink {
  text-decoration: none;
  color: white;
}
