.mapContainer {
  height: 85vh;
  width: 100% !important;
  background-image: none !important;
  padding: 0 !important;
  position: relative !important;
}
.wrapper {
  background-image: none !important;
  min-height: 400px !important;
}

.mapElement {
  height: 100%;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}

.zoomButton {
  pointer-events: all !important;
  cursor: pointer;
}

.zoomButton:hover {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}

.clickableImage {
  position: relative;
  pointer-events: all !important;
  cursor: pointer !important;
  transition: all 150ms;
  object-fit: contain;
}

.clickableImage:hover {
  opacity: 0.88;
  transform: scale(1.2);
}

.markerContainer {
  position: relative;
  width: 30px;
  height: 30px;
  background: #f5a623;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.markerIcon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 90%;
  height: 90%;
}

.entertainment {
  composes: markerContainer;
  background-color: #3f3f3f;
}
.transit {
  composes: markerContainer;
  background-color: #3f3f3f;
}
.perks {
  composes: markerContainer;
  background-color: #f5a623;
}
.restaurants {
  composes: markerContainer;
  background-color: #3f3f3f;
}

.schools {
  composes: markerContainer;
  background-color: #3f3f3f;
}

.key_buildings {
  composes: markerContainer;
  background-color: #3f3f3f;
}

.markerLabel {
  position: absolute;
  top: -40px;
  font-size: 13px;
  color: #333333;
  left: -60px;
  width: 120px;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
}

.locations-map-retail-modal {
  position: absolute;
  top: 0%;
  right: 30px;
  width: 250px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate(0px, -10%);
  -ms-transform: translate(0px, -10%);
  transform: translate(0px, -10%);
  z-index: 9;
}

.locations-map-retail-modal-bottom-section {
  padding: 20px;
  border-top: 1px solid #d2d2d2;
}

.locations-map-retail-modal-top-section {
  padding: 40px 20px 20px;
}

.map-retail-modal-address {
  margin-top: 15px;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
}

.locations-modal-description {
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  word-break: break-all;
}

.locations-modal-description.light {
  position: relative;
  z-index: 2;
  color: #fff;
}

.locations-modal-description.dark {
  color: #3f3f3f;
}

.locations-modal-description.cards-testimonial {
  margin-bottom: 0px;
  padding: 20px 25px 50px;
  background-color: #fff;
}

.locations-modal-description.centered {
  text-align: center;
}

.locations-modal-description.centered.large {
  font-size: 17px;
  line-height: 32px;
}

.locations-modal-description.faq-answer {
  margin-bottom: 20px;
}

.locations-modal-description.small {
  font-size: 14px;
  line-height: 25px;
}

.locations-modal-description.small.light.margin-top {
  margin-top: 30px;
}

.locations-modal-description.margin-top {
  margin-top: 30px;
}

.locations-modal-description.sustainable-feature {
  font-size: 14px;
  line-height: 24px;
}

.locations-modal-description.author-bio {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.div-block-423 {
  display: flex;
  align-items: center;
  color: #b47208;
  font-size: 16px;
  line-height: 17px;
  font-weight: 500;
}
.div-block-423 img {
  width: 9px;
  margin-left: 10px;
}
.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  background: #222;
  color: #fff;
  font-size: 14px;
  font-family: cursive;
  cursor: pointer;
}

.af-view .af-class-text-block-80 {
  cursor: pointer;
}

.interest {
  transition: all 125ms;
  cursor: pointer;
}

.interest:hover {
  opacity: 0.8;
}

.poiHeader {
  position: absolute;
  padding: 5px 15px 8px 15px;
  transform: translate(0, -45px);
  border-radius: 3px 3px 0 0;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: white;
  letter-spacing: 3px;
  margin: auto;
}
@media (max-width: 991px) {
  .poiHeader {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .interest {
    padding: 5px 0px !important;
    border-right: none !important;
    flex: 1 0 auto !important;
    width: 33% !important;
  }
}
@media (max-width: 479px) {
  .poiHeader {
    font-size: 8px;
    padding: 0px 10px 0px 10px;
    transform: translate(0, -32px);
  }
}
@media (max-width: 479px) {
  .wrapper {
    height: 85vh !important;
  }
}
