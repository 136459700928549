@media all and (-ms-high-contrast:none) {
  .image {
    height: 100% !important;
  }
}
@media (min-width: 480px) {
  .goldStar {
    padding: 20px !important
  }
}
@media (min-width: 992px) {
  .image {
    width: auto !important;
  }
}
