.wrapper {
    align-self: flex-end;
    margin-right: 0 !important;
    max-width: 300px !important;
}
.date {
    white-space: nowrap;
    font-size: 20px;
}
.startDate {
    margin-right: 5px;
}
.endDate {
    margin-left: 5px;
}
.arrow {
    margin: 0 5px;
}

.button {
    cursor: pointer;
}

.button:hover {
    opacity: 0.80;
}
