.floorplan-list-item {
  height: 160px;
  margin-bottom: 16px;
  display: grid;
  grid-template-areas:
    'thumbnail-container title-container'
    'thumbnail-container info-container'
    'thumbnail-container .';
  grid-template-columns: 160px 1fr;
  grid-template-rows: 65px 75px 20px;
}

.floorplan-list-item > .floorplan-thumbnail {
  grid-area: thumbnail-container;
  width: 160px;
  height: 160px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.floorplan-list-item > .floorplan-thumbnail > .floorplan-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 115px;
  width: 100%;
  padding: 5px 5px;
  overflow: hidden;
}

.floorplan-list-item > .floorplan-thumbnail > .floorplan-img-container img {
  object-fit: contain;
}

.floorplan-list-item > .floorplan-thumbnail > .floorplan-3d-tour {
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}

.floorplan-list-item > .floorplan-thumbnail > .floorplan-3d-tour svg {
  height: 24px;
  width: auto;
  margin-left: 5px;
}

.floorplan-list-item > .floorplan-title-container {
  grid-area: title-container;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
}

.floorplan-list-item > .floorplan-title-container * {
  margin: 0;
}

.floorplan-list-item > .floorplan-title-container > h3 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  height: 24px;
  margin-bottom: 6px;
}

.floorplan-list-item > .floorplan-title-container > .floorplan-perk-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
  padding-left: 16px;
}

.floorplan-list-item > .floorplan-title-container > .floorplan-perk-container > .floorplan-perk {
  border-radius: 20px;
  height: 23px;
  margin-right: 12px;
  font-size: 10px;
  color: white;
  line-height: 15px;
  letter-spacing: 1px;
  padding: 2px 8px 6px 8px;
  min-width: fit-content;
}

.floorplan-list-item > .floorplan-title-container > h4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: right;
  height: 12px;
  margin-bottom: 10px;
}

.floorplan-list-item > .floorplan-information-container {
  grid-area: info-container;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  padding-left: 24px;
  height: 100%;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-information-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 32px;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-information-stats .floorplan-info.floorplan-size {
  width: 110px;
  margin-right: 0;
}

.floorplan-list-item > .floorplan-information-container .floorplan-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 13px 16px 0 0;
  height: calc(100% - 13px);
}

.floorplan-list-item > .floorplan-information-container .floorplan-info > h5 {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #707070;
  text-transform: uppercase;
}

.floorplan-list-item > .floorplan-information-container .floorplan-info > p {
  margin: 4px 0 0 0;
  font-size: 16px;
  line-height: 16px;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-availability {
  margin-right: 32px;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-availability p {
  min-width: 122px;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-pricing {
  margin-right: 32px;
  font-weight: 700;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-quote-container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 13px;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-quote-container a {
  text-decoration: none;
  height: 30px;
}

.floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-quote-container a .floorplan-quote {
  height: 30px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: white;
  padding: 4px 12px 8px;
  overflow: visible;
  min-width: max-content;
}

@media (max-width: 850px) {
  .floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-availability {
    margin-right: 24px;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-information-stats {
    margin-right: 24px;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-information-stats .floorplan-info.floorplan-size {
    width: fit-content;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-availability p {
    min-width: fit-content;
  }
}

@media (max-width: 691px) {
  .floorplan-list-item {
    height: 242px;
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 0;
    display: block;
    position: relative;
  }

  .floorplan-list-item > .floorplan-thumbnail {
    width: 121px;
    height: 121px;
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .floorplan-list-item > .floorplan-thumbnail > .floorplan-img-container {
    height: 100%;
    width: 100%;
  }

  .floorplan-list-item > .floorplan-title-container {
    height: 63px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    padding: 0;
    position: absolute;
    top: 16px;
    left: 141px;
    width: calc(100% - 141px);
  }

  .floorplan-list-item > .floorplan-title-container > .floorplan-perk-container {
    padding: 0;
    width: 100%;
    overflow-x: scroll;
  }

  .floorplan-list-item > .floorplan-title-container > h4 {
    position: absolute;
    right: 16px;
    bottom: -120px;
  }

  .floorplan-list-item > .floorplan-information-container .floorplan-info {
    margin: 0;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-information-stats .floorplan-info.floorplan-size {
    position: absolute;
    top: 95px;
    left: 141px;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-information-stats .floorplan-info.floorplan-beds {
    position: absolute;
    top: 95px;
    left: 258px;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-information-stats .floorplan-info.floorplan-baths {
    position: absolute;
    top: 95px;
    left: 315px;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-availability {
    margin: 0;
    position: absolute;
    left: 141px;
    top: 183px;
  }

  .affordable {
    left: 16px !important;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-pricing {
    margin: 0;
    position: absolute;
    left: 16px;
    top: 183px;
  }

  .floorplan-list-item > .floorplan-information-container > .floorplan-availability-pricing-container .floorplan-quote-container a .floorplan-quote {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}

.floorplan-close-button {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1000 !important;
  visibility: visible !important;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 700;
}
