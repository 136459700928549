@media screen and (max-width: 991px) {
    .af-class-div-block-169 {
        width: 100% !important;
    }

    .af-class-div-block-168 {
        flex-basis: calc(25% - 12px) !important;
        width: auto !important;
    }
}

@media screen and (max-width: 767px) {
    .af-class-div-block-168 {
        flex-basis: calc(33% - 12px) !important;
        width: auto !important;
    }
}

@media screen and (max-width: 479px) {
    .af-class-div-block-168 {
        flex-basis: calc(50% - 12px) !important;
        width: auto !important;
    }
}

@media screen and (max-width: 1440px) {
    .af-view .af-class-div-block-172  {
        margin-top: -10% !important;
    }
}

@media screen and (max-width: 768px) {
    .af-view .af-class-div-block-172  {
        margin-top: -15% !important;
    }
}

.af-class-list-perks .af-class-div-block-172 {
    transform: translate(0px);
    margin-top: -2%;
}
