.text {
  font-size: 17px !important;
}
@media (min-width: 991px) {
  .text {
    height: 100%;
  }
}
.button {
  position: relative;
  display: inline-block !important;
  text-align: center;
}

.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  position: absolute;
  right: 5px;
  left: 88%;
}
