.title {
    font-size: 32px!important;
    margin-bottom: 0px!important;
    margin-top: 0px!important;
}
.text_blurb {
    font-size: 18px;
}
@media only screen and (max-width: 991px) {
    .text_blurb {
        font-size: 15px;
        line-height: 27px
    }
    .title {
        margin-top: 10px!important;
        margin-bottom: 20px!important;
        font-size: 36px!important;
        line-height: 42px!important;
        font-weight: 700!important;
    }
}
@media only screen and (max-width: 768px) {
    .title {
        font-size: 24px !important;
        line-height: 28px !important;
        margin-bottom: 10px !important;
    }
}
@media only screen and (max-width: 479px) {
    .text_blurb {
        font-size: 14px!important;
    }
    .title {
        font-size: 30px!important;
    }
}
