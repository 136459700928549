.highlights {
  line-height: 1 !important;
}

.button {
  cursor: pointer !important;
  transition: all 125ms;
}

.button:hover {
  opacity: 0.8;
}
