@media (min-width: 480px) and (max-width: 767px) and (-ms-high-contrast:none) {
  .textWrapper {
    margin: 0 15px !important;
    padding: 40px 20px !important;
  }
  .textWrapper > p {
    font-size: 25px;
  }
  .textWrapper > a > img {
    margin-left: 0 !important;
  }
}
@media (max-width: 480px) and (-ms-high-contrast:none) {
  .textWrapper {
    flex-basis: auto !important
  }
}
