.bannerWrapper {
  font-family: 'Outfit', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  background-color: #383838;
  text-align: center;
  color: white;
  height: 50px;
  font-size: 16px;
}

.buttonWrapper {
  display: flex;
  align-items: baseline;
}

.buttonText {
  color: white;
  text-decoration: underline;
}

.buttonArrow {
  cursor: pointer;
  height: 10px;
  margin-left: 5px;
}

.bannerWrapper > p {
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .bannerWrapper {
    font-size: 15px;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
    height: 60px;
  }
}
