.af-class-spotlight-3-points {
    padding-top: 50px !important;
}
.af-class-spotlight-3-points > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.af-class-_3-spotlight-description  {
    flex-basis: 100%;
    flex: 1 1 auto;
    width: 100%;
    margin-top: 30px;
}

.af-view .af-class-spotlight-3-first-column {
    width: unset !important;
    flex-basis: 36%;
}
.af-view .af-class-spotlight-3-point-column {
    flex-basis: 30%;
}
@media (min-width: 1600px) {
    .af-view .af-class-spotlight-3-points {
        max-width: 1600px;
        margin: 0 auto;
    }
    .af-view .af-class-spotlight-3-point-full-bleed-img {
        height: 300px;
    }
}
@media all and (-ms-high-contrast:none) and (max-width: 767px) {
    .af-view .af-class-spotlight-3-first-column {
        flex-basis: auto;
    }
    .af-view .af-class-spotlight-3-point-column {
        flex-basis: auto;
    }
}
