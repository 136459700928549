@media (min-width: 768px) {
    .af-class-short-term-list > span {
        flex: 0 1 45% !important;
        width: auto !important;
        height: auto !important;
    }
}

.af-class-short-term-list-item {
    width: 100% !important;
}
.af-view .af-class-div-block-285 {
    margin-top: 0px;
    padding-top: 10%;
}
@media (min-width: 2100px) {
    .af-view .af-class-div-block-285 {
        padding-top: 5%;
    }
}
@media (min-width: 991px) and (max-width: 1400px) {
    .af-view .af-class-div-block-285 {
        margin-top: 2%;
    }
}
@media (max-width: 991px) {
    .af-view .af-class-div-block-285 {
        padding-top: 0 !important;
        margin-top: 0;
    }
}
@media (max-width: 767px) {
    .af-view .af-class-div-block-283 {
        justify-content: flex-start;
    }
    .af-class-short-term-list {
        flex-direction: column;
    }
}
@media (max-width: 767px) and (-ms-high-contrast: none) {
    .af-class-short-term-list.w-list-unstyled > span {
        flex: 0 1 auto !important;
    }
}
