.sliderContainer {
    padding-top: 40px !important;
    background-position: center center;
    background-size: cover;
    position: relative;
    align-items: flex-start !important;
}

.leftArrow,
.rightArrow {
    transition: all 100ms;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftArrow:hover,
.rightArrow:hover {
    background: rgba(255, 255, 255, 0.7);
    cursor: pointer;
}

.arrow {
    width: 12px;
    margin-left: 4px;
}

.left {
    transform: rotate(180deg);
    margin-right: 8px;
}

.dotsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 94px;
    left: 0;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 50%;
    margin-left: 15px;
    transition: all 125ms;
    cursor: pointer !important;
}

.selectedDot {
    transform: scale(1.2);
}

@media (max-width: 767px) {
    .title {
        font-size: 32px !important;
        line-height: 32px !important;
    }
}
