.active {
  margin-left: 0px;
  padding-left: 0px;
  color: #008d94;
  font-weight: 700;
  cursor: pointer;
}

.notActive {
  padding-left: inherit !important;
  color: inherit !important;
  font-weight: inherit !important;
  cursor: pointer;
  transform: all 150ms;
  border-bottom: none !important;
}

.notActive:hover {
  opacity: 0.8;
}

.active,
.notActive {
  white-space: nowrap;
  margin-left: 10px !important;
  padding-left: 5px !important;
}

.active,
.notActive {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@media (max-width: 479px) {
  .active,
  .notActive {
    font-size: 14px !important;
  }
}