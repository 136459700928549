.container {
  flex: auto !important;
  width: 100% !important;
  height: 100%;
  min-width: 300px;
}

.signout {
  transition: all 125ms;
  line-height: 20px;
}

.signout:hover {
  background-color: #008d94;
  color: white;
}

.cta {
  cursor: pointer;
  transition: all 125ms;
}

.cta:hover {
  opacity: 0.88;
}

.expDate {
  font-family: 'Outfit', sans-serif;
}
.signedInText {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #727272;
  margin-bottom: 0px;
  margin-top: 30px;
}
.userEmail {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #333333;
  font-style: italic;
}
.noQuotes {
  margin-top: 30px;
  color: #628bc1;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  padding-left: 0px;
  display: block;
}
.noQuotes:hover {
  opacity: 0.8;
  cursor: pointer;
}
.noQuotesModal {
  position: absolute;
  top: 50%;
  left: 30%;
  z-index: 1;
  background: #fafafa;
  width: 50%;
  right: auto;
  box-shadow: 0 10px 70px 0 rgba(0, 0, 0, 0.4);
  padding: 30px;
}
.modalClose {
  width: 20px;
  padding: 0px;
  position: absolute;
  background: transparent;
  right: 0;
  top: 5px;
}
.modalClose img {
  position: absolute;
  top: 5px;
  right: 5px;
}
.modalText {
  font-size: 14px;
  font-weight: 300;
  color: #727272;
  text-align: center;
}
@media (max-width: 991px) {
  .container {
    padding-top: 0px !important;
  }
  .container > div {
    padding-top: 30px !important;
  }
}
@media all and (min-width: 992px) and (-ms-high-contrast: none) {
  .container {
    max-width: 25vw;
  }
}
@media (max-width: 767px) {
  .noQuotesModal {
    width: 80%;
    left: 10%;
  }
}
