.contentWrapper {
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}

.wrapper {
  margin-bottom: 0 !important;
  overflow: visible !important;
}

.teaserWrapper {
  background-color: #81a4d5;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: white;
  margin: 0;
  padding: 15px 0;
  position: relative;
  width: 100%;
}

.teaserText {
  color: white !important;
}

.arrowWrapper {
  align-items: center;
  background-color: #81a4d5;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 20px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 100%;
  width: 20px;
}

.arrow {
  color: white;
}

.arrowOpen {
  transform: translate(-50%, -50%) rotate(-180deg);
}

.contentOpen {
  background-color: #81a4d5;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: block !important;
  padding: 15px 20px;
  margin: auto;
  width: 95%;
}
.contentOpen li {
  list-style: circle;
  color: #ecd925;
  margin-bottom: 20px;
}
.contentOpen li > span {
  color: white;
  font-family: 'Outfit', sans-serif;
}
.contentOpen > p {
  color: white;
}
@media (min-width: 1600px) {
  .wrapper {
    width: 420px !important;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .wrapper {
    width: 95%;
    margin: auto;
    max-width: 375px !important;
  }
}
@media (max-width: 767px) {
  .wrapper {
    width: 100%;
    margin: auto;
  }
}
@media (max-width: 479px) {
  .wrapper {
    padding: 25px 0px 0px !important;
  }
  .teaserWrapper {
    position: relative !important;
  }
}
@media (-ms-high-contrast: none) {
  .wrapper {
    max-width: 310px !important;
  }
}
