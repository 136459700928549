.readMore {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
  font-weight: 600;
  margin-left: 5px;
}
.buttonArrow {
  padding-top: 4px;
  margin-left: 4px;
  transition: all 300ms ease-in-out;
}
.readMore:hover > .buttonArrow {
  transform: translate(5px);
  transition: all 300ms ease-in-out;
}
