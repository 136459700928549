quoteSummaryCardViewList1 {
  flex-wrap: wrap !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

quoteSummaryCardViewList1 > span {
  flex: 0 1 400px !important;
  width: auto !important;
  height: auto !important;
  margin-bottom: 40px;
}

quoteSummaryCardViewList1 > span > div {
  margin: 0 auto !important;
}
@media (max-width: 1215px) {
  .af-class-div-block-388 .af-class-quote-details-wrap {
    padding-right: 20px;
  }
  quoteSummaryCardViewList1 {
    justify-content: space-between;
  }
  quoteSummaryCardViewList1 > span {
    flex-basis: 45% !important;
  }
}
@media (max-width: 991px) {
  quoteSummaryCardViewList1 > span > div{
    flex-direction: column !important;
  }
}
@media (max-width: 767px) {
  quoteSummaryCardViewList1 > span  {
    flex-basis: 100% !important;
  }
  .af-view .af-class-floorplan-card-profile.af-class-quote-summary {
    max-width: none;
  }
}
@media (max-width: 991px) and (-ms-high-contrast:none){
  .af-view .af-class-floorplan-card-col-label {
    max-width: 90%;
  }
  quoteSummaryCardViewList1 > span {
    flex-basis: 47% !important;
  }
}
@media (max-width: 767px) and (-ms-high-contrast:none){
  quoteSummaryCardViewList1 > span {
    flex-basis: 100% !important;
  }
}
