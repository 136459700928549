.af-class-div-block-15 > span {
    flex: auto !important;
    width: auto !important;
}

.af-class-div-block-15 {
    z-index: 5 !important;
}
.visibility_hidden {
    visibility: hidden;
}