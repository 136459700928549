.wrapper {
  width: 90% !important;
  margin: auto !important;
  margin-bottom: 20px !important;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 355px;
}

.bottomContainer {
  flex-direction: column;
  justify-content: center;
}

.disclaimerApartmentSize {
  line-height: 10px;
  padding-left: 24px;
  padding-top: 5px;
  font-size: 10px;
  font-style: italic;
  display: flex;
}

@media (max-width: 691px) {
  .disclaimerApartmentSize {
    line-height: 10px;
    font-size: 10px;
    font-style: italic;
    position: absolute;
    bottom: 70px;
    left: 16px;
    padding-left: 0;
    padding-right: 10px;
  }
}

@media (max-width: 1115px) {
  .imageContainer {
    min-height: 280px;
  }
}

@media (min-width: 1300px) {
  .imageContainer {
    min-height: 375px;
  }
}

.tourButton {
  justify-content: center !important;
  margin-top: 15px;
  margin-left: 8em !important;
  margin-right: 8em !important;
  padding-top: 10px;
  cursor: pointer;
  width: 50%;
  border: 2px solid #eaeaea;
  box-shadow: none !important;
}

.tourButton:hover {
  color: white;
  border: none;
}

@media (max-width: 991px) {
  .tourButton {
    width: 150px;
    margin-left: 8em;
    margin-right: 8em;
  }
}

.available {
  padding: 5px 0 !important;
  font-weight: 600 !important;
}

.available,
.name {
  max-width: 45%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.priceWrapper {
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 55%;
  flex-direction: column;
}

.priceLabel {
  text-decoration: underline;
  cursor: pointer;
  text-align: end;
}

.toolTipClose {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 25px;
  transition: all 0.15s;
  cursor: pointer;
}

.price {
  margin-left: 5px !important;
  display: flex;
  justify-content: center;
  align-items: inherit;
}

.disclaimerContainer {
  position: fixed;
  z-index: 3;
}

.asterisk {
  font-size: 18px;
  padding-left: 2px;
}

@media (min-width: 768px) {
  .priceLabel:hover + .priceDisclaimer {
    display: block;
  }

  .priceLabel:hover ~ .toolTipArrow {
    display: block !important;
  }
}

.showDisclaimer {
  display: block !important;
}

.priceDisclaimer {
  display: none;
  width: 300px;
  border: 1px solid #787878;
  padding: 15px;
  padding-right: 20px;
  position: absolute;
  z-index: 2;
  background: white;
  right: -30px;
  bottom: 30px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #333;
}

.toolTipArrow {
  display: none;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 25px;
  z-index: 3;
  border-right: 1px solid #787878;
  border-top: 1px solid #787878;
  transform: rotate(135deg);
  background: white;
}

.beds,
.view,
.baths {
  white-space: nowrap;
}

.beds,
.baths,
.view,
.name,
.price {
  white-space: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.sqftmin,
.sqftmax,
.link,
.wrapper {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.view {
  cursor: pointer;
  padding-right: 10px !important;
}

.view:hover {
  opacity: 0.8;
}

.plant {
  width: 100% !important;
  height: 18vw !important;
  min-height: 175px;
  max-height: 250px;
  object-fit: contain;
}

.buttonArrow {
  margin-left: 15px;
}

.rightArrow {
  margin-right: 3px !important;
}

.bathLogo {
  margin-bottom: 3px;
  max-height: 20px;
  min-height: 13px;
}

.cardHeader {
  position: relative;
  height: 84px;
}

@media (max-width: 991px) {
  .available {
    max-width: initial;
  }

  .cardHeader {
    height: 68px;
  }
}

@media (max-width: 767px) {
  .availableLabel {
    padding-bottom: 0px !important;
  }
}

@media (max-width: 400px) {
  .priceDisclaimer {
    width: 250px;
  }
}
