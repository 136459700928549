@media only screen and (max-width: 991px) {
    .af-class-content-collage-retail-content-column > a {
        margin: 20px 0px;
    }   
} 
.af-class-content-collage-retail-content-column > a {
    background-color: #ecd925;
    background-image: linear-gradient(to right,#ecd925  17%, white 17% 100%);
}
.af-view .af-class-certifications-block {
    margin-top: 0 !important;
}
.af-class-certifications-block .af-class-cert-block-collage-column {
    margin-top: 0px !important;
}
.af-class-content-collage-retail-content-column {
    padding-top: 0 !important;
    padding-bottom: 20px !important;
}
.af-class-cert-block-collage-column {
    flex-basis: 50% !important;
}
@media only screen and (max-width: 479px) {
    .af-class-content-collage-retail-content-column {
        padding-top: 20px !important;
        padding-bottom: 0px !important;
    }
} 