@media (max-width: 479px){
  .af-class-sustainable-sub-nav {
    width: 90%;
    margin: auto;
  }
}
.fixed-nav-xlarge {
  position: fixed;
  top: 30px;
  z-index: 1000000;
  max-height: 60px;
}
.fixed-nav-large {
  position: fixed;
  top: 30px;
  z-index: 1000000;
  max-height: 60px;
}
.fixed-nav-med {
  position: fixed;
  top: 30px;
  z-index: 1000000;
}
.fixed-nav-sm {
  position: fixed;
  top: 0px;
  z-index: 1000000;
}
.fixed-nav-xs {
  position: fixed;
  top: 30px;
  z-index: 1000000;
}
