.wrapper {
  /* cursor: pointer; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: white !important;
  border: 1px solid #d2d2d2 !important;
}

/* .wrapper:hover {
  opacity: 0.88;
} */

.selected {
  border: 1px solid #018094;
  background-color: #fafafa;
}

.title,
.value {
  background-color: white !important;
}

.plusButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  transition: all 125ms;
}

.button:hover {
  opacity: 0.8;
  background-color: rgba(0, 0, 0, 0.1);
}
.plusSign {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 5px);
  width: 16px;
  height: 16px;
}
