
.af-class-content-retail-showcase-condensed .af-class-div-block-138 {
  max-width: 1920px !important;
}
.af-view .af-class-content-retail-showcase-condensed {
  padding-top: 0px !important;
}
.af-class-grid-highlight-images {
  margin-bottom: 40px !important; 
}

.af-class-retail-image-caption-wrap.af-class-blur  {
  width: 65% !important;
}

@media (max-width: 767px) and (-ms-high-contrast: none) {
  .af-view .af-class-div-block-140,
  .af-view .af-class-div-block-139 {
    flex-basis: auto !important;
  }
}
