.taglineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: -1;
  left: 0;
  opacity: 0;
  width: 100vw;
  transition: all 500ms;
  margin-top: 50px;
}

.showText {
  opacity: 1;
  margin-top: 0;
}

.bannerContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

.tagline {
  font-family: 'Outfit', sans-serif;
  font-size: 110px;
  line-height: 1;
  color: white;
  font-weight: 600;
  letter-spacing: 3px;
  text-shadow: -1px 3px 2px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.parallaxContainer {
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -2;
  width: 100%;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  pointer-events: none;
  top: 0;
  height: 95vh;
  box-sizing: content-box;
  opacity: 1;
}

.peopleContainer {
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  pointer-events: none;
  top: 0;
  height: 95vh;
  box-sizing: content-box;
  display: none;
}

.backgroundLoader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -4;
  opacity: 0;
}

.regionContainer:focus {
  box-shadow: inset 0px 0px 0px 3px rgb(77, 144, 254);
  outline: none;
}

.regionContainer {
  width: 23.25%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  text-decoration: none;
  transition: all 150ms;
  height: 50%;
  cursor: pointer !important;
}

.hasAlertBanner {
  top: 50px !important;
}
@media (max-width: 768px) {
  .parallaxContainer {
    background-position: 75% 40% !important;
    height: 95vh;
  }
  .continue {
    width: 100%;
    position: absolute;
    color: white;
    bottom: 6%;
    font-family: 'Outfit', sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .continue {
    bottom: 2%;
  }
}
@media (min-width: 769px) {
  .continue {
    display: none;
  }
}

@media (max-width: 769px) {
  .regionBox{
      margin-left: 0 !important;
  }
}

@media only screen and (max-height: 768px) and (min-width: 930px) {
  .regionBox,
  .bannerContainer,
  .peopleContainer {
    height: 700px !important;
  }
  .parallaxContainer {
    height: 800px !important;
  }
  .tagline:first-child {
    margin-top: 180px;
  }
  .regionSelectedButton {
    padding: 10px 10px !important;
  }
}
@media only screen and (max-height: 768px) and (min-width: 768px) and (max-width: 929px) {
  .regionBox,
  .bannerContainer,
  .peopleContainer {
    height: 900px !important;
  }
  .parallaxContainer {
    height: 1000px !important;
  }
  .tagline:first-child {
    margin-top: 180px;
  }
  .regionSelectedButton > span {
    font-size: 12px;
  }
  .regionSelectedButton {
    padding: 10px 10px !important;
  }
}
@media only screen and (max-height: 768px) and (min-width: 930px) and (-ms-high-contrast: none) {
  .tagline:first-child {
    margin-top: 30px;
  }
}
@media only screen and (max-height: 768px) and (min-width: 768px) and (max-width: 929px) and (-ms-high-contrast: none) {
  .tagline:first-child {
    margin-top: 30px;
  }
}

.emptyRegionContainer {
  width: 20%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  text-decoration: none;
  transition: all 150ms;
}

.regionBox {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden !important;
  height: calc(95vh - 100px);
  margin-top: 100px;
  margin-left: 12%;
}

.region {
  background: white;
  color: black;
  opacity: 0.85;
  text-align: center;
  width: 75%;
  padding: 4%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  white-space: nowrap;
  margin-top: 0;
  font-weight: 500;
}

.regionSecondRow {
  background: white;
  color: black;
  opacity: 0.85;
  text-align: center;
  width: 75%; /* Change to 100% to fit within the 25% width of container */
  padding: 4%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  white-space: nowrap;
  margin-top: auto;
  font-weight: 500;
}

.regionLabel {
  font-family: 'Outfit', sans-serif;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.regionSelectContainer {
  z-index: 10;
  position: absolute;
  bottom: 20%;
  right: 0;
  left: 0;
  top: 50%;
  display: none;
  align-items: flex-end;
  justify-content: center;
}

.regionSelect {
  margin-top: 5em;
  z-index: 10;
  width: 90%;
  height: 50px;
  border: none !important;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
  font-family: 'Outfit', sans-serif;
  letter-spacing: 0.8px;
  padding: 5px 15px;
  -webkit-appearance: none;
  appearance: none;
  background: white;
}

.arrow {
  border-radius: 0 4px 4px 0;
  position: absolute;
  z-index: 15;
  right: 5%;
  bottom: 0;
  width: 50px;
  height: 50px;
  display: flex;
  background-color: #e8d540;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  transition: all 150ms;
  pointer-events: none;
}

.arrow:hover {
  opacity: 0.88;
}

.arrowIcon {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.imageCommon {
  transition: opacity 500ms ease-out;
  object-fit: cover;
}

.regionCommon {
  transition: all 250ms;
}

.regionSelected {
  padding: 20px;
  display: flex;
  background-color: white;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  height: 85%;
  /* height: 100%; */
}
.regionSecondRowPersistent {
  bottom: -15% !important;
}

.regionSelectedBody {
  width: 100%;
  flex: 1;
}

.regionSelectedWrapper {
  width: 100%;
  flex: 1;
}

.regionSelectedButton,
.regionSelectedBody,
.regionSelectedLabel {
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.regionSelectedLabel {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  letter-spacing: 1.2px;
}

.regionSelectedBody {
  margin-top: 20px;
  color: #383838;
  font-size: 14px;
  line-height: 1.6;
}

.regionSelectedButton {
  display: flex;
  margin-top: 20px;
  padding: 10px 25px;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
  background-color: #4c698f;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 150ms;
  position: absolute;
  bottom: 25px;
}

.regionSelectedButton:hover {
  background-color: #628bc1;
}

.buttonArrow {
  margin-left: 7px;
  width: 12px;
  height: 12px;
  object-fit: contain;
}

@media (min-width: 1920px) {
  .tagline {
    font-size: 130px;
  }
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .tagline {
    font-size: 95px;
  }
  .regionSelectedBody {
    font-size: 12px;
    margin-top: 10px;
  }
  .regionSelectedButton {
    bottom: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .tagline {
    font-size: 72px;
  }
  .regionSelectedBody {
    font-size: 12px;
    margin-top: 10px;
  }
  .regionSelected {
    padding-bottom: 10px;
  }
  .regionSelectedButton {
    bottom: 15px;
  }
}
@media (max-width: 991px) {
  .regionSelectedBody {
    font-size: 12px;
    margin-top: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .tagline {
    font-size: 64px;
  }
}

@media (max-width: 768px) {
  .regionContainer {
    display: none !important;
  }
  .tagline {
    font-size: 4em;
    height: auto;
    line-height: 1em;
  }
  .taglineContainer {
    bottom: 45vh;
    padding: 20px;
  }
  .regionSelectContainer {
    display: flex !important;
  }
  .emptyRegionContainer
  {
      border-left: none;
  }
}
@media (max-width: 400px) {
  .tagline {
    font-size: 3em;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .arrowIcon {
    /* transform: rotate(-90deg); */
  }
}
