.wrapper {
  z-index: 2 !important;
}

.top {
  position: relative;
  z-index: 10 !important;
}

.tiles {
  z-index: 0 !important;
}

.button > .buttonText {
  transform: translate(0);
  transition: transform 300ms ease-in-out;
}

.button > img {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}

.button:hover > img {
  position: absolute;
  right: 5px;
  left: 88%;
}
.video {
  height: 264px !important;
  width: 470px !important;
}
@media (min-width: 991px) and (max-width:1100px) {
  .video {
    width: 400px !important;
    height: 230px !important;
  }
}
@media (min-width: 767px) and (max-width:991px) {
  .video {
    width: 350px !important;
    height: 197px !important;
  }
}
@media (max-width: 479px) {
  .video {
    width: 400px !important;
    height: 225px !important;
  }
}
@media (max-width: 405px) {
  .video {
    width: 325px !important;
    height: 175px !important;
  }
}
