.button {
  cursor: pointer;
  transition: all 75ms;
  position: relative;
}
.button:hover {
  opacity: 0.88;
  background-color: rgba(0, 0, 0, 0.6);
}
.petvalue {
    width: 35px;
}
.catIcon {
  height: 26px;
  margin-bottom: 5px;
}

.plusSign {
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
}
@media (min-width: 768px) {
  .parkingWrapper {
    width: 50% !important;
  }
  .parkingWrapper > div:first-child {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .parkingWrapper > div:first-child {
    width: 100%;
    max-width: none;
    padding-right: 0px !important;
  }
}

@media (max-width: 767px) and (-ms-high-contrast: none){
  .parkingWrapper > div:first-child {
    flex-basis: auto !important;
  }
}

.disclaimer {
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 12px;
}