.modal {
  position: absolute;
  width: 620px;
  max-width: 95%;
  height: 620px;
  max-height: 75%;
}

.modal:focus {
  outline: none;
}

.modalTitle {
  min-height: 30px;
  padding: 15px 0;
  font-size: 26px;
  font-weight: 600;
  font-family: 'Outfit', sans-serif;
  color: white;
}

.modalTitle,
.modalGalleryTitle {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.modalBody > div {
  height: 85%;
}

.modalBody {
  background-color: white;
  height: 100%;
  flex: 1;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modalClose > svg {
  transform: rotate(45deg) scale(0.8);
}
.modalClose {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: rgba(0, 0, 0, 0.77);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 125ms;
  z-index: 2;
}

.modalClose:hover {
  transform: scale(1.1);
}

.modalClose:focus {
  outline: none;
}

.modalClose:hover {
  opacity: 0.92;
}

.modalGallery {
  width: 100%;
  height: 100%;
  padding: 1px;
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.42);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBody > div {
  flex: 1;
  height: 100%;
}

.modalGallery > iframe {
  min-height: 0px !important;
}

@media (-ms-high-contrast: none) {
  .modal {
    position: static !important;
    width: 620px;
    max-width: 95%;
    height: 620px;
    max-height: 75%;
  }
  .modalGallery > iframe {
    position: absolute !important;
    width: 620px !important;
    height: 100% !important;
    min-height: none;
  }
}
