.lds-dual-ring {
  margin: auto;
  margin-top: 20px;
  width: 64px;
  height: 64px;
  align-self: center;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #008d94;
  border-color: #008d94 transparent #008d94 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.af-class-customTable {
  overflow: scroll;
} 
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
