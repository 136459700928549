.custom-filter-bar-wrapper>span.af-view:last-of-type>.floorplan-card-container {
  padding-bottom: 80px;
}

.custom-filter-bar-wrapper>span.af-view>section.floorplan-card-container div.af-class-floorplan-section-cards-container {
  padding: 0 130px 24px 130px;
}

@media screen and (min-width: 480px) and (max-width: 1200px) {
  .custom-filter-bar-wrapper .af-view .af-class-sticky-floorplan-wrapper .af-class-div-block-177 {
    flex-wrap: wrap;
    justify-content: center;
  }
}


@media screen and (max-width: 1136px) {
  .custom-filter-bar-wrapper>span.af-view:last-of-type>.floorplan-card-container {
    padding-bottom: 0;
  }

  .custom-filter-bar-wrapper>span.af-view>section.floorplan-card-container div.af-class-floorplan-section-cards-container {
    padding: 0 50px 24px 50px;
  }
}

@media screen and (max-width: 930px) {
  .custom-filter-bar-wrapper>span.af-view>section.floorplan-card-container div.af-class-floorplan-section-cards-container {
    padding: 0 10px 24px 10px;
  }
}