.menu {
  background-color: white;
  z-index: 10;
}

.menu:focus {
  box-shadow: inset 0px 0px 0px 3px rgb(77, 144, 254);
  outline: none;
}

.menu,
.apply,
.logo {
  cursor: pointer;
  transition: all 150ms;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.apply:hover,
.logo:hover,
.menu:hover {
  opacity: 0.78;
}
.menu > div {
  font-family: 'Outfit', sans-serif;
}
.fixedNavBar {
  width: 100% !important;
  position: fixed !important;
}
.hasAlertBanner {
  top: 50px !important;
}
.links {
  -webkit-scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 1075px) and (min-width: 992px) {
  .links {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    transform: translateY(100%) !important;
    padding: 20px 20px 20px 10px !important;
    background: #fff !important;
    border-top: 1px solid #d8d8d8 !important;
    border-bottom: 1px solid #d8d8d8 !important;
  }
}

@media (max-width: 767px) {
  .overviewApply {
    display: none !important;
  }
  .hasAlertBanner {
    top: 60px !important;
  }
}
@media (max-width: 480px) {
  .subpageApply {
    display: none !important;
  }
}
@media all and (-ms-high-contrast: none) {
  .links > span {
    flex-basis: auto !important;
    width: auto !important;
  }
}

@media all and (-ms-high-contrast: none) {
  .logo {
    width: 100%;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 850px) {
  .links > span {
    min-width: 175px;
  }
}
