.button {
  /* position: relative; */
}
.button:hover > .buttonText > .buttonArrow {
  transform: translateX(5px);
}
.buttonText {
  transition: transform 300ms ease-in-out;
}
.buttonArrow {
  margin-left: 5px;
  width: 8px;
  height: 13px;
  transition: transform 300ms ease-in-out;
}
.buttonText:hover {
  cursor: pointer;
}
.cardHeader {
  min-height: 56px;
  justify-content: space-between;
}
.infoIcon {
  position: absolute;
  top: 2px;
  right: -2px;
}
.priceLabel {
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  text-align: end;
}
.disclaimerContainer {
  order: 3;
  position: relative;
}
@media (min-width: 768px) {
  .priceLabel:hover + .priceDisclaimer {
    display: block;
  }
  .priceLabel:hover ~ .toolTipArrow {
    display: block !important;
  }
}
.showDisclaimer {
  display: block !important;
}
.priceDisclaimer {
  display: none;
  width: 250px;
  border: 1px solid #787878;
  padding: 15px;
  padding-right: 20px;
  position: absolute;
  right: 0px;
  bottom: 60px;
  z-index: 1;
  background: white;
  bottom: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  color: #333;
  text-align: left;
}
.toolTipArrow {
  display: none;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 5px;
  right: 20px;
  z-index: 3;
  border-right: 1px solid #787878;
  border-top: 1px solid #787878;
  transform: rotate(135deg);
  background: white;
}
.toolTipClose {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 25px;
  transition: all .15s;
  cursor: pointer;
}
