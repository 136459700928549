.modal {
  position: absolute;
  display: flex !important;
  right: initial !important;
  width: 100%;
}
.link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.link > span {
  flex-basis: auto !important;
}
.modal > ul {
  margin: auto;
}
.hasAlertBanner {
  top: 125px !important;
}
@media (max-width: 767px) and (min-width: 479px) {
  .modal {
    padding-top: 10% !important;
  }
}
