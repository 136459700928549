.headerSpacer {
  margin-top: 75px;
  margin-bottom: 10px;
}

@media (max-width: 1075px) {
  .headerSpacer {
    margin-top: 140px;
    margin-bottom: 10px;
  }
}

.image {
  height: 100% !important;
  max-height: none !important;
  object-fit: cover !important;
  object-position: center center !important;
  width: 100%;
}
.container {
  transition: all 500ms;
}
@media only screen and (max-width: 767px) {
  .container {
    height: 375px;
    flex-direction: column;
    margin-bottom: -5% !important;
    max-width: 100%;
    align-items: flex-start !important;
    margin-top: 0px !important;
  }
  .image {
    max-width: 771px;
  }
}
@media only screen and (max-width: 479px) {
  .container {
    height: 375px !important;
    margin-bottom: -50% !important;
  }
}
@media screen and (min-width: 1075px) {
  .wrapper,
  .container {
    height: calc(100vh - 140px) !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1074px) {
  .wrapper,
  .container {
    height: calc(100vh - 210px) !important;
  }
}
.affordablePolicy {
  text-align: center;
  font-family: 'Outfit', sans-serif;
  padding: 40px;
  position: relative;
  color: #707070;
  font-style: italic;
}
.affordablePolicyUnits {
  text-align: center;
  font-family: 'Outfit', sans-serif;
  padding: 90px 40px 10px;
  position: relative;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 700;
}
.policyLink {
  margin-left: 2px;
  font-weight: 700;
  cursor: pointer;
}
.policyLink:hover {
  opacity: 0.88;
}
.policyModal {
  position: absolute;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  border: 1px solid #787878;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  z-index: 2;
  background: white;
  left: 10%;
  right: 10%;
  top: 80px;
  font-style: initial;
}
.policyModal > svg {
  position: absolute;
  top: 5px;
  right: 5px;
}
.hasAlertBanner {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .hasAlertBanner {
    padding-top: 60px;
  }
}
