.grid {
    align-items: flex-start !important;
    justify-content: flex-start !important;
    width: 100%;
    max-width: 1200px;
    flex-wrap: wrap;
}

.grid > span > div {
    width: 100% !important;
}

.grid > span {
    flex: 0 1 33% !important;
    width: auto !important;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 768px ) and (max-width: 937px) {
    .grid > span {
        flex: 0 1 50% !important;
    }
}

@media only screen and (max-width: 767px ) {
    .grid > span {
        flex: 0 1 100% !important;
    }
}
@media only screen and (max-width: 938px) {
    .grid > span:nth-child(1) {
        order: 1
    }
    .grid > span:nth-child(2) {
        order: 2
    }
    .grid > span:nth-child(3) {
        order: 3
    }
    .grid > span:nth-child(4) {
        order: 6
    }
    .grid > span:nth-child(5) {
        order: 5
    }
    .grid > span:nth-child(6) {
        order: 4
    }
    .grid > span:nth-child(7) {
        order: 7
    }
    .grid > span:nth-child(8) {
        order: 8
    }
    .grid > span:nth-child(9) {
        order: 9
    }
    .grid > span:nth-child(10) {
        order: 12
    }
    .grid > span:nth-child(11) {
        order: 11
    }
    .grid > span:nth-child(12) {
        order: 10
    }
    .grid > span:nth-child(13) {
        order: 13
    }
    .grid > span:nth-child(14) {
        order: 14
    }
    .grid > span:nth-child(15) {
        order: 15
    }
}
