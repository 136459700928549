.image {
    background-position: 0 50% !important;
}

.tag {
    z-index: 5 !important;
}

.goldStar {
    z-index: 0 !important;
}
@media (min-width: 480px) {
    .goldStar {
      padding: 20px !important
    }
  }
@media (min-width: 1280px) {
    .goldStar {
        transform: translate(20%, -40%) !important;
    }
}
@media (min-width: 992px) {
    .image {
        max-height: 800px;
        min-height: 800px;
    }
}
