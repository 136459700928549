html {
  scroll-behavior: smooth;
}

.comparisonWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-family: 'Outfit', sans-serif;
  position: relative;
}

.galleriesWrapper {
  display: flex;
}

.propertyGallery {
  margin: 0;
  margin-right: 4.66em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: calc(33vw - 1em - 100px);
  flex: 1 1 0px;
}

.propertyGalleryAddress {
  font-size: 16px;
}

.propertyGalleryPhone {
  font-size: 16px;
  font-weight: 700;
  color: #4c698f;
  margin-bottom: 3em;
}

.imageGallery {
  aspect-ratio: 16 / 9;
}

.propertyTitle {
  text-decoration: underline;
  font-size: 32px;
  margin: 0 0 0.75em 0;
  text-overflow: ellipsis;
  position: relative;
}

.propertyDisclaimer {
  color: red;
  font-size: 12px;
  text-decoration: none;
  position: absolute;
  left: 0;
  bottom: -32px;
  font-weight: normal;
}

.specials {
  position: absolute;
  padding: 0.6em;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  background-color: #06845e;
  color: white;
  z-index: 1;
  border-radius: 50px;
  right: 1em;
  top: 1em;
  cursor: pointer;
}

.specials > span {
  font-family: 'Outfit', sans-serif;
  padding: 0 0.5em 0 0;
}

.specialModal {
  position: absolute;
  bottom: 3em;
  background-color: white;
  color: rgb(63, 63, 63);
  font-size: 16px;
  padding: 1.5em;
  width: 15em;
  border: 1px solid rgb(63, 63, 63);
  border-radius: 10px;
  right: 0;
  display: none;
}

.specialModal::after {
  content: ' ';
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -16px;
  right: 15px;
  background-color: white;
  transform: rotate(45deg);
  border: 1px solid rgb(63, 63, 63);
  border-top: 1px solid white;
  border-left: 1px solid white;
}

.specialExpiration {
  text-align: right;
  font-weight: 700;
}

.specials:hover .specialModal {
  display: block;
}

.sliderContainer {
  position: relative;
}

.comparisonSection {
  padding: 2em 4em;
  overflow: hidden;
  width: calc(100vw - 100px);
}

.comparisonSection a {
  text-decoration: none;
  color: unset;
}

.comparisonSection a:visited {
  text-decoration: none;
  color: unset;
}

.capitalize {
  text-transform: capitalize;
}

.comparisonTitle {
  font-size: 48px;
  margin: 0 0 1em 0;
  line-height: 44px;
}

.comparisonTable {
  display: flex;
}

.slideArrow {
  height: 50px;
  margin: calc(50vh - 25px) 0;
  position: sticky;
  top: calc(50vh - 25px);
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 200ms;
  z-index: 999999;
}

.slideArrow::before {
  content: ' ';
  width: 50px;
  height: 50px;
  background-color: #7ba4da;
}

.slideArrow:active {
  transform: translateY(3px);
}

.incrementArrow::before {
  clip-path: polygon(30% 0%, 80% 50%, 30% 100%, 20% 100%, 70% 50%, 20% 0%);
}

.decrementArrow::before {
  clip-path: polygon(80% 0%, 30% 50%, 80% 100%, 70% 100%, 20% 50%, 70% 0%);
}

.mobileNavBar {
  display: none;
}

.mobileControls {
  display: none;
  color: rgba(123, 193, 197, 1);
  font-size: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-weight: 700;
  border-bottom: 1px solid #c5c5c5;
}

.mobileControl {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mobilePrevArrow::before {
  font-family: 'webflow-icons';
  content: '\E601';
}

.mobileNextArrow::before {
  font-family: 'webflow-icons';
  content: '\E600';
}

.mobileControlName {
  margin: 0 10px;
  text-transform: capitalize;
  color: rgba(104, 104, 104, 1);
  font-size: 12px;
}

.selectApartmentsDisclaimer {
  margin: 2em;
  font-style: italic;
}

.propertyMobileTitle,
.followingTitle {
  display: none;
}

@media only screen and (max-width: 1084px) {
  .propertyGallery {
    min-width: calc((100% - 2em) / 2);
    margin-right: 2em;
  }
}

@media only screen and (max-width: 720px) {
  .comparisonSection {
    overflow: unset;
  }

  .followingTitle {
    position: sticky;
    top: 0;
    width: 100vw;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: row;
    height: 40px;
    z-index: 20;
    gap: 2px;
    background-color: white;
    margin-top: -40px;
    padding: 0;
    border-bottom: 1px solid white;
  }

  .propertyMobileTitle {
    background-color: #00c58a;
    display: block;
    flex-grow: 1;
    flex-basis: 1px;
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
    font-weight: 700;
    background-color: rgb(63, 63, 63);
    color: white;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .propertyGallery {
    min-width: 95vw;
    margin: 0;
    display: none;
  }

  .propertyTitle {
    padding: 0 0.5em;
  }

  .comparisonSection {
    padding: 10px 0 0 0;
    width: 100vw;
  }

  .comparisonTitle {
    font-size: 18px;
    line-height: 20px;
    padding: 0 0.5em;
    text-align: center;
    margin: 20px 0 20px;
  }

  .slideArrow::before {
    content: ' ';
    width: 30px;
    height: 50px;
    background-color: #7ba4da;
  }

  .mobileNavBar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    z-index: 21;
    background-color: white;
    position: relative;
  }

  .mobileNavLink {
    flex: 1 1 0px;
    font-size: 12px;
    line-height: 14px;
    color: #686868;
    margin: 0 14px;
    font-weight: 700;
    text-align: center;
  }

  .mobileControls {
    display: flex;
  }

  #overview .comparisonTable > div:first-of-type > ul li {
    padding-right: 5em !important;
    text-align: left;
  }

  #overview .comparisonTable > div:last-of-type > ul li {
    padding-left: 5em !important;
    text-align: right;
  }
}
