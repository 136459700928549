.af-class-amenities-slider-list-wrap {
  max-width: 900px !important;
}
@media (min-width: 1100px) {
  .af-class-amenities-slider-toolbar {
    bottom: initial !important;
  }
  .af-class-list-amenities-w-gallery {
    min-height: 600px;
  }
}
@media (max-width: 1099px) {
  .af-class-list-amenities-w-gallery {
    width: 100% !important;
  }
  .af-view .af-class-amenity-slider-heading {
    padding-left: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .af-view .af-class-amenities-image-slider-container {
    position: relative;
    left: 0%;
    width: 100%;
    height: 385px;
  }
  .af-view .af-class-property-sub-page-header.af-class-amenities {
    background-image: linear-gradient(8deg, rgba(0, 0, 0, 0.37), transparent);
    background-position: 0px 0px, 0px 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }
  .af-view .af-class-list-amenities-w-gallery.af-class-reversed {
    left: 0%;
  }
  .af-view .af-class-div-block-160 {
    max-width: none;
  }
  .af-view .af-class-section-title {
    margin-bottom: 0px;
  }
  .af-view .af-class-section-tagline {
    margin-top: 0px;
    font-size: 32px;
    line-height: 38px;
  }
  .af-view .af-class-amenities-image-slider-container.af-class-reversed {
    left: 0%;
  }
}
@media (max-width: 425px) {
  .af-view .af-class-amenities-image-slider-container {
    height: 285px;
  }
}

@media (max-width: 1100px) and (-ms-high-contrast: none) {
  .slider.animated {
    max-width: 100vw !important;
  }
}
@media (-ms-high-contrast: none) {
  .af-class-amenities-image-slider-container {
    height: auto !important;
  }
}
.slider-wrapper.axis-horizontal > ul > li {
  height: 100%;
}
.slider-wrapper.axis-horizontal > ul > li > div {
  height: inherit;
}

.slider.animated {
  max-width: 100vw !important;
}
