.af-class-div-block-388 > span {
  flex: auto !important;
  width: auto !important;
  height: auto !important;
}

@media only screen and (max-width: 991px) {
  .af-class-div-block-388 > span {
    width: 100% !important;
  }
  .af-view .af-class-profile-sidebar .af-class-div-block-392 {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 991px) and (-ms-high-contrast: none) {
  .af-view .af-class-profile-sidebar .af-class-div-block-392 {
    height: 220px;
  }
}
