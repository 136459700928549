.furnishedBlockContainer {
    height: 517px;
    width: 100%;
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
}

.imageContainer {
    width: 50%;
}

.imageContainer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.content {
    width: 50%;
    background-color: white;
    padding: 61px 0 105px 55px;
    position: relative;
}

.contentLabel {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 1px;
    color: rgb(110, 110, 110);
}

.contentHeader {
    width: calc(100% - 264px);
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    margin-top: 12px;
    color: #383838;
}

.contentBlurb {
    width: calc(100% - 125px);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #666667;
    margin: 32px 0 0;
}

.requestInfo {
    position: absolute;
    bottom: 23px;
    left: 55px;
    width: 201px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: white;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    text-align: right;
    height: 42px;
    transition: all 300ms ease-in-out;
}

.requestInfo p {
    margin-bottom: 3px;
    transition: all 300ms ease-in-out;
}

.requestInfoLink:hover>.requestInfo>p {
    transform: translateX(-20px);
}

.requestInfo svg {
    margin-right: 10px;
    transition: all 300ms ease-in-out;
}

.requestInfoLink:hover>.requestInfo>svg {
    transform: translate(159px);
}

.priceTag {
    position: absolute;
    top: 30px;
    right: 154px;
    height: 119px;
    width: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 90%, 0 100%);
    color: white;
    padding: 34px 0;
}

.priceTag *, .contentLabel {
    margin: 0;
}

.priceTag h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
}

.priceTag p {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

@media (max-width: 1440px) {
    .furnishedBlockContainer {
        height: 600px;
    }
}

@media (max-width: 1250px) {
    .furnishedBlockContainer {
        height: 660px;
    }
}

@media (max-width: 1185px) {
    .furnishedBlockContainer {
        height: 700px;
    }
}

@media (max-width: 1070px) {
    .furnishedBlockContainer {
        padding: 0;
    }

    .priceTag {
        right: 124px;
    }
}

@media (max-width: 991px) {
    .imageContainer {
        width: 40%;
    }

    .content {
        width: 60%;
    }

    .furnishedBlockContainer {
        height: 550px;
    }
}

@media (max-width: 936px) {
    .furnishedBlockContainer {
        height: 600px;
    }
}

@media (max-width: 904px) {
    .furnishedBlockContainer {
        height: 680px;
    }
}


@media (max-width: 803px) {
    .furnishedBlockContainer {
        height: 750px;
    }
}


@media (max-width: 767px) {
    .furnishedBlockContainer {
        flex-direction: column;
        height: fit-content;
    }

    .imageContainer {
        width: 100%;
    }

    .imageContainer img {
        min-height: 271px;
    }

    .priceTag {
        top: 0;
        right: 8px;
    }

    .content {
        width: 100%;
        height: fit-content;
        padding: 24px 15px 91px 15px;
    }

    .contentHeader {
        width: 100%;
    }

    .contentBlurb {
        width: 100%;
    }

    .requestInfo {
        left: 15px;
        bottom: 17px;
    }
}