.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: transform 300ms ease-in-out;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  transform: translate(140px, -50%);
}

.sectionTagline {
  text-transform: inherit;
}

@media (min-width: 992px){
  .backgroundImage {
    justify-content: flex-start !important;
  }
}
@media (max-width: 768px) {
  .backgroundImage {
    height: 300px !important;
  }
}
@media (max-width: 425px) {
  .backgroundImage {
    height: 200px !important;
  }
}

@media (max-width: 479px){
  .sectionTagline {
    font-size: 24px !important;
    text-align: center;
  }
}
