.af-class-sustainable-why-we-care {
    margin-top: 50px;
}

.af-class-div-fact-details {
    display:flex;
    flex-direction:row;
}

.af-class-div-fact-details-item-report-image-wrapper img {
    height: 100px;
    width: 70px;
    border: 1px solid lightgray;
}

.af-class-div-fact-details-item-report-image {
    height: 123px;
    width: 95px;
}
.af-class-div-fact-details-item-report-version-main {
    border-bottom-width: 5px;

    margin-left: 7px;
}

.af-class-div-fact-details-item-report-version-main a:hover {
    border-bottom: 3px solid yellow;
    margin-bottom: -3px;
}

.af-class-content-with-fact-grid-item-link:hover {
    border-bottom: 3px solid yellow;
    margin-bottom: -3px;
}

.af-class-div-fact-details-item-report-version-archived-wrapper{
    margin-left:7px;
    margin-top: 20px;
}

.af-class-div-fact-details-item-report-version-archived-items-wrapper{
     margin-left:0px !important;
}

.af-class-div-fact-details-item-report-version-archived-label {
    margin-bottom: 10px;
}

.af-class-content-with-fact-grid-item-link {
    color: #3f3f3f;
    margin-right: 10px;
}

.af-class-content-with-fact-grid-item-link:first-child{
    margin-left: 0px;
}

@media (min-height: 779px ) {
    .af-class-benchmark-row.af-class-margin-top-bottom {
        margin-bottom: 0px !important;
    }
    .af-class-benchmark-row.af-class-margin-top-bottom 
    .af-class-div-block-120 {
        padding-bottom: 0px;
    }
    .af-class-div-block-415
    .af-class-div-block-90 {
        padding-bottom: 0px;
    }
}
.af-class-benchmark-leed-numbers-wrapper
.af-class-text-block-25 {
    font-size: 15px !important;
}