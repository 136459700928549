.property-banner {
  margin: auto;
  margin-top: 75px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 25px;
}

.property-banner-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  margin-top: 76px;
  margin-bottom: 0;
  padding: 0 15px;
}

.banner-text-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.tour-modal-link {
  display: inline;
  color: #4c698f;
  cursor: pointer;
}

.banner-text {
  display: inline;
  flex: 0.65 1;
  font-family: 'Outfit', sans-serif;
}

.property-banner-custom .banner-text {
  max-width: fit-content;
  margin-left: 12px;
}

.banner-tour-text {
  display: inline;
}

.banner-spacer {
  width: 15px;
  height: 15px;
}

.tour-text-split {
  display: inline;
}

.banner-headline {
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
  flex: 0.35 1;
}

.property-banner-custom .banner-headline {
  max-width: fit-content;
  margin-right: 12px;
  text-align: left;
}

.property-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  text-align: center;
  margin-bottom: 25px;
  padding: 0 15px;
}

.header.w-property-banner {
  margin-top: 0px;
}

.close-icon.w-property-banner {
  margin-top: 220px;
}

@media (min-width: 1076px) {
  .property-banner {
    margin-top: 90px;
    margin-bottom: 20px;
  }

  .property-banner-custom {
    margin-top: 75px;
    margin-bottom: 0px;
  }

  .banner-text {
    text-align: left;
  }

  .banner-headline {
    text-align: left;
  }

  .breadcrumbs.w-property-banner {
    top: 200px;
  }
}

@media (max-width: 1075px) {
  .property-banner {
    margin-top: 150px;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .property-banner-custom {
    margin-top: 145px;
  }
}

@media (max-width: 1076px) {
  .close-icon.w-property-banner {
    margin-top: 289px;
  }

  .breadcrumbs.w-property-banner {
    top: 270px;
  }
}

@media (max-width: 884px) {
  .property-banner-custom {
    min-height: 100px;
  }

  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
  }
}

@media (max-width: 878px) {
  .close-icon.w-property-banner {
    margin-top: 259px;
  }

  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .breadcrumbs.w-property-banner {
    top: 290px;
  }
}

@media (max-width: 767px) {
  .property-banner {
    margin-top: 150px;
    width: 90%;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .property-banner-custom {
    min-height: 125px;
  }

  .property-banner-custom .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 591px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .property-banner-custom {
    min-height: 150px;
  }

  .close-icon.w-property-banner {
    margin-top: 279px;
  }

  .breadcrumbs.w-property-banner {
    top: 310px;
  }
}

@media (max-width: 479px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .property-banner-custom {
    margin-top: 135px;
    min-height: 180px;
  }
}

@media (max-width: 437px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .property-banner {
    min-height: 150px;
  }

  .close-icon.w-property-banner {
    margin-top: 299px;
  }

  .breadcrumbs.w-property-banner {
    top: 330px;
  }
}

@media (max-width: 400px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .property-banner-custom {
    min-height: 200px;
  }
}

@media (max-width: 365px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .property-banner {
    min-height: 155px;
  }

  .property-banner-custom {
    min-height: 250px;
  }

  .close-icon.w-property-banner {
    margin-top: 319px;
  }

  .breadcrumbs.w-property-banner {
    top: 350px;
  }
}

@media (max-width: 346px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .property-banner {
    min-height: 175px;
  }

  .close-icon.w-property-banner {
    margin-top: 339px;
  }

  .breadcrumbs.w-property-banner {
    top: 370px;
  }
}

@media (max-width: 300px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }

  .property-banner-custom {
    min-height: 275px;
  }
}

@media (max-width: 375px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 912px) {
  .banner-headline {
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-text-container {
    flex-direction: column;
    text-align: center;
  }
}
