.image2 {
  margin-top: 30px !important;
  margin-bottom: 0px !important;
}

.title {
  margin-top: 0;
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  font-size: 36px;
  line-height: 48px;
}
.paragraph strong {
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .title {
    margin-top: 20px !important;
  }
}
