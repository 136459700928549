
.wrapper {
  z-index: 100000 !important;
  display: block !important;
}

.link {
  cursor: pointer !important;
  pointer-events: all !important;
  transition: all 150ms;
}

.link:hover {
  opacity: 0.73;
}

.arrow {
  position: relative;
  bottom: -2px;
  width: 11px;
}

.linktag {
    text-decoration: none !important;
    cursor: pointer;
}

.image {
  max-height: 230px;
  object-fit: cover;
}

.logoPlace {
  width: 300px;
  max-width: 100% !important;
  height: 150px;
  object-fit: cover;
}
.buttonArrow {
  margin-left: 10px;
}
.linktag:hover {
  opacity: 0.8;
}
.noLogo > div:first-child {
  padding-top: 10px;
}
.noWebsite > div:nth-child(2) {
  padding-bottom: 0px;
}
