.af-view .af-class-home-guide-wrap {
  margin-top: 30px !important;
}
.dropdown__control:hover {
  cursor: pointer;
  border: 1px solid #d2d2d2 !important;
  border-color: #d2d2d2 !important;
  box-shadow: none !important;
}
.dropdown__option:focus {
  background-color: white;
}
.dropdown__option:last-child {
  border-bottom: none;
}
@media (max-width: 991px) and (-ms-high-contrast: none) {
  .af-view .af-class-div-block-407 {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .af-class-div-block-412:nth-child(1),
  .af-class-div-block-413 {
    display: none;
  }
}
