.collageWrapper {
  width: 100%;
  height: 100%;
  flex: 1;
  font-size: 15px;
  font-family: 'Outfit', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  color: white;
  margin-top: 2em;
}

.blurbContainer {
  padding: 3em 6em;
  flex-grow: 2;
  background-color: rgb(63, 63, 63);
  flex-basis: 1em;
}

.blurbTitle {
  font-size: 3.2em;
  font-weight: bold;
  margin: 0 0 1em;
  letter-spacing: 1px;
}

.blurbText {
  line-height: 1.5em;
  font-size: 1em;
  margin: 0;
}

.blurbText > p > a {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

.imageContainer {
  flex-grow: 3;
  flex-basis: 1em;
}

@media only screen and (max-width: 720px) {
  .imageContainer {
    display: none;
  }

  .blurbContainer {
    padding: 1em 2em 2em;
  }
}
