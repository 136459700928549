.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapper > span {
  flex: 0 1 auto !important;
}

.container {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
}

.image {
  flex: 1 0 40%;
  height: auto;
  background-image: url('/images/katie-treadway-171141-unsplash.jpg');
  background-position: center center;
  background-size: cover;
}
.hasAlertBanner {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .hasAlertBanner {
    padding-top: 60px;
  }
}
@media (max-width: 730px) {
  .image {
    display: none;
  }
}

.form {
  flex: 1 0 60%;
  padding: 50px;
}

.formRow {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-top: 20px;
}

.forgot {
  color: #008d94;
  font-family: 'Outfit', sans-serif;
  transition: all 125ms;
  cursor: pointer;
}

.forgot:hover {
  opacity: 0.8;
}

.submitButton {
  padding: 12px 40px 17px;
  border-radius: 3px;
  background-color: #008d94;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  transition: all 125ms;
}

.submitButton:hover {
  opacity: 0.8;
}

.cancelButton {
  padding: 9px 25px;
  border-radius: 3px;
  background-color: #eee;
  color: #727272;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paragraph {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  margin-bottom: 15px;
}

.title {
  margin-bottom: 5px !important;
}

.input {
  border-radius: 3px;
  margin-top: 20px;
  padding-top: 21px;
  padding-bottom: 21px;
}

.formContainer {
  min-height: 500px;
  max-width: 500px;
  margin: auto;
}

.error {
  display: block !important;
}

@media (-ms-high-contrast: none) and (min-width: 730px) and (max-width: 1024px) {
  .formContainer {
    margin: 0 10% 0 0;
    padding-right: 10%;
  }
}

.loadingButton {
  background-color: white;
}

.disabledButton {
  background-color: #727272;
  opacity: 0.8;
}
