.bold {
  font-weight: 700;
}

.listItemSmall {
  height: 75px !important;
  position: relative;
}

@media only screen and (max-width: 720px) {
  .listItemSmall {
    height: 65px !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #686868;
    padding: 32px 28px 10px !important;
    align-items: flex-start !important;
  }

  .listItemLabel {
    position: absolute;
    right: -73px;
    top: 10px;
    width: 146px;
    text-align: center;
    z-index: 3;
    font-size: 12px;
    line-height: 14px;
  }

  .mobileRight {
    align-items: flex-end !important;
  }
}
