.af-class-div-block-111 {
  z-index: 1 !important;
}
.af-class-content-video-testimonial-containe {
  width: 100%;
}

@media only screen and (min-width: 425px) and (max-width: 950px) {

  .af-class-content-video-testimonial-container
  .af-class-boss.af-class-yellow {
    width: max-content;
    min-height: 60px;
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .af-class-content-video-testy-header {
    font-size: 24px!important;
  }
}
@media only screen and (max-width: 768ox){
  .af-class-content-video-testimonial-container
  .af-class-paragraph {
    font-size: 12px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 950px) {
  .af-class-content-video-testy-header {
    min-height: 90px!important;
    height: 90px!important;
  }
  .af-view .af-class-content-video-testy-card .af-class-paragraph {
    min-height: 180px;
    height: 180px;
  }
}
@media only screen and (min-width: 951px) {
  .af-view .af-class-content-video-testy-card .af-class-paragraph {
    min-height: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  .af-class-content-video-testimonial-bg-blue-left {
    bottom: 75% !important;
    right: 25% !important;
  }
  .af-class-content-video-testimonial-bg-blue-right {
    top: 50% !important;
    bottom: 25% !important;
    left: 25% !important;
  }
  .af-class-content-video-testimonial-container > .af-class-content-video-testy-column:last-child > span:last-child > .af-class-content-video-testy-card {
    margin-bottom: 0px;
  }
}
