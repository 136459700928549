.button > .buttonText {
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
  }
  
  .button > .buttonArrow {
    transform: translate(10px, -50%);
    transition: transform 300ms ease-in-out;
  }
  
  .button:hover > .buttonText {
    transform: translateX(-10px);
  }
  .button:hover > .buttonArrow {
    transform: translate(135px, -50%);
  }
  