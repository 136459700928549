@media (min-width: 768px) {
  .af-view .af-class-related-area-band-column.regions2 {
    width: 50%;
  }
  .af-view .af-class-related-area-band-column.regions1 {
    width: 100%;
  }
}
@media (max-width: 920px) and (min-width: 768px) {
  .af-view .af-class-related-area-band-label {
    padding: 15px 15px;
    max-width: 100%;
  }
}
@media (-ms-high-contrast:none) {
  .af-class-div-block-27 {
    max-height: 400px;
  }
}
