.carousel .slide > div > img {
  object-fit: cover;
}
.carousel .control-arrow:focus {
  box-shadow: inset 0px 0px 0px 3px rgb(77, 144, 254);
  opacity: 0.88;
}

.af-class-property-header > div:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
  align-self: flex-start;
  height: 100%;
  /* max-height: 100% !important; */
}
.view-all-photos > img {
  display: none;
}

.site-plan-button > svg,
.view-all-photos > svg,
.tour-modal-button > svg {
  margin-left: 10px;
}

.view-site-plan-icon,
.tour-button-cube,
.all-photos-cube {
  transition: all 200ms ease-in-out;
}
.site-plan-button:hover > svg > g > g.view-site-plan-icon,
.tour-modal-button:hover > svg > g > g.tour-button-cube,
.view-all-photos:hover > svg > g > g.all-photos-cube {
  fill: #ffffff;
}

.site-plan-button {
  cursor: pointer;
}
.af-class-property-header > div:first-child > .carousel:nth-child(2) {
  max-width: 260px;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) .control-arrow {
  display: none;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) .thumbs-wrapper {
  margin: 0;
  overflow: hidden;
  padding: 15px;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) ul {
  padding: 0;
  max-height: 470px;
  overflow: hidden auto;
  margin: 0;
  -webkit-transform: none !important;
  transform: none !important;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) ul::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) ul::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) ul li {
  width: 100%;
  margin: 0;
  display: block;

  margin-bottom: 10px;
  position: relative;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) ul li:last-child {
  margin-bottom: 0;
}

.carousel.carousel-slider .control-arrow {
  top: 45%;
  bottom: auto;
  padding: 15px 5px;
  background: white !important;
  opacity: 1;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #090808;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #090808;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) ul li.thumb::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.6);
  transition: 0.15s all ease;
}

.af-class-property-header > div:first-child > .carousel:nth-child(2) ul li.thumb.selected::after {
  background: none;
}

div.close-icon {
  position: absolute;
  left: 0px;
  top: 30px;
  display: inline-block;
  background: hsla(0, 0%, 100%, 0.64);
  padding: 5px 16px;
  color: #222;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
}

.af-class-property-header {
  max-height: 100% !important;
}
.carousel .slider-wrapper {
  height: 100%;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  height: 100%;
  max-height: none !important;
}

.carousel.carousel-slider .control-arrow {
  background: #ccc;
}
.af-class-property-info-card-wrapper.property-details-card-wrapper-mobile {
  display: none;
}
@media (max-width: 767px) {
  .af-class-property-banner-info-wrapper .af-class-property-info-card-wrapper {
    display: none;
  }
  .af-class-property-info-card-wrapper.property-details-card-wrapper-mobile {
    display: flex;
  }
  .af-class-property-info-card-details-mobile {
    flex-direction: column;
    background-color: #fff;
  }
  .mobile-property-card-top-row {
    border-bottom: 1px solid #f6f6f6;
    padding: 5px 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .af-class-property-phase--property-name {
    max-width: 65%;
  }
  .af-class-div-block-157.mobile-specials-stamp {
    position: absolute;
    bottom: 5px;
    right: 5px;
    transform: none;
    padding: 3px 12px 5px 6px !important;
    margin: 0 !important;
  }
  .mobile-property-card-top-row > .af-class-property-phase--property-name {
    font-size: 24px;
    margin: 5px 0 10px;
  }
  .mobile-property-card-middle-row {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #f6f6f6;
    margin-top: 40px;
  }
  .left-column {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px 15px 20px;
    border-right: 1px solid #f6f6f6;
  }
  .mobile-property-card-middle-row .af-class-div-block-130 {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px 15px 20px;
  }
  .mobile-property-card-middle-row .mobile-card-phone > a {
    text-decoration: none;
    color: #3f3f3f;
  }
  .mobile-property-card-top-row .af-class-star-rating-wrapper {
    width: 45%;
  }
  .mobile-property-card-bottom-row {
    padding: 15px;
    display: flex;
    justify-content: space-around;
  }
  .mobile-property-card-bottom-row > a {
    margin-top: 0;
  }
  .property-header-full-carousel {
    margin-top: 31px;
  }
  .property-header-full-carousel .carousel.carousel-slider {
    height: 56.25vw !important;
  }
  .af-view .af-class-property-header {
    flex-direction: column;
  }

  .af-class-property-header > div:first-child {
    min-height: 100%;
  }

  .carousel .slider-wrapper {
    min-height: 100%;
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    height: 375px;
  }
}
.container.w-property-banner {
  margin-top: 0px;
}

@media (max-width: 479px) {
  .mobile-property-card-top-row .af-class-icon--star--active,
  .mobile-property-card-top-row .af-class-icon--star--inactive {
    width: 20px;
    height: 20px;
  }
  .af-view .af-class-property-header-button-container {
    background: none;
  }
  .af-view .af-class-property-info-card-price-info {
    padding: 0px !important;
  }

  .mobile-property-card-middle-row {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .af-view .af-class-property-banner-info-wrapper {
    top: 39%;
  }
  .container.w-property-banner {
    margin-top: 0px !important;
  }
  .property-breadcrumbs.w-property-banner {
    transform: translate(0px, 0px) !important;
  }
}
@media all and (-ms-high-contrast: none) {
  .af-view .af-class-property-card-location-top-row,
  .af-view .af-class-property-card-location-bottom-row {
    flex-basis: auto !important;
  }
  .carousel .slider-wrapper {
    width: 97vw;
  }
}
@media only screen and (max-width: 1075px) {
  .close {
    margin-top: 114px;
  }
  .container.w-property-banner {
    margin-top: 0px;
  }
}

.af-class-property-info-card-wrapper property-subpage-details-card-wrapper-mobile {
  z-index: 1000 !important;
  position: relative !important;
}

.af-class-text-block-1000 {
  font-weight: bold;
  font-family: 'Outfit', sans-serif !important;
  font-size: 14px;
  color: #3f3f3f;
}
