.af-view .af-class-property-subpage-placard-brick.af-class-prop-name,
.af-view .af-class-property-subpage-placard-brick.af-class-prop-address,
.af-class-property-subpage-placard-brick.af-class-prop-details {
  height: 50%;
  justify-content: space-around !important;
  display: flex;
  flex-direction: column;
  padding: 5px 15px 15px !important;
}
.af-class-property-sub-page-header.af-class-amenities {
  min-height: 450px;
}
.af-view .af-class-property-subpage-placard {
  min-width: 425px;
}
.af-class-property-info-card-wrapper.property-subpage-details-card-wrapper-mobile {
  display: none;
}

.af-class-property-info-card-wrapper property-subpage-details-card-wrapper-mobile {
  z-index: 1000 !important;
  position: relative !important;
}

@media (max-width: 991px) {
  .af-view .af-class-property-subpage-placard-brick.af-class-prop-details {
    width: 50% !important;
  }
}
@media (max-width: 479px) {
  .af-view .af-class-property-subpage-placard-brick.af-class-prop-name,
  .af-view .af-class-property-subpage-placard-brick.af-class-prop-address,
  .af-view .af-class-property-subpage-placard-brick.af-class-prop-details {
    display: none;
  }
  .af-class-property-info-card-wrapper.property-subpage-details-card-wrapper-mobile {
    display: flex;
  }
  .property-subpage-details-card-wrapper-mobile .mobile-property-card-top-row {
    flex-direction: column;
    justify-content: space-between;
  }
  .property-subpage-details-card-wrapper-mobile .af-class-property-phase--property-name {
    min-width: 50%;
    max-width: 50%;
    line-height: 24px;
  }
  .property-subpage-details-card-wrapper-mobile .af-class-property-phase--property-name.long-property-name {
    font-size: 20px;
  }
  .af-class-property-phase--property-name {
    max-width: 65%;
  }
  .af-class-div-block-157.mobile-subpage-specials-stamp {
    position: absolute;
    right: 5px;
    bottom: 5px;
    transform: none;
    padding: 3px 12px 5px 6px !important;
    margin: 0 !important;
  }
  .af-view .af-class-property-subpage-placard-brick.af-class-prop-details .af-class-div-block-158 {
    padding-bottom: 5px;
  }
  .af-view .af-class-property-subpage-placard {
    min-width: initial;
  }
  .af-class-property-subpage-placard {
    height: auto !important;
  }
  .af-view .af-class-property-subpage-placard-brick.af-class-prop-name,
  .af-view .af-class-property-subpage-placard-brick.af-class-prop-address {
    padding: 15px 10px !important;
  }
  .af-class-property-subpage-placard-brick.af-class-prop-details {
    padding: 10px !important;
  }
  .af-class-property-sub-page-header.af-class-amenities {
    height: 425px !important;
    max-height: 425px !important;
    min-height: 425px !important;
  }
}

@media (min-width: 280px) and (max-width: 280px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .mobile-property-card-top-row .af-class-star-rating-wrapper {
    width: 52% !important;
  }
}

@media (min-width: 320px) and (max-width: 320px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .af-class-floorplan-link-list {
    margin-top: 45px;
  }
}

@media (min-width: 360px) and (max-width: 360px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .mobile-property-card-top-row .af-class-star-rating-wrapper {
    width: 40% !important;
  }

  .af-class-floorplan-link-list {
    margin-top: 45px;
  }
}

@media (min-width: 375px) and (max-width: 375px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .af-class-floorplan-link-list {
    margin-top: 25px;
  }

  .mobile-property-card-top-row .af-class-star-rating-wrapper {
    width: 38% !important;
  }
}

@media (min-width: 380px) and (max-width: 380px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .af-class-floorplan-link-list {
    margin-top: 50px;
  }
}

@media (min-width: 384px) and (max-width: 384px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .af-class-floorplan-link-list {
    margin-top: 25px;
  }
}

@media (min-width: 411px) and (max-width: 411px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .mobile-property-card-top-row .af-class-star-rating-wrapper {
    width: 35% !important;
  }

  .af-class-floorplan-link-list {
    margin-top: 25px;
  }
}

@media (min-width: 414px) and (max-width: 414px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .af-class-floorplan-link-list {
    margin-top: 25px;
  }

  .mobile-property-card-top-row .af-class-star-rating-wrapper {
    width: 35% !important;
  }
}

@media (min-width: 428px) and (max-width: 428px) {
  .af-class-div-block-182 {
    margin-top: 25px !important;
  }

  .af-class-floorplan-link-list {
    margin-top: 45px;
  }

  .mobile-property-card-top-row .af-class-star-rating-wrapper {
    width: 35% !important;
  }
}

.af-class-text-block-1000 {
  font-weight: bold;
  font-family: 'Outfit', sans-serif !important;
  color: #3f3f3f;
}
