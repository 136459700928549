.link {
  transition: all 200ms;
  border-radius: 3px;
  padding: 3px;
}

.link:hover {
  background-color: rgba(0,0,0,0.03);
}
.configuration,
.pricing {
  font-weight: 400 !important;
}
