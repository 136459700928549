.list-item-4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
}

.location-distance-label {
  padding-right: 10px;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
}
.af-class-unordered-list-7 {
  padding-left: 0px !important;
}

.div-block-248 {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  z-index: -1;
  height: 1px;
  border-bottom: 1px dashed #000;
}

.div-block-247 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  background-color: #fff;
}

.text-block-76 {
  padding-right: 20px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 700;
}
.af-view .af-class-image-40 {
  left: 0%;
}

.af-class-white {
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: #fdfdfd;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.section-tagline {
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  padding-top: 10px;
  padding-right: 0px;
  color: #fff;
  font-size: 48px;
  line-height: 46px;
}
.tagline-location-span {
  display: block;
}
@media (min-width: 768px) {
  .af-class-div-block-244 {
    margin-left: 40px;
  }
  .af-class-image-40 {
    max-width: 120%;
  }
}
@media (max-width: 768px) {
  .section-tagline {
    font-size: 36px;
  }
  .af-view .af-class-text-block-74 {
    margin-left: 0px;
    text-align: center;
  }
}
