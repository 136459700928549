.af-class-blog-card--tag.greyTag {
  background-color: #eaeaea !important;
  font-size: 10px;
  margin-right: 15px;
}
.af-class-blog-card--tag.container {
  display: flex;
  padding-left: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  text-align: center;
}
.related-blog-item-wrapper {
  text-decoration: none;
  color: #333;
  padding-left: 20px;
}

.af-class-blog-card--small.related{
  height: 300px;
  overflow: hidden;
}

.af-class-blog-card--small.related
.af-class-blog-card--tag.container {
  background-color: #ffffff;
  min-width: 200px;
  width: 100%;
  height: 75px;
}
.related-blog-item-wrapper
.af-class-author {
  padding-left: 5px;
}

.related-blog-wrapper{
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.blog-page .af-class-blog-card--publish-info {
  margin-top: 5px;
}

@media (max-width: 479px) {
  .blog-page .af-class-blog-card--publish-info {
    flex-direction: column !important;
    width: auto;
  }
  .blog-page .af-class-blog-card--date {
    text-align: initial;
    border-left: none;
    margin-top: 7px !important;
    padding-top: 5px !important;
    border-top: 1px solid #d2d2d2 !important;
    font-size: 12px !important;
  }
}
