.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.lineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 100%;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin-top: 15px;
}
