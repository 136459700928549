.button {
    cursor: pointer;
    transition: all 200ms;
}

.button:hover {
    opacity: 0.88;
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.25) !important;
}

.image {
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    cursor: pointer;
}

.link {
    text-decoration: none;
    color: white;
}
