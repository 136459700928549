.heading {
  font-family: 'Outfit', sans-serif;
  font-size: 38px;
  line-height: 44px;
}

.paragraph {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 15px;
  /* text-align: justify;
  text-justify: inter-word; */
}
.heading > span > a {
  color: #3f3f3f;
  text-decoration: none;
}

.heading > span > a:hover {
  opacity: 0.6;
}
