.hours {
  white-space: nowrap;
}

.days {
}

.container {
  margin-right: 10px;
  padding-top: 1em;
}
