.af-class-grid-highlight-images .af-class-div-block-141 {
  max-width: 1920px !important;
}

@media (max-width: 479px) {
  .af-view .af-class-grid-highlight-column.af-class-first-col {
    width: 100% !important;
  }
  .af-view .af-class-grid-highlight-image.af-class-helper-text {
    margin-bottom: 30px;
  }
}

.af-class-grid-highlight-images {
  margin-top: 5px !important;
}

.af-class-grid-highlight-intro-headings {
  padding: 30px 50px !important;
}

@media (max-width: 767px) {
  .af-view .af-class-grid-highlight-intro-headings {
    padding: 0 0 30px !important;
  }
}
