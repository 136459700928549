.mobile-region-button-selected {
  background-color: #3E3E3E !important;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 5px !important;
}
.mobile-region-button-selected::after {
  content: "";
  width: 90%;
  height: 1px;
  background-color: white;
  display: block;
  position: absolute;
  bottom: 0;
}

.mobile-region-button-selected > p {
  color: white;
}
.mobile-region-button-selected > img {
  filter: invert(100%);
  transform: rotate(180deg);
  transition: all 300ms ease-in-out;
}
.mobile-region-location-selected {
  display: block;
}
@supports (-webkit-overflow-scrolling: touch) {
  .mobile-region-button-selected > img {
    /* transform: rotate(90deg); */
  }
}
