.af-class-div-block-428 .af-view {
  flex: unset !important;
  width: 30% !important;
  flex-basis: auto!important;;
}
.contact-page-phone-link > a {
  color: #333;
}
@media (max-width: 767px) {
  .af-class-div-block-428 .af-view {
    width: 50% !important;
  }
}
@media (max-width: 479px) {
  .af-class-div-block-428 .af-view {
    width: 100% !important;
  }
}
