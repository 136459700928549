.af-class-spotlight-text-image-content-column.af-class-no-cta {
  display: block !important;

}
.af-view .af-class-spotlight-text-image-content-column.af-class-no-cta {
  padding-left: 6% !important;
}
@media (min-width: 1440px) {
  .af-class-spotlight-text-image-content {
    max-width: 600px !important;
  }
}
@media (max-width: 991px) {
  .af-view .af-class-spotlight-text-image.af-class-no-cta {
    flex-direction: column-reverse !important;
  }
  .af-view .af-class-spotlight-text-image.af-class-no-cta .af-class-div-block-37 {
    min-height: 200px;
    height: auto !important;
  }
  .af-view .af-class-spotlight-text-image-content {
    max-width: none;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .af-view .af-class-spotlight-text-image.af-class-no-cta {
    margin-top: 0px;
  }
}
@media (max-width: 479px) {
  .af-view .af-class-spotlight-text-image-content {
    padding-top: 20px !important;
  }
  .af-view .af-class-spotlight-text-image-border-heading {
    font-size: 32px;
    line-height: 38px;
  }
  .af-view .af-class-spotlight-text-image.af-class-no-cta .af-class-div-block-37 {
    padding-top: 50px !important;
    min-height: initial;
  }
}
@media (-ms-high-contrast: none) {
  .af-class-div-block-37 {
    flex-basis: auto !important;
  }
  .af-class-div-block-55 {
    position: relative !important;
  }
  .af-class-div-block-55 > img {
    height: 200px !important;
  }
}
