.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.description {
  font-size: 10px;
  /* margin-left: 7px; */
}

@media screen and (max-width: 767px) {
  .container {
    flex-direction: column;
  }
  .description {
    margin-left: 0px;
  }
}
