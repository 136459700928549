.af-class-div-block-11 {
  width: 100% !important;
  justify-content: space-between !important;
}
.af-class-content-image-offset-header {
  padding-bottom: 120px !important;
}
.region .af-class-poi_card_tag {
  display: none;
}
.af-class-cards-poi-detail-headers .af-class-section-title {
  color: white;
}