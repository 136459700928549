.container {
  width: 100%;
  height: 65px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
}

.left,
.right {
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
}

.left:hover,
.right:hover {
  opacity: 0.88;
}

.right {
  border-left: 1px solid #eaeaea;
}

.left {
  border-right: 1px solid #eaeaea;
}

.title {
  flex: 1;
  text-align: center;
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #3f3f3f;
}

.iconTitle {
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 8px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #575757;
  text-transform: uppercase;
}

.icon {
  width: 29px;
  height: 23px;
  object-fit: contain;
  margin-top: 2px;
}

.breadcrumbs {
  font-family: 'Outfit', sans-serif;
  font-weight: 300 !important;
  pointer-events: all !important;
  max-height: 40px !important;
  font-size: 12px !important;
  line-height: 24px;
  color: black !important;
  top: 10px !important;
  border-radius: 3px;
  cursor: pointer !important;
  text-transform: capitalize;
  text-align: center;
}

.svg > svg {
  height: 8px;
  margin-right: 5px;
  transition: all 300ms ease-in-out;
}
.breadcrumbs:hover > .svg > svg {
  transform: translate(-2px, 0px);
}
.headerWrapper {
  display: flex;
  flex-direction: column;
}
