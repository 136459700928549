.retail-showcase-logo {
  filter: grayscale(1.0);
}
.retail-showcase-logo:hover {
  filter: grayscale(0);
}
.af-class-p1-retail-item-description.rich-text-item-wrapper > p {
  color: hsla(0, 0%, 100%, 0.61) !important;
  font-weight: 300;
  line-height: 20px;
}
@media only screen and (max-width: 1440px) {
  .af-class-logo-holder {
    padding: 20px !important;
  }
}

@media (-ms-high-contrast: none) and (min-width: 767px) {
    .retail-showcase-logo {
      width: 100%;
  }
}

@media (-ms-high-contrast: none) and (max-width: 778px) {
  .af-class-div-block-347 {
    display: block !important;
    max-width: 100%;
  }
  .retail-showcase-logo {
    max-width: 110px;
  }
  .af-class-showcase-logo {
    max-width: 200px;
  }
  .af-class-logo-holder {
    flex-basis: 100px !important;
  }
  .af-class-div-block-345 {
    min-width: 100% !important;
  }
}
