.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.hasAlertBanner {
    padding-top: 50px;
}
@media (max-width: 767px) {
    .hasAlertBanner {
        padding-top: 60px;
    }
}
@media all and (-ms-high-contrast: none) {
    .container {
        flex-basis: auto;
    }
    .container > span {
        flex-basis: auto !important;
    }
}
