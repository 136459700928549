.button {
  position: relative;
}

.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  position: absolute;
  right: 5px;
  left: 85%;
}
.line1 {
  margin-bottom: 5px;
}

.line2 {
  position: relative;
  top: 15px;
}
.mainImage {
  background-size: cover !important;
}
@media (min-width: 992px) and (max-width: 1100px) {
  .line1 {
    font-size: 56px !important;
  }
  .line2 {
    font-size: 58px !important;
  }
}
@media (max-width: 991px) {
  .textBlurb {
    width: 100%;
    flex: 1;
  }
  .line2 {
    top: -5px;
  }
}
@media (max-width: 479px) {
  .line1, .line2 {
    font-size: 38px !important;
  }
  .line2 {
    top: 0px !important;
    margin-left: 5px !important;
  }
  .textBlurb, .button{
    transform: translateY(255px);
  }
  .mainImage {
    background-size: contain !important;
    min-height: none !important;
  }
}
@media (max-width: 432px) {
  .textBlurb, .button{
    transform: translateY(225px);
  }
}
@media (max-width: 400px) {
  .line1, .line2 {
    font-size: 32px !important;
  }
}
@media (max-width: 365px) {
  .line1, .line2 {
    font-size: 27px !important;
  }
  .textBlurb, .button{
    transform: translateY(200px);
  }
}
