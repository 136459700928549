.emptyState {
  width: 100%;
  background-color: #eaeaea;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 24px;
  line-height: 30px;
  max-width: 720px;
  padding: 30px;
  font-weight: bold;
  text-align: center;
}
.loading {
  display: none;
}

.cardsContainer > span {
  flex: auto !important;
}
.paginationWrapper {
  display: flex;
  border-top: 1px solid #eaeaea;
  width: 100%;
  max-width: 720px;
  margin: auto;
}
.paginationContainer {
  width: 100%;
  margin: auto;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  display: flex;
  list-style-type: none;
  margin-bottom: 24px;
  margin-top: 64px;
  padding: 0px !important;
  justify-content: space-around;
  margin-bottom: 48px;
}
.paginationItem {
  padding: 0px 8px;
  cursor: pointer;
  opacity: 0.8;
}
.paginatedActive {
  opacity: 1;
}

.paginatedActiveLink {
  border-bottom: 3px solid #ecd925;
  font-weight: 700;
}
.paginationPrev {
  margin-right: 30%;
  opacity: 0.8;
  cursor: pointer;
}
.paginationNext {
  margin-left: 30%;
  opacity: 0.8;
  cursor: pointer;
}
.paginationBreak {
  display: none;
}
.prevLabel {
  transform: rotate(180deg);
}
@media (max-width: 608px) {
  .paginationPrev {
    margin-right: 20%;
  }
  .paginationNext {
    margin-left: 20%;
  }
}
@media (max-width: 429px) {
  .paginationPrev {
    margin-right: 10%;
  }
  .paginationNext {
    margin-left: 10%;
  }
}
@media (max-width: 375px) {
  .paginationPrev {
    margin-right: 0;
  }
  .paginationNext {
    margin-left: 0;
  }
}
