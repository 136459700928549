.button {
  cursor: pointer;
  transition: all 100ms;
  min-width: 105px;
}

.button:hover {
  opacity: 0.8;
}

.toggleArrow {
  transition: all 75ms;
}

.closedArrow {
  transform: rotate(0deg) !important;
  top: 0px !important;
}

.details {
  transition: all 125ms;
  display: none !important;
}

.show {
  display: flex !important;
}
