.toggle,
.quote {
  cursor: pointer;
  transition: all 150ms;
}

.toggle:hover,
.quote:hover {
  opacity: 0.88;
}

.toggleIcon {
  transition: all 150ms;
  margin-top: 2px;
}

.detailsOpen {
  transform: rotate(0deg) !important;
}

.row {
  display: flex;
  flex-direction: row;
}
.row > img {
  height: 20px;
  margin-right: 3px;
}
.name {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 700;
}
.labelWrapper,
.moveInlabelWrapper {
  display: flex;
  align-items: center;
}
.moveInlabelWrapper {
  width: 140px;
}
.petsLabelWrapper {
  display: flex;
  align-items: center;
}
.disclaimerContainer {
  display: flex;
  position: relative;
  margin-left: 5px;
}
@media (min-width: 768px) {
  .infoIcon:hover + .disclaimerText {
    display: block;
  }
  .infoIcon:hover ~ .toolTipArrow {
    display: block !important;
  }
}
.showPetsDisclaimer,
.showAvailableDisclaimer,
.showPriceDisclaimer {
  display: block !important;
}
.petsDisclaimerText {
  left: -25px;
}
.availableDisclaimerText {
  left: -225px;
}
.priceDisclaimerText {
  right: 0px;
}
.disclaimerText {
  display: none;
  width: auto;
  border: 1px solid #787878;
  padding: 15px;
  position: absolute;
  z-index: 2;
  background: white;
  /* left: -25px; */
  top: 25px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  width: 400px;
  max-width: 90vw;
  font-size: 14px;
}
.toolTipArrow {
  display: none;
  left: 6px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 20px;
  z-index: 3;
  border-right: 1px solid #787878;
  border-top: 1px solid #787878;
  transform: rotate(-45deg);
  background: white;
}
@media (max-width: 991px) {
  .disclaimerContainer {
    width: 15px;
  }
  .toolTipArrow {
    left: 2px;
  }
}
@media (max-width: 550px) {
  .disclaimerText {
    max-width: 75vw;
    font-size: 12px;
  }
  .name {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .availableDisclaimerText {
    left: -150px;
  }
}
@media (min-width: 480px) {
  .name {
    width: 60px;
  }
  .petsLabelWrapper {
    width: 82px;
  }
}
