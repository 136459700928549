.button {
  position: absolute !important;
  margin-top: 30px !important;
}
.buttonText {
  margin-bottom: 0 !important;
}
.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}
.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  position: absolute;
  right: 30px;
  left: 85%;
}
.button:hover {
  cursor: pointer;
}
.textBlurb {
  text-transform: initial;
  letter-spacing: normal;
  font-size: 17px !important;
}
.imageWrapper {
  top: initial !important;
  bottom: initial !important;
}

@media (max-width: 991px) and (-ms-high-contrast: none) {
  .imageWrapper  {
    bottom: 20px !important;
    top: 20px !important;
  }
}

@media (max-width: 991px) {
  .button {
    display: block !important;
  }
  .imageWrapper {
    left: 0 !important;
  }
}
@media (max-width: 767px) {
  .imageWrapper {
    bottom: -20px !important;
  }
}
@media (max-width: 479px) {
  .textBlurb {
    font-weight: 300 !important;
    font-size: 14px !important;
  }
  .buttonText {
    font-size: 16px !important;
  }
  .imageWrapper {
    bottom: 0px !important;
  }
}
