.moveIn {
  cursor: pointer;
}
.datepicker,
.datepicker > div,
.datepicker > div > div,
.datepicker > div > div > input {
  cursor: pointer !important;
}

.datepicker > div {
  border: none !important;
}

.datepicker,
.datepicker > div,
.datepicker > div > div,
.datepicker > div > div > input:focus {
  outline: none;
}
.datepicker {
  z-index: 99;
}

.apply,
.feesLink {
  cursor: pointer;
  transition: all 150ms;
  white-space: nowrap;
}

.apply:hover,
.feesLink:hover {
  opacity: 0.88;
}

.datepicker > div > div > input,
.apply,
.feesLink {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.filtersDropdown {
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  padding: 6px 15px;
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.filtersDropdown:hover {
  opacity: 0.78;
}
.dropdownContainer {
  width: 225px;
  position: absolute;
  z-index: 10;
  right: 0;
  background-color: white;
  -webkit-box-shadow: -5px 5px 10px 2px rgba(68, 68, 68, 0.26);
  -moz-box-shadow: -5px 5px 10px 2px rgba(68, 68, 68, 0.26);
  box-shadow: -5px 5px 10px 2px rgba(68, 68, 68, 0.26);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
}
.dropdownOpen {
  width: 8px;
  margin-left: 10px;
  margin-right: 5px;
  transform: rotate(90deg);
}
.dropdownClosed {
  width: 8px;
  margin-left: 10px;
  margin-right: 5px;
  transform: rotate(-90deg);
}
@media screen and (max-width: 767px) {
  .moveIn {
    z-index: 10;
  }
  .datepicker {
    padding-bottom: 5px;
    z-index: 1;
  }
  /* .datepicker > div > div > input {
    font-size: 16px;
  } */
  .datepicker > div > div > input > div {
    color: black !important;
    z-index: 1;
  }
  .filtersDropdown {
    margin-left: 0;
    margin-top: 21px;
    height: 36px;
    margin-right: 10px;
  }
  .dropdownContainer {
    left: 0;
    z-index: 101;
  }
}

@media (max-width: 768px) and (max-width: 768px) {
  .dropdownContainer {
    left: 17px !important;
  }
}

@media (max-width: 1024px) and (max-width: 1024px) {
  .dropdownContainer {
    left: 17px !important;
  }
}
