.interactiveWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px;
  margin-top: 0;
  align-items: top;
  background: #fafafa;
  padding-bottom: 10px;
}
.toggleWrapper {
  width: 40%;
  height: 43px;
  background: #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding: 4px;
  cursor: pointer;
  margin-top: 10px;
}
.toggleText {
  border-radius: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 50%;
  height: 35px;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  font-family: 'Outfit', sans-serif;
}

@media (max-width: 767px) {
  .toggleWrapper {
    width: 70%;
  }
}
@media (max-width: 479px) {
  .toggleWrapper {
    width: 90%;
  }
}

@media (min-width: 280px) and (max-width: 319px) {
  .toggleText {
    font-size: 10px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

@media (min-width: 320px) and (max-width: 359px) {
  .toggleText {
    font-size: 12px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

@media (min-width: 360px) and (max-width: 374px) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

@media (min-width: 375px) and (max-width: 383px) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

@media (min-width: 384px) and (max-width: 410px) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

@media (min-width: 411px) and (max-width: 413px) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

@media (min-width: 414px) and (max-width: 414px) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

@media (min-width: 428px) and (max-width: 428px) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

/* iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
/* 2340x1080 pixels at 476ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

/* iPhone 13 Pro Max and iPhone 12 Pro Max */
/* 2778x1284 pixels at 458ppi */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

/* iPhone 13, iPhone 12 and iPhone 12 Pro */
/* 2532x1170 pixels at 460ppi */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}

/* Older iPhones (X, Xs, XR and 11) */
/* 1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .toggleText {
    font-size: 13px;
  }

  .toggleWrapper {
    position: relative;
    z-index: 10;
  }
}
