.af-view .af-class-div-block-358 {
  position: relative;
  width: 100% !important;
  height: 80%;
  background-position: 0px 0px;
  background-size: 125px;
  box-shadow: 0 13px 50px 0 rgba(0, 0, 0, .19);
}

.af-view .af-class-slider-gallery-prev-arrow,
.af-view .af-class-slider-gallery-next-arrow {
  z-index: 15;
  top: 55%;
  cursor: pointer;
}

.af-view .af-class-slider-gallery-prev-arrow:hover,
.af-view .af-class-slider-gallery-prev-arrow:focus,
.af-view .af-class-slider-gallery-next-arrow:hover,
.af-view .af-class-slider-gallery-next-arrow:focus {
  border: 1px solid #000;
}

.af-view .af-class-html-embed-9,
.af-view .af-class-slider-gallery-prev-arrow-icon {
  margin-top: 5px;
}

.af-class-div-block-358>div {
  height: 100%;
}
.slider {
  width: 600px;
  margin: 20px auto;
  text-align: center;
  padding: 20px;
  color: white;
}
/* .slider .slide {
  padding: 40px;
} */
/* .slider .slide .child-element {
  transition: all 0.2s ease;
  background: red;
  width: 100%;
  height: 70px;
} */
.slider .slide.slick-center .child-element {
  background: rebeccapurple;
  -webkit-transform: translate(-70px, 0px);
          transform: translate(-70px, 0px);
  width: calc(100% + 140px);
  max-width: initial;
}
.af-view .af-class-div-block-358{
  width: 100%;
  box-shadow: none;
  background: none;
}
.af-class-div-block-358>div .carousel.carousel-slider {
  height: 100%;
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper {
  height: 100%;
  position: relative;
  padding: 25px 0;
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper::before {
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  z-index: 10;
  background: rgb(2, 0, 36);
  background: linear-gradient(270deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 90%);
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper::after {
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  z-index: 10;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 90%);
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider {
  height: 100%;
  align-items: center;
  display: flex;
}


.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide {
  padding: 15px;
  height: 85%;
  transition: 0.3s all ease;
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide.selected {
  height: 100%;
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide>div {
  height: 100%;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.5);
  position: relative;
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide>div img {
  height: 100%;
  object-fit: cover;
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide>div p {
  position: absolute;
  bottom: -25px;
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  padding: 7px 20px;
  font-size: 18px;
  opacity: 0;
  transition: 0.3s all ease;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide.selected>div p {
  opacity: 1;
}


@media only screen and (max-width:767px) {
  .af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide {
    opacity: 0;
  }

  .af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide.selected {
    opacity: 1;
  }

  .af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide.selected>div {
    /* margin: 0 -50%; */
  }

  .af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper::before {
    width: 0px;
  }

  .af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper::after {
    width: 0px;
  }

  .af-class-div-block-358>div .carousel.carousel-slider .slider-wrapper .slider .slide>div p {
    width: auto;
    font-size: 14px;
    left: 15px;
    right: 15px;
    bottom: 0;
    max-width: 100%;
    transform: none;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 63%, rgba(255, 255, 255, 0.8410714627647934) 100%);
  }
}



.custom-slider .slick-list{
  height: 100% !important;
  padding-top: 15px !important;
  padding-bottom: 35px !important;
}

.custom-slider .slick-list .slick-track{
  height: 100%;
  display: flex;
  align-items: center;
}
.custom-slider .slick-list .slick-track .slick-slide{
  height: 85%;
  transition: 0.2s all ease;
}
.custom-slider .slick-list .slick-track .slick-slide.slick-active{
  height: 100%;
}
.custom-slider .slick-list .slick-track .slick-slide>div{
  height: 100%;
}

.custom-slider .slick-list .slick-track .slick-slide>div .slide-img-wrap{
  height: 100%;
  padding: 15px 30px;
  position: relative;
  outline: none !important;
}

.custom-slider .slick-list .slick-track .slick-slide>div .slide-img-wrap>img{
  height: 100%;
  object-fit: cover;
  transition: 0.3s all ease;
  box-shadow: 0 13px 50px 0 rgba(0, 0, 0, .19);
  width: 100%;
}



.custom-slider .slick-list .slick-track .slick-slide>div .slide-img-wrap p{
  position: absolute;
  bottom: -7px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: rgba(0,0,0,0.9);
  color: #fff;
  font-size: 16px;
  padding: 5px 25px;
}
.af-view .af-class-slider-gallery-prev-arrow {
  width: 75px;
  height: 125px;
}

@media (max-width: 767px){

  .af-view .af-class-slider-gallery-prev-arrow {
    width: 45px;
    height: 70px;
  }
}

@media (max-width: 479px){
  .af-class-slider-gallery {
  margin-top: 50px !important; 
  margin-bottom: 0px !important;
  height: 400px !important;
  }
}

