.link {
  background-image: url('/images/icon-arrow--right--blue.svg');
  background-position: right center;
  background-repeat: no-repeat;
  color: #018094;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
  margin-top: 15px;
  padding-right: 15px;
}
.link:hover {
  opacity: 0.8;
}
.noImage {
  padding-top: 75px !important;
}
.overload {
  margin-top: 50px !important;
  justify-content: flex-start !important;
}
.oddResource {
  justify-content: flex-end !important;
}
@media (min-width: 992px){
  .overload {
    width: 33% !important;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .hanger {
    width: 100% !important;
    justify-content: center !important;
  }
  .title {
    font-size: 32px !important;
  }
}
@media (max-width: 767px) {
  .headerContainer {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .oddResource {
    margin-top: 0px;
  }
}
.listWrapper {
  max-width: initial !important;
}
@media (max-width: 479px) {
  .overload > div:first-child {
    margin-top: 0px !important;
  }
  .overload {
    margin-top: 0 !important;
    margin-bottom: 0px !important;
  }
}
