.link {
  cursor: pointer;
  transition: all 150ms;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.link:hover {
  opacity: 0.65;
}
