.button {
  position: relative;
}

.buttonText {
    color:white;
}

.buttonArrow {
    width: 8px;
}

.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;

}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  position: absolute;
  left: 85%;
}
.subtitle {
  color: white !important;
}
