.af-class-leadership-tab.af-class-active {
  font-weight: 700 !important;
}
.mobile-dropdown__value-container {
  flex: 1 0 100%;
  justify-content: center;
}
@media (max-width: 479px) {
  .af-view .af-class-list-staff {
    padding-top: 0px;
  }
}
.af-class-list-staff {
  padding-bottom: 0 !important;

}
