.propertyStatsWrapper {
  min-width: calc(33vw + 3.66em - 100px);
  flex-grow: 1;
  flex-basis: 1em;
}

.propertyStatsWrapper a {
  color: unset;
  text-decoration: none;
}

.propertyStatsWrapper a:visited {
  color: unset;
  text-decoration: none;
}

.propertySubtitle {
  background-color: rgb(63, 63, 63);
  color: white;
  width: fit-content;
  padding: 0.1em 0.25em;
  font-size: 16px;
  font-weight: bold;
  margin: 1em 0 1em 2em;
}

.propertyStats {
  border-right: 2px solid #c6d8ef;
  list-style: none;
  padding: 0;
  margin: 0;
}

.propertyStats li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1em 5em 1em 2em;
  font-size: 16px;
}

.propertyStats li:nth-child(even) {
  background-color: #ecf2f9;
}

@media only screen and (max-width: 1084px) {
  .propertyStatsWrapper {
    min-width: calc((100% + 2em) / 2);
  }
}

@media only screen and (max-width: 720px) {
  .propertyStatsWrapper {
    min-width: unset;
  }

  .propertyStats li {
    padding: 1em;
    border-top: 1px solid rgba(217, 217, 217, 1);
    padding: 10px 30px;
    color: rgba(104, 104, 104, 1);
  }

  .propertyStats li:last-child {
    border-bottom: 1px solid rgba(217, 217, 217, 1);
  }

  .propertyStatsWrapper a {
    display: none;
  }

  .propertyStats {
    border-right: none;
    width: 50vw;
  }
}
