.button {
  position: relative;
  cursor: pointer;
}
.buttonText {
  color: white;
}

.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}
.buttonArrow {
  width: 8px;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  position: absolute;
  left: 85%;
}
.image {
  max-width: 1440px;
  margin: auto;
}
.extraSpace {
  margin-bottom: 15px !important;
}
.subtitle {
  color: white;
  font-family: 'Outfit', sans-serif;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 479px) {
  .image {
    margin-bottom: 180px;
  }
}
@media (max-width: 375px) {
  .image {
    margin-bottom: 200px;
  }
}
@media (max-width: 360px) {
  .image {
    margin-bottom: 230px;
  }
}
