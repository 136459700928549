propertynavigationmodallinkviewlist0,
propertynavigationmodallinkviewlist11,
propertyNavigationModalCareersLinkViewList14,
propertyNavigationModalAboutLinkViewList9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 10px;
}

propertynavigationmodallinkviewlist0 > span,
propertynavigationmodallinkviewlist11 > span,
propertyNavigationModalCareersLinkViewList14 > span,
propertyNavigationModalAboutLinkViewList9 > span {
  flex: 0 1 33% !important;
}

propertyNavigationModalAboutLinkViewList9 > span > li,
propertyNavigationModalAboutLinkViewList9 > span > li > a,
propertyNavigationModalCareersLinkViewList14 > span > li,
propertyNavigationModalCareersLinkViewList14 > span > li > a,
propertynavigationmodallinkviewlist0 > span > li,
propertynavigationmodallinkviewlist0 > span > li > a,
propertynavigationmodallinkviewlist11 > span > li,
propertynavigationmodallinkviewlist11 > span > li > a {
  width: 100% !important;
  white-space: nowrap;
}

@media (max-width: 991px) {
  propertyNavigationModalCareersLinkViewList14,
  propertyNavigationModalAboutLinkViewList9,
  propertynavigationmodallinkviewlist0,
  propertynavigationmodallinkviewlist11 {
    justify-content: flex-start !important;
  }
  propertyNavigationModalCareersLinkViewList14 > span,
  propertyNavigationModalAboutLinkViewList9 > span,
  propertynavigationmodallinkviewlist0 > span,
  propertynavigationmodallinkviewlist11 > span {
    flex: 0.1 !important;
  }
}

@media (max-width: 767px) {
  propertyNavigationModalCareersLinkViewList14 > span,
  propertyNavigationModalAboutLinkViewList9 > span,
  propertynavigationmodallinkviewlist0 > span,
  propertynavigationmodallinkviewlist11 > span {
    flex: none !important;
  }
}

.af-class-property-navigation > span {
    position: absolute;
    top: 0;
    z-index: -10;
}
