.wrapper {
  padding: 40px;
  padding-top: 0px;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

