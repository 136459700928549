.af-view .af-class-blog-sidebar-column {
  justify-content: flex-start !important;
  -webkit-justify-content: flex-start !important;
}
.af-view .af-class-blog-sidebar-column .af-view {
  width: unset !important;
  flex: 0 0 auto !important;
}
.af-class-blog-post-cards-column .af-view {
  width: unset !important;
  height: unset !important;
  flex: unset !important;
  height: auto !important;
}
.af-class-blog-post-cards-column {
  align-items: stretch!important;
}
.af-class-blog-post-cards-column > span {
  align-items: center!important;
  display: flex;
  justify-content: center;
  flex: 0 1 auto !important;
  height: none !important;
}
@media (max-width: 479px){
  .af-class-social-card--description {
    height: 80px;
    overflow: hidden;
  }
}
@media (max-width: 375px) {
  /* .af-class-social-card--image-container  */
  .af-class-blog-sidebar-section {
    padding-left: 20px !important;
    padding-right: 20px !important;
   }
   .af-class-social-card {
     min-width: 100% !important;
   }
}
