.button > .buttonText {
  /*   transform: translateX(0);
    transition: transform 300ms ease-in-out;*/
}

.button > .buttonArrow {
  /*transform: translate(10px, -50%);
  transition: transform 300ms ease-in-out;*/
}

.button:hover > .buttonText {
  /* transform: translateX(-10px);*/
}
.button:hover > .buttonArrow {
  /*transform: translate(145px, -50%);*/
}

.reports_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Space out the report categories */
}

.reports_columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Space out the report categories */
}

.reports_item_image_container {
  margin-right: 3px !important;
  margin-top: 8px !important;
  flex-shrink: 0;
}

.reports_item_image {
  width: 208px; /* Adjust width as necessary */
  height: 160px; /* Maintain aspect ratio */
}

.reports_items {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 2em;
}

.reports_item_title {
  font-weight: bold;
  /*white-space: nowrap;*/ /* Prevent word wrapping */
}

.reports_item_link {
  color: black; /* Standard link color */
  text-decoration: none; /* No underline */
  margin-bottom: 5px; /* Space between the links */
}

.reports_item_image {
  width: 208px; /* Default width */
  height: 160px; /* Default height to maintain aspect ratio */
}

/* Smaller devices (phones, 600px and down) */
@media (max-width: 600px) {
  .reports_item_image {
    width: 156px; /* Adjusted width */
    height: 120px; /* Adjusted height to maintain aspect ratio */
  }

  .reports_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out the report categories */
  }

  .reports_columns {
    display: flex;
    flex-direction: row;
    justify-content: normal !important; /* Space out the report categories */
  }

  .reports_item_title {
    font-weight: bold;
    white-space: revert;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 601px) and (max-width: 768px) {
  .reports_item_image {
    width: 182px; /* Adjusted width */
    height: 140px; /* Adjusted height to maintain aspect ratio */
  }

  .reports_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out the report categories */
  }

  .reports_columns {
    display: flex;
    flex-direction: row;
    justify-content: normal !important; /* Space out the report categories */
  }

  .reports_item_title {
    font-weight: bold;
    white-space: revert;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 769px) and (max-width: 1548px) {
  .reports_item_image {
    width: 198px; /* Adjusted width */
    height: 152px; /* Adjusted height to maintain aspect ratio */
  }

  .reports_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out the report categories */
  }

  .reports_columns {
    display: flex;
    flex-direction: row;
    justify-content: normal !important; /* Space out the report categories */
  }

  .reports_item_title {
    font-weight: bold;
    white-space: revert;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 993px) {
  .reports_item_image {
    width: 208px; /* Adjusted width */
    height: 160px; /* Adjusted height to maintain aspect ratio */
  }
}

/*.report_category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;*/ /* Align items to the start of the flex container */
/*}

.report_category_title {
  font-weight: bold;
  margin-bottom: 10px;*/ /* Space below the title */
/*}

.report_row {
  display: flex;
  align-items: flex-start;*/ /* Align items to the start of the flex container */
/*}

.report_cover_container {
  margin-right: 10px;*/ /* Space between the image and the list of years */
/*}



.report_years {
  display: flex;
  flex-direction: column;*/ /* Stack the year links on top of each other */
/*}*/

/* Responsive adjustments, if necessary */
/*@media (max-width: 768px) {
  .reports_container {
    flex-direction: column;
  }

  .report_category {
    align-items: center;*/ /* Center the items in mobile view */
/*}

  .report_row {
    flex-direction: column;*/ /* Stack image and years on top of each other in mobile view */
/*}
}*/

.section_tagline {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

@media (max-width: 479px) {
  .title {
    font-size: 32px !important;
  }
  .section_tagline {
    font-size: 20px;
    line-height: 28px;
  }
}
