.text {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 700;
}

@media (max-width: 767px) {
  .leedLogo {
    left: 85% !important;
  }
}

@media (max-width: 375px) {
  .leedLogo {
    max-width: 110px !important;
    top: -8% !important;
  }
}
