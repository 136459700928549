@media only screen and (max-width: 479px) {
    .af-class-cards-news-header {
        max-height: 150px;
    }
}
.af-class-cards-news-container {
    z-index: 2; 
}
.af-class-cards-news-header .af-class-section-title {
    color: white;
}
@media only screen and (max-width: 991px) and (min-width: 767px) {
    .af-class-news_card {
        width:95%!important;
    }
}
@media only screen and (max-width: 767px) and (-ms-high-contrast: none) {
    .af-class-cards-news-container {
        display: block !important;
    }
}
@media (-ms-high-contrast: none) {
    .af-class-cards-news-container
    .af-class-div-block-15 {
        display: block !important;
    }
}