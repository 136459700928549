.mapContainer {
  height: 90vh;
  min-height: 800px;
  width: 100% !important;
  background-image: none !important;
  padding: 0 !important;
  position: relative !important;
}

.subregionMapContainer {
  height: calc(100vh - 80px - 6rem);
  min-height: 600px;
  width: 100% !important;
  background-image: none !important;
  padding: 0 !important;
  position: relative !important;
}

.wrapper {
  background-image: none !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.mapElement {
  height: 100%;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}

.zoomButton {
  pointer-events: all !important;
  cursor: pointer;
}

.zoomButton:hover {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}

.clickableImage {
  position: relative;
  pointer-events: all !important;
  cursor: pointer !important;
  transition: all 150ms;
  object-fit: contain;
}

.propertyPin {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

.clickableImage:hover {
  opacity: 0.88;
  transform: scale(1.2);
  z-index: 10000;
}

.markerContainer {
  position: relative;
  width: 30px;
  height: 30px;
  background: #0d986d;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  /* margin: -20px 0 0 -20px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.markerIcon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 90%;
  height: 90%;
}

.clickableImage:hover > .markerLabel {
  display: block;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
  opacity: 1;
}

.markerLabel {
  position: absolute;
  top: -20px;
  font-size: 13px;
  color: #333333;
  left: -35px;
  text-overflow: ellipsis;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
}

.title {
  position: relative;
}

.subtitle {
  position: absolute;
  bottom: -30px;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px;
  font-size: 20px !important;
}

.breadcrumbs {
  font-family: 'Outfit', sans-serif;
  pointer-events: all !important;
  max-height: 40px !important;
  font-weight: 500 !important;
  line-height: 24px;
  font-size: 14px !important;
  background-color: #3f3f3f !important;
  opacity: 0.9;
  color: white !important;
  top: 10px !important;
  border-radius: 3px;
  cursor: pointer !important;
  text-transform: capitalize;
  box-shadow: 0 0px 7px 0px rgba(0, 0, 0, 0.3) !important;
  transform: none !important;
  width: auto !important;
}

.svg > svg {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  transition: all 300ms ease-in-out;
}

.breadcrumbs:hover > .svg > svg {
  transform: translate(-2px, 0px);
}

.subregionTextBox {
  color: #fff;
  background-color: rgba(63, 63, 63, 0.9);
  border-radius: 3px;
  width: 320px;
  padding: 30px;
  margin-top: 20px;
}

.subregionTextBoxAsHeader {
  color: #fff;
  background-color: rgba(63, 63, 63, 0.9);
  width: 100%;
  padding: 30px;
  margin-top: 0;
  position: relative;
}

@media (max-width: 991px) {
  .wrapper {
    min-height: unset !important;
    height: auto !important;
    max-height: unset !important;
  }
}

@media (max-width: 719px) {
  .wrapper,
  .mapContainer {
    min-height: initial !important;
    max-height: 60vh;
  }

  .wrapper {
    bottom: 0 !important;
    top: initial;
  }

  .subregionTextBox {
    display: none;
  }

  .breadcrumbs {
    top: initial !important;
    left: 10px !important;
    bottom: -50px;
    z-index: 999;
  }
}

.mapInnerContainer {
  position: relative;
}

.propertyIconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.propertyIconContainer:hover > .propertyLabel {
  opacity: 1;
  display: block;
  z-index: 1000000;
}

.propertyTooltipContainer {
  text-transform: capitalize;
  font-weight: 800;
  font-size: 18px;
  color: white;
  background-color: rgba(63, 63, 63, 0.9);
  padding: 10px;
  position: absolute;
  height: 50px;
  right: 46px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* https://caniuse.com/css-has 
 * Forgive me css gods
 * We don't want to spend much time on the old codebase
 * so we are using this hack to make sure the tooltip is
 * offset from the pin properly. 
 * This will be done properly with a tooltip component
 * in the new codebase.
 */
.propertyTooltipContainer:has(> .propertyTooltipText) {
  top: -21px;
  height: 83px;
}

.propertyTooltipText {
  padding: 0;
  margin: 0;
}

.propertyTooltipTail {
  height: 100%;
  width: 30px;
  background-color: rgba(63, 63, 63, 0.9);
  position: absolute;
  right: -30px;
  top: 0;
  bottom: 0;
  clip-path: polygon(0 30%, 100% 50%, 0 70%);
}

.propertyTooltipImage {
  height: 30px;
  width: auto;
  filter: invert(1);
}

.propertyLabel {
  position: absolute;
  display: none;
  top: -25px;
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  left: -25px;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  opacity: 0;
  transition: all 125ms;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
}

@media only screen and (max-width: 720px) {
  .subregionMapContainer {
    height: calc(80vh - 80px - 6rem);
    min-height: 400px;
  }
}
