.table {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 850px;
  margin: -50px auto;
}

/* Write a media query for .table at (max-width: 479px) */
@media (max-width: 479px) {
  .table {
    margin: -5px auto;
  }
}

.row {
  display: flex;
  width: 100%;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.column {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 45px;
  padding: 0 10px;
  border-left: 1px solid #ccc;
}

.cell {
  margin: 5px 0;
}

.row:nth-child(odd) {
  background-color: #f2f2f2;
}

.row:nth-child(1) {
  background-color: #27486f;
  color: #fff;
  text-transform: uppercase;
  border: none;
}

.row:nth-child(1) .column {
  padding: 5px 10px;
}

.column:nth-child(1) {
  border-left: none;
}
