div.gm-svpc,
div.gm-style-mtc {

    display: none !important;
}

div.gmnoprint > div:first-child {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}
div.gmnoprint > div:first-child > button:hover {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.35));
}

div.gmnoprint > div:first-child > button {
    border-radius: 50% !important;
    background-color:white !important;
    margin-bottom: 10px !important;
    top: -120px !important;
    left: -30px !important;
    width: 50px !important;
    height: 50px !important;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}
.af-class-header-property-map > div:not(.af-class-real-estate-column) {
    flex: 1 1 60%;
}
.af-class-real-estate-column {
    min-height: 800px;
}
.subregion-page-map .af-class-heading-2 {
    padding-top: 40px !important;
}
.af-class-real-estate-column {
    flex-basis: 45% !important;
}
@media (min-width: 1081px) and (max-width: 1600px) {
    .subregion-page-map .af-class-map-zoom-controls-wrapper {
        bottom: 70px;
    }
}
@media (min-width: 992px) and (max-width: 1080px) {
    .subregion-page-map .af-class-map-zoom-controls-wrapper {
        bottom: 90px;
    }
}
@media (max-width: 991px) {
    .subregion-page-map .af-class-map-zoom-controls-wrapper {
        bottom: 110px;
    }
    .af-class-real-estate-column {
        flex: 0 0 50% !important;
    }

}
@media (max-width: 740px) {
    .af-class-real-estate-column {
        flex: 0 0 52% !important;
    }
}
@media (max-width: 719px) {
    .af-view .af-class-map-zoom-controls-wrapper {
        margin-bottom: 70px !important;
    }
    .af-class-real-estate-column {
        flex: 0 0 52% !important;
    }
}

@media (-ms-high-contrast: none) {
    .af-class-real-estate-column-container > span {
        flex: 1 1 auto !important;
    }
 .af-class-neighborhood-card {
        display: block !important;
    }
}

.af-class-real-estate-sticky-menu {
    height: auto !important;
}
