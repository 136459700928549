.af-view .af-class-div-block-319 {
  height: 80vh;
  background-image: url("../../assets/images/applyNow.jpg") !important;
}
.af-view .residentportal-block {
  height: 80vh;
  background-image: url("../../assets/images/residentportal.jpg") !important;
}
.dropdown__menu {
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
  overflow: auto !important;
}
@media only screen and (max-width: 425px) {
  .af-class-div-block-320 {
      width: 100% !important;
  }
  .dropdown__control {
      width: 100% !important;
  }
}

.af-class-dropdown .w-dropdown {
  cursor: pointer;
}
