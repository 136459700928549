.af-view .af-class-list-resources-list-container {
    padding-right: 0px;
}
.af-class-list-amenities-w-gallery.af-class-reversed {
    margin-top: 50px !important;
}
.af-class-list-amenities-resources {
    margin-top: 50px !important;
    margin-bottom: 25px !important;
}

@media (max-width: 767px) {
    .af-view .af-class-list-amenities-resources {
        flex-direction: column;
    }
    .af-view .af-class-list-resources-list-container {
        width: 100%;
        padding-left: 30px;
    }
    .af-view .af-class-div-block-166 {
        width: 50%;
        padding: 0 15px;
    }
}
@media (max-width: 479px) {
    .af-view .af-class-div-block-166 {
        width: 100%;
    }
    .af-class-list-amenities-resources {
        margin-bottom: 50px !important;
    }
}
