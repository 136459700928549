a {
    text-decoration: none;
}

.arrow-right-icon{
    margin-left:5px;
    transition: all 500ms;
}

.arrow-right-icon:hover {
    margin-left:10px;
    transition: all 500ms;
}
.af-class-image-63 {
    display: none;
}

