@media (max-width: 991px) {
  .af-view .af-class-career-page-heading {
    max-width: 450px;
  }
}
@media (max-width: 479px) {
  .af-view .af-class-career-page-heading {
    max-width: 280px !important;
  }
  .af-view .af-class-page-header.af-class-career-overview {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .af-view .af-class-page-header-content.af-class-light {
    align-items: baseline;
  }
  .af-view .af-class-page-header.af-class-career-overview {
    margin-bottom: 25px;
  }
}
@media (max-width: 479px) {
  .af-class-page-header.af-class-career-overview {
    height: 400px !important;
  }
}
@media (min-width: 1440px) {
  .af-class-page-header.af-class-career-overview {
    min-height: 900px;
  }
}
.af-class-page-header.af-class-career-overview.careers-overview,
.af-class-page-header.af-class-career-overview.culture,
.af-class-page-header.af-class-career-overview.benefits,
.af-class-page-header.af-class-career-overview.giving-back,
.af-class-page-header.af-class-career-overview.professional-development {
  background-position: center top !important;
}

.topEmployersAward {
  width: 100px;
}

.career-wrapper-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.af-class-career-page-header-btn-offset {
  position: relative !important;
}

@media (min-width: 1600px) {
  .career-right-items {
    /*width: 355px;*/
    margin-top:10px;
    margin-right: 30px;
  }
}

@media (max-width: 570px) {
  .af-class-career-page-heading {
    text-align: left !important;
    font-size: 28px !important;
    line-height: 31px !important;
  }
  .topEmployersAwardSmall {
    margin-right: -20px;
  }
  .career-wrapper-container {
    margin-left: -15px;
  }
}

@media (max-width: 411px) {
  .af-class-career-page-heading {
    text-align: left !important;
    font-size: 25px !important;
    line-height: 31px !important;
    width: 60% !important;
  }
}

@media (max-width: 410px) {
  .af-class-career-page-heading {
    text-align: left !important;
    font-size: 22px !important;
    line-height: 31px !important;
    width: 60% !important;
  }
}

@media (max-width: 320px) {
  .af-class-career-page-heading {
    text-align: left !important;
    font-size: 22px !important;
    line-height: 28px !important;
    width: 70% !important;
  }
}

@media (max-width: 818px) {
  .af-class-page-header-content {
    flex-direction: row !important;
    bottom: 0px;
    align-items: center !important;
    width: inherit !important;
  }
  .careers {
    max-height: 100% !important;
    height: 500px !important;
    padding-bottom: 0px !important;
    margin-bottom: 25px !important;
  }
  .topEmployersAward {
    width: 0px;
    display: none;
  }
  .career-wrapper-container {
    flex-direction: column;
    align-items: start;
  }
}

.topEmployersAwardSmall {
  display: none;
}

@media (max-width: 818px) {
  .topEmployersAwardSmall {
    display: block;
    width: 140px;
  }
}

@media (max-width: 767px) {
  .career-right-items {
    margin-top: -1em;
    margin-bottom: 1em;
  }
}

.career-right-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
