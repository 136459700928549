.sliderWrapper {
  position: relative;
  padding: 0;
  margin: 0;
}

.sliderController {
  position: absolute;
  top: 40%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: all 200ms;
  cursor: pointer;
}

.sliderController:hover {
  transform: scale(1.1);
}

.sliderController:active {
  transform: translateY(5px) scale(1.1);
}

.leftArrow {
  left: 0;
}

.rightArrow {
  right: 0;
}

.slideContainer {
  height: 100%;
  width: 100%;
  margin: 0;
}

.image {
  position: relative;
  height: 100%;
}
