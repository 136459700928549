.af-class-floorplan-policies .af-class-div-block-206 {
    min-height: 470px;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .af-class-div-block-206 {
        width: 100% !important;
    }
}
@media (max-width: 991px) {
    .af-class-floorplan-policies .af-class-policies-tabs {
        margin-top: 0px;
    }
}
@media (max-width: 479px) {
    .af-class-floorplan-policies .af-class-div-block-206 {
        min-height: initial;
    }
    .af-view .af-class-tabbed-content-wrap {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .af-class-floorplan-policies .af-class-policies-tabs {
        margin-top: 0px;
    }
}
@media (max-width: 375px) {
    .af-view .af-class-tabbed-content-wrap {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media all and (-ms-high-contrast: none) {
    .af-class-div-block-206 {
        max-width: none !important;
    }
    .af-class-tab-labels-wrap > span {
        flex-basis: 75px !important;
    }
    .af-class-tab-labels-wrap > span:first-child {
        flex-basis: 120px !important;
    }
}

@media (max-width: 767px) {
    span.af-view > section.af-class-floorplan-policies {
        flex-direction: column-reverse;
    }
}
