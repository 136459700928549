.sightmapContainer {
  width: 100%;
  height: calc(100vh);
  padding: 0 4em 10em;
  margin: 0;
  font-family: 'Outfit', sans-serif;
}

.sightmapTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  color: white;
  background-color: rgb(63, 63, 63);
  padding: 1em;
  margin: 1em 0 2em 0;
}

@media only screen and (max-width: 720px) {
  .sightmapContainer {
    height: 1150px;
    padding: 0 0 3em;
  }

  .sightmapTitle {
    font-size: 18px;
    margin: 0;
  }
}
