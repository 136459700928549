.af-view .af-class-div-block-419
{
    padding-left: 0px;
}

.af-class-div-block-419-padding{
    padding-left: 30px;
}

.leasing-message
{
   color:white;
   padding-left: 30px;

   font-size:18px;
}



propertycontactmapitemviewlist2,
propertycontactmapscheduleitemviewlist0 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
propertycontactmapitemviewlist2 > span:first-child {
  margin-right: 20px !important;
  margin-bottom: 10px;
}
propertycontactmapitemviewlist2 {
  transform: translate(15px, 10px);
}

.af-class-div-block-418 {
  width: 100% !important;
}
.af-class-property-contact-details-wrap {
  padding: 25px !important;
}

div.gm-svpc,
div.gm-style-mtc {
  display: none !important;
}

div.gmnoprint > div:first-child {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
div.gmnoprint > div:first-child > button:hover {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.35));
}

div.gmnoprint > div:first-child > button {
  border-radius: 50% !important;
  background-color: white !important;
  margin-bottom: 10px !important;
  top: -30px !important;
  left: -30px !important;
  width: 50px !important;
  height: 50px !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}
.af-class-div-block-419 .af-class-section-title {
  font-size: 12px !important;
}

propertycontactmapscheduleitemviewlist0 {
  justify-content: space-between !important;
}

@media only screen and (max-width: 425px) {
  .af-class-div-block-420 > propertycontactmapscheduleitemviewlist0 {
    flex-direction: column;
  }
  propertycontactmapscheduleitemviewlist0 > span:nth-child(2) > div {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 425px) and (-ms-high-contrast: none) {
  propertycontactmapscheduleitemviewlist0 > span,
  .af-class-div-block-420 > propertycontactmapscheduleitemviewlist0 {
    flex-basis: auto !important;
  }
  .af-class-div-block-420 > propertycontactmapscheduleitemviewlist0 {
    flex-direction: row;
    display: block !important;
  }
}

@media (min-width: 280px) and (max-width: 280px) {
  .af-class-div-block-418 {
    margin-top: 155px !important;
  }
}

@media (min-width: 320px) and (max-width: 320px) {
  .af-class-div-block-418 {
    margin-top: 155px !important;
  }
}

@media (min-width: 375px) and (max-width: 375px) {
  .af-class-div-block-418 {
    margin-top: 155px !important;
  }
}

@media (min-width: 360px) and (max-width: 360px) {
  .af-class-div-block-418 {
    margin-top: 155px !important;
  }
}

@media (min-width: 384px) and (max-width: 384px) {
  .af-class-div-block-418 {
    margin-top: 155px !important;
  }
}

@media (min-width: 411px) and (max-width: 411px) {
  .af-class-div-block-418 {
    margin-top: 155px !important;
  }
}

@media (min-width: 414px) and (max-width: 414px) {
  .af-class-div-block-418 {
    margin-top: 0px !important;
  }
}

@media (min-width: 375px) and (max-width: 375px) and (min-height: 667px) and (max-height: 667px) {
  .af-class-div-block-418 {
    margin-top: 0px !important;
  }
}

@media (max-width: 1000px) {
  .splitColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .smallContact {
    padding-top: 0 !important;
    margin-top: -1em;
  }
}

.removePadding {
  padding: 0 !important;
}

@media (max-width: 414px) {
  .infoColumn-social {
    margin-left: -15px;
  }
}

.divider {
  display: none;
}

@media (min-width: 1000px) {
  .divider {
    margin-top: 50px;
    margin-bottom: 25px;
    border-left: 1.5px solid #d0d0d0;
    margin-left: 20px;
    padding-right: 20px;
    display: initial;
  }
}

.contact-group {
  padding-top: 1em;
}

@media (min-width: 414px) {
  .splitColumn {
    display: flex;
    flex-direction: row right;
    justify-content: space-between;
  }

  .infoColumn-social {
    margin-left: -15px;
  }

  .tour {
    padding-top: 1em;
  }

  .infoColumn-social {
    margin-top: 0 !important;
  }
}

.note {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  white-space: normal;
  margin-top: 8px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .af-view .af-class-property-contact-details-wrap {
    display: block;
  }
  .af-class-div-block-419 {
      display: flex;
      flex-direction:column;
    padding-top: 125px !important;
  }

    .leasing-message
    {
       padding-top: 10px;
    }
}

@media (min-width: 412px) and (max-width: 479px) {
  .af-class-div-block-419 {
    padding-top: 150px !important;
  }
}

@media (min-width: 411px) and (max-width: 411px) {
  .af-class-div-block-419 {
    padding-top: 0 !important;
  }
}

@media (min-width: 383px) and (max-width: 410px) {
  .af-class-div-block-419 {
    padding-top: 150px !important;
  }
}

@media (min-width: 384px) and (max-width: 384px) {
  .af-class-div-block-419 {
    padding-top: 0 !important;
  }
}

@media (min-width: 361px) and (max-width: 382px) {
  .af-class-div-block-419 {
    padding-top: 150px !important;
  }
}

@media (min-width: 360px) and (max-width: 360px) {
  .af-class-div-block-419 {
    padding-top: 0 !important;
  }
}

@media (min-width: 321px) and (max-width: 359px) {
  .af-class-div-block-419 {
    padding-top: 150px !important;
  }
}

@media (min-width: 320px) and (max-width: 320px) {
  .af-class-div-block-419 {
    padding-top: 0 !important;
  }
}

@media (min-width: 281px) and (max-width: 319px) {
  .af-class-div-block-419 {
    padding-top: 150px !important;
  }
}

@media (min-width: 280px) and (max-width: 280px) {
  .af-class-div-block-419 {
    padding-top: 0 !important;
  }
}

@media (min-width: 200px) and (max-width: 279px) {
  .af-class-div-block-419 {
    padding-top: 150px !important;
  }
}

.infoColumn {
  font-size: 28px !important;
}

.infoItem > propertycontactmapitemviewlist2 > span > div > div {
  font-size: 20px !important;
}

.infoItem > propertycontactmapitemviewlist2 > span > div > svg {
  font-size: 20px !important;
}
