.amenityItem {
  font-size: 16px;
  line-height: 16px;
  margin: 5px 0;
}

@media only screen and (max-width: 720px) {
  .amenityItem {
    margin: 0;
    font-size: 12px;
    line-height: 13.8px;
  }
}
