.outsideWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  min-width: 350px;
}
.hasAlertBanner {
  padding-top: 50px;
}
.bodyContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.bodyContainer > span {
  flex: 0 1 0 !important;
  height: auto !important;
  width: auto !important;
  max-width: 30%;
  min-width: 330px;
}

.quotesContainer {
  padding: 40px;
  padding-right: 0px;
  padding-top: 60px;
}

.quoteDetailWrapper {
  position: relative;
}
.backButton {
  position: absolute;
  top: 20px;
  left: 40px;
}
.backButton:hover {
  cursor: pointer;
}
.backButton > span {
  margin-left: 10px;
  font-family: 'Outfit', sans-serif;
  line-height: 20px;
}
.backButton > img {
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
}
@media (min-width: 1440px) {
  .bodyContainer {
    justify-content: center;
  }
}

@media only screen and (max-width: 997px) {
  .bodyContainer > span {
    flex: 1 0 100% !important;
    max-width: none;
  }
  .bodyContainer {
    flex-direction: column;
  }
  .quotesContainer {
    order: 3;
  }
  .quotesContainer > span > div {
    flex-direction: column !important;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .quotesContainer {
    padding: 60px 20px 0px;
  }
  .hasAlertBanner {
    padding-top: 60px;
  }
}
@media (max-width: 479px) {
  .quotesContainer > span {
    flex-direction: column-reverse !important;
  }
}
@media all and (-ms-high-contrast: none) {
  .outsideWrapper {
    flex-basis: auto !important;
  }
  .bodyContainer {
    padding-top: 100px;
    flex-basis: auto;
  }
}
@media (max-width: 1280px) and (-ms-high-contrast: none) {
  .bodyContainer > span {
    min-width: 250px;
  }
}
@media (max-width: 991px) and (-ms-high-contrast: none) {
  .bodyContainer > span {
    flex: 1 1 auto !important;
  }
}
