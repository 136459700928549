.floorplan-card-container {
    padding: 0 calc((100% - 1136px) / 2);
    background-color: rgba(0, 133, 73, 0.1);
}

.floorplan-card-container .af-class-floorplan-section-heading-wrapper {
    padding: 0;
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 100%;
}

.floorplan-card-container .af-class-floorplan-section-heading-wrapper img {
    width: 50% !important;
    object-fit: cover;
    height: 100%;
}

.floorplan-card-container .af-class-floorplan-section-heading-wrapper .af-class-div-block-190 {
    width: 30%;
    position: absolute;
    left: 50%;
    padding: 0;
}

.floorplan-card-container .af-class-floorplan-section-heading-wrapper>div {
    padding: 32px 0 40px 130px;
    width: 50%;
}

.floorplan-card-container .af-class-floorplan-section-heading-wrapper>div>h2 {
    transform: translate(0);
    position: relative;
    inset: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    margin: 0 0 16px 0;
}

.floorplan-card-container .af-class-floorplan-section-heading-wrapper>div>p {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
 
}

.floorplan-card-container>.af-class-floorplan-section-cards-container {
    background-color: white;
}

.floorplan-card-container>.af-class-floorplan-section-cards-container>.af-class-div-block-192 {
    transform: translate(0);
    padding: 0;
}

.floorplan-card-container>.af-class-floorplan-section-cards-container>.af-class-div-block-192>floorplansfloorplancardviewlist0 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

@media (max-width: 991px) {
    .af-view .af-class-floorplan-section-header {
        text-align: unset;
        transform: translate(0) !important;
    }
}

@media (max-width: 900px) {
    .floorplan-card-container .af-class-floorplan-section-heading-wrapper {
       
    }

    .floorplan-card-container .af-class-floorplan-section-heading-wrapper>div>p {
        font-size: 13px;
    }

    .floorplan-card-container .af-class-floorplan-section-heading-wrapper>div {
        padding: 0 0 0 20px;
        width: 50%;
    }

    .floorplan-card-container .af-class-floorplan-section-heading-wrapper>div>h2 {
        position: relative;
        top: 26px;
        width: fit-content;
        height: fit-content;
        font-size: 28px;
        line-height: 42px;
        z-index: 3 !important;
        padding-bottom: 10px;
    }
}

@media (max-width: 418px) {
    .floorplan-card-container .af-class-floorplan-section-heading-wrapper {
     
    }
}

@media (max-width: 358px) {
    .floorplan-card-container .af-class-floorplan-section-heading-wrapper {
 
    }
}

@media (max-width: 691px) {
    .floorplan-card-container>.af-class-floorplan-section-cards-container {
        padding: 0 !important;
    }
}