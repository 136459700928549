@media (min-width: 767px) 
{
    .af-class-home-intro {
        margin-bottom: 8% !important;
        
    }

    .af-view .af-class-home-intro-content-container
    {
        margin-left: 22.25% !important;
    }

    .af-view .af-class-home-intro-content 
    {
        flex-basis: 53.9% !important;
        padding: 20px 7% 10% 0% !important;
    }
}
@media (max-width: 600px) {
    .af-view .af-class-container.af-class-home-intro {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
    .af-view .af-class-home-intro-image.af-class-left-offset {
        max-width: 49% !important;
    }
    .af-view .af-class-home-intro-image.af-class-right-offset {
        max-width: 49% !important;
    }
    .af-class-home-intro {
        margin-bottom: 100px !important;
    }
    .af-view .af-class-home-intro-content-container {
        max-width: 600px;
        width: 100%;
    }
    .af-view .af-class-home-intro-image.af-class-right-offset {
        transform: translate(104%, 50%) !important;
    }
    .af-view .af-class-home-intro-image.af-class-left-offset {
        transform: translate(0%, 50%) !important;
    }
}
@media (max-width: 479px) {
    .af-view .af-class-home-intro-image.af-class-left-offset,
    .af-view .af-class-home-intro-image.af-class-right-offset {
        transform: translate(0%, 205%) !important;
    }
    br {
        display: none;
    }
    .af-view .af-class-home-intro-content{
        padding-right: 20px !important;
        padding-bottom: 0% !important;
        padding-left: 20px !important;
        width: 100%;
        background: white;
        padding-top: 0px;
    }
    .af-class-home-intro {
        margin-bottom: 280px !important;
    }
    .af-view .af-class-home-intro-content-container {
        height: 100px;
    }
}
@media (max-width: 430px) {
    .af-view .af-class-home-intro-image.af-class-left-offset,
    .af-view .af-class-home-intro-image.af-class-right-offset {
        transform: translate(0%, 170%) !important;
    }
    .af-class-home-intro {
        margin-bottom: 225px !important;
    }
}
@media (max-width: 365px) {
    .af-view .af-class-home-intro-image.af-class-left-offset,
    .af-view .af-class-home-intro-image.af-class-right-offset {
        transform: translate(0%, 190%) !important;
    }
    .af-class-home-intro {
        margin-bottom: 200px !important;
    }
}
@media (max-width: 767px) and (-ms-high-contrast:none) {
    .af-view .af-class-home-intro-content,
    .af-view .af-class-home-intro-images {
        flex-basis: auto !important;
    }
  }

@media (min-width: 768px) and (max-width: 1279px) {
   .af-view .af-class-home-intro-content-container
    {
       margin-left: 28.35% !important;
    } 
   
    .af-view .af-class-container-2
    {
        margin-left: 153px;
    }
}

@media (min-width: 1280px) and (max-width: 1365px)
{
    .af-view .af-class-home-intro-content-container
    {
       margin-left: 28.35% !important;
    } 
   
    .af-view .af-class-container-2
    {
        margin-left: 156px;
    }
}

@media (min-width: 1366px) 
{
    .af-view .af-class-home-intro-content-container
    {
       margin-left: 28.35% !important;
    } 
   
    .af-view .af-class-container-2
    {
        margin-left: 165px;
    }
}

@media (min-width: 1536px) 
{
    .af-view .af-class-home-intro-content-container
    {
       margin-left: 28.35% !important;
    } 
   
    .af-view .af-class-container-2
    {
        margin-left: 145px;
        max-width: 1310px;
    }
}

@media (min-width: 1920px) 
{
    .af-view .af-class-home-intro-content-container
    {
       margin-left: 21.35% !important;
    } 
   
    .af-view .af-class-container-2
    {
        margin-left: 0px;
        max-width: 1443px;
    }
}

@media (min-width: 2122px) 
{
    .af-view .af-class-home-intro-content-container
    {
       margin-left: 5.35% !important;
    } 
   
    .af-view .af-class-container-2
    {
        margin-left: 0px;
        max-width: 1611px;
    }
   
}

@media (min-width: 2560px) 
{
    .af-view .af-class-home-intro-content-container
    {
       margin-left: 5.35% !important;
    } 
   
    .af-view .af-class-container-2
    {
        margin-left: 0px;
        max-width: 1940px;
    }
   
}

@media (min-width: 3840px) 
{
    .af-view .af-class-home-intro-content-container
    {
       margin-left: -10.35% !important;
    } 
   
    .af-view .af-class-container-2
    {
        margin-left: 0px;
        max-width: 2911px;
    }
}

