.collapsibleSection {
  border-bottom: 2px solid #c2ccd3;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: border-color 200ms, margin 200ms;
}

.collapsibleSection[isopen='true'] {
  margin-bottom: 2em;
  border-color: transparent;
}

.collapsibleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  cursor: pointer;
  font-size: 32px;
}

.collapsibleHeader * {
  height: fit-content;
  margin: 0;
  margin-left: 1em;
}

.collapsibleArrow {
  height: fit-content;
  margin-right: 1em;
}

.collapsibleArrow::before {
  font-family: 'webflow-icons' !important;
  font-size: 32px;
  content: '\E603';
  display: inline-block;
  transition: transform 200ms;
  color: #7ba4da;
}

.collapsibleArrow[isopen='true']::before {
  transform: rotateZ(-180deg);
}

.collapsibleContent {
  max-height: 0;
  transition: max-height 200ms;
}

.collapsibleContent[isopen='true'] {
  max-height: 9999px;
}

@media only screen and (max-width: 720px) {
  .collapsibleSection {
    padding: 0;
  }

  .collapsibleSection[isopen='true'] {
    margin-bottom: 0.5em;
  }

  .collapsibleHeader {
    padding: 0.25em;
  }

  .collapsibleHeader h3 {
    font-size: 20px;
    line-height: 23px;
    color: rgba(71, 71, 71, 1);
    font-weight: 700;
  }

  .collapsibleArrow::before {
    color: #a7a7a7;
    font-size: 16px;
  }
}
