.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.icon {
  padding-right: 20px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nearbyItem {
  width: 65px;
  white-space: nowrap;
}

.regionPanelContainer {
  align-items: flex-start !important;
  padding-top: 25px;
}
.scoreWrapper {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.linkWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  cursor: pointer;
}
.link {
  font-size: 18px;
  font-family: 'Outfit', sans-serif;
  line-height: 24px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}
.buttonArrow {
  width: 10px;
  margin-left: 15px;
  transform: translateX(0px);
  transition: all 300ms ease-in-out;
}
.link:hover > .buttonArrow {
  transform: translateX(10px);
  transition: all 300ms ease-in-out;
}
@media (max-width: 479px) {
  .regionPanelContainer {
    padding-top: 0px !important;
  }
}
