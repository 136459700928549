.date {
  margin-right: 5px;
}
.readMore:hover {
  cursor: pointer;
}
.readMore {
  margin-left: 40px;
  flex: 0 0 auto;
}
@media (max-width: 479px) {
  .readMore {
    margin-left: 0px !important;
  }
}
.clickableTitle {
  transition: all 120ms;
}
.clickableTitle:hover {
  cursor: pointer;
  opacity: 0.68;
}
