@media (min-width: 479px) and (max-width: 767px) {
  .af-view .af-class-list-compact-list-item {
    width: unset;
  }
}
@media (max-width: 479px) {
  .af-view .af-class-list-compact-list-item {
    width: 100%;
  }
}
.af-view .af-class-list-compact-list-item {
  line-height: 22px;
}
