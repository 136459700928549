.icon {
  object-fit: contain;
}

.image {
  height: 370px !important;
}

.title {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

@media (max-width: 479px) {
  .image {
    height: 250px !important;
  }
}
@media (max-width: 375px) {
  .image {
    height: 200px !important;
  }
}
