.w-embed {
    display: none;
}
 @media (min-width: 1900px) {
    .af-class-content-intro-descriptive {
        justify-content: center !important;
    }
    .af-class-div-block-36 {
        max-width: 1600px !important;
        padding: 0 35px;
    }
 }
 .af-class-content-intro-descriptive {
     padding: 2% 6% !important;
 }
