.save,
.back {
  transition: all 125ms;
}

.save:hover,
.back:hover {
  opacity: 0.8;
}

.error {
  display: block !important;
  background-color: transparent;
  color: red;
  padding: 0px;
}
.signIn {
  margin-top: 15px;
  color: #3f3f3f;
}

.signIn a {
  margin-left: 5px;
  font-weight: 500;
  color: #008d94;
}
.centerContainer {
  flex-direction: column;
}
.formToggle {
  margin-left: 5px;
  font-weight: 700;
  cursor: pointer;
  color: #008d94;
}
.formToggle:hover,
.floorplansLink:hover {
  opacity: 0.8;
}
.floorplansLink {
  text-decoration: none;
}
.floorplansLink > span {
  font-weight: 500;
}
.selectWrapper {
  margin-top: 20px;
}
@media (max-width: 991px) {
  .centerContainer {
    padding-top: 50px !important;
  }
}

.loading {
  display: none !important;
}
.passwordCriteria {
  font-size: 12px;
  color: #727272;
}
.passwordCriteria > span {
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
}
.passwordCriteria > span:first-child {
  padding-left: 0;
}
.match {
  background-image: url("../../assets/icons/checkmark.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 98% 50%;
}
.noMatch {
  background-image: url("../../assets/icons/close.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 98% 50%;
}

.loader {
  position: absolute;
  top: 50px;
  left: 15px;
}

.formContainer {
  position: relative;
}

@media (max-width: 767px) {
  .loader {
    width: 100%;
    text-align: center;
    left: 0px !important;
  }
}
