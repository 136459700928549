.title {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}
.hasAlertBanner {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .hasAlertBanner {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 425px) {
  .dropdownContainer {
      width: 100% !important;
  }
}
