.title {
  transform: translate(0px, -80%) !important;
}
@media (min-width: 992px) {
  .image {
    max-width: initial !important;
    width: 80% !important;
    right: 95% !important;
    height: 80%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .title {
    transform: translate(0px, -70%) !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .title {
    transform: translate(0px, 10%) !important;
  }
  .image {
    height: 60%;
  }
}
@media (max-width: 767px) {
  .image {
    max-width: initial !important;
  }
  .bodyContainer {
    padding-top: 60% !important;
  }
  .title {
    transform: translate(0px, 0px) !important;
    margin-top: 0px !important;
  }
}
