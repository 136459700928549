.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(12px, -50%);
  transition: all 300ms ease-in-out;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}
.button:hover > .buttonArrow {
  position: absolute;
  right: 5px;
  left: 85%;
}

.buttonText > p {
  margin: 0 !important;
}
.title {
  position: relative;
}
.title::after {
  content: '';
  background-color: #ECD925;
  display: block;
  top: 0;
  left: 0;
  height: 3px;
  right: 0;
  position: absolute;
  max-width: 15%;
}
@media (min-width: 479px) and (max-width: 991px) {
  .title {
    margin-top: 15px !important;
  }
}
