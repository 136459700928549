/* Sustainability sub-headers */

.af-class-div-block-406  > span > span > h3,
.af-class-residents-details-col > h2,
.af-class-div-block-2 .af-class-section-tagline {
    font-size: 24px !important;
}

.af-class-div-block-405 {
    display: none;
}