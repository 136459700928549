.interest {
  cursor: pointer !important;
  flex: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.interest:hover {
  opacity: 0.8;
}

.icon {
  object-fit: contain;
  fill: white !important;
}

.label {
  padding-left: 0px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.iconContainer {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1085px) {
  .interest {
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
}

@media (max-width: 1260px) and (min-width: 922px) {
  .label {
    font-size: 12px;
  }
}

@media (max-width: 922px) and (min-width: 720px) {
  .label {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .iconContainer {
    margin-right: 5px;
  }
}
@media (max-width: 720px) {
  .label {
    font-size: calc(5px + 1.4vw);
  }
}
