.title::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #ecd925;
  top: -15px;
  height: 5px;
  width: 50px;
  margin-left: 10%
}

.image2 {
  min-width: 100%;
  max-height: 80%;
}

@media (max-width: 767px) {
  .image2 {
    min-height: 260px;
  }
  .title {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .mobileImage {
    margin-top: 30px;
    min-height: 300px !important;
    width: 100%
  }
}

@media (min-width: 767px) {
  .mobileImage {
    display: none
  }
}
