.af-view.af-class-section-tagline.af-class-white {
    padding-top: 0 !important;
}
.af-class-property-floorplans-container .af-view .af-class-property-floorplan-card-col {
    padding-left: 0px;
}
.af-class-property-floorplan-card-header .af-class-property-floorplan-header-col {
    max-width: 50%;
}
@media (max-width: 1081px) {
    .af-class-property-floorplan-card-header .af-class-property-floorplan-header-col {
        max-width: 46%;
    }
}
