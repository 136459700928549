.wrapper {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

.map {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  position: relative;
  z-index: 0;
}
@media (max-width: 479px) {
  .map > div:first-child {
    height: 500px !important;
  }
  .locationImage {
    order: 1;
  }
}

.introduction {
  position: absolute;
  top: 0;
  width: 100%;
  background: linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 1% 5%;
}

.introduction > div,
.introduction > h2,
.introduction > p {
  /* width: 60%; */
  left: 10%;
  position: relative;
}

.slogan {
  background-color: transparent;
  color: #727272;
  display: inline-block;
  font-family: 'Outfit', sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 8px;
  position: relative;
  text-transform: uppercase;
}

.slogan::after {
  background-color: #ecd925;
  bottom: 0;
  content: '';
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
}

.title,
.description,
.locationName,
.locationCount,
.locationCommunitiesCount,
.locationCommunity .introduction,
.exploreRegion,
.exploreRegion::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.title {
  color: #3f3f3f;
  font-family: 'Outfit', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.description {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  line-height: 2;
  font-weight: 400;
  max-width: 600px;
  margin-bottom: 0px;
}

.location {
  box-shadow: -20px 0 70px rgba(0, 0, 0, 0.15);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 15%;
  z-index: 1;
}

.locationContentWrapper {
  padding: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.locationInnerContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.locationImage {
  border-bottom: 1px solid #ccc;
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  flex: none;
}

.locationName {
  color: #3f3f3f;
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
  margin-top: 0px;
}

.locationCount {
  color: #3f3f3f;
  font-family: 'Outfit', sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
  margin-top: 0px;
}

.locationCommunitiesCount {
  font-size: 14px;
  margin-bottom: 12px;
  font-family: 'Outfit', sans-serif;
  color: #1b1b1b;
}

.locationCommunities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.locationCommunity {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  font-size: 13px;
  margin-bottom: 5px;
  font-family: 'Outfit', sans-serif;
  color: #797979;
}

.exploreRegion {
  font-weight: 800;
  padding: 12px 8px;
  width: 300px;
  position: relative;
  z-index: 2;
  text-decoration: none;
  color: #3f3f3f;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
}

.exploreArrow {
  position: absolute;
  top: 15px;
  font-size: 20px;
  left: 10px;
  font-weight: 200;
  transition: all 300ms;
}
.exploreRegion:hover > .exploreArrow {
  position: absolute;
  left: 140px;
}

.exploreLabel {
  top: 17px;
  position: absolute;
  left: 25px;
  transition: all 150ms;
}
.exploreRegion:hover {
  opacity: 0.8;
}

.exploreRegion::after {
  background-color: #ecd925;
  content: ' ';
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  height: 46px;
  left: 0px;
  top: 4px;
  position: absolute;
  width: 50%;
  z-index: -1;
  transition: all 150ms;
}
@media (max-width: 1100px) {
  .introduction > div,
  .introduction > h2,
  .introduction > p {
    left: 0%;
  }
}
@media (max-width: 773px) {
  .wrapper {
    flex-direction: column;
  }
  .introduction {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media (max-width: 992px) {
  .locationContentWrapper {
    padding: 30px;
  }
  .description,
  .slogan,
  .title {
    left: unset !important;
  }
  .description {
    width: 100%;
  }
}

.marker {
  width: 26px !important;
  height: 26px !important;
}

.marker:focus {
  padding: 18px;
  left: -6px;
  top: -6px;
  position: absolute;
}

.disclaimer {
  margin-top: 20px;
  color: #707070;
  font-family: 'Outfit', sans-serif;
  font-size: 13px;
  font-style: italic;
}
