.wrapper {
  transition: all 100ms;
}

.wrapper:focus,
.wrapper:hover {
  background-color: #333;
}

.hoveredTitle {
  color: #ecd825 !important;
}

.blurb {
  color: white;
  font-size: 16px;
  text-transform: none !important;
  font-weight: 400;
  max-width: 90%;
  margin: auto;
  margin-top: 15px;
  width: 100%;
  flex: 1;
  word-wrap: break-word;
}
@media (max-width: 991px) {
  .blurb {
    font-size: 12px;
  }
}
@media (max-width: 479px) {
  .wrapper {
    justify-content: normal !important;
  }
  .wrapper > img {
    margin: auto;
  }
  .hoveredTitle {
    padding-top: 0px !important;
    width: 100% !important;
    text-align: center !important;
  }
  .wrapper:focus div {
    padding-top: 0px !important;
    width: 100% !important;
    text-align: center !important;
  }
  .blurb {
    font-size: 15px;
  }
}
@media all and (-ms-high-contrast: none) {
  .blurb {
    max-width: 60%;
    width: 100%;
  }
}

.invisible {
  display: none;
}


.wrapper:focus img {
  display: none;
}

.wrapper:focus div {
  color: #ecd825 !important;
}
.wrapper:focus div p {
  display: block;
}