.carousel .slide,
.carousel-slider,
.slider-wrapper,
.slider {
  height: 100% !important;
}

.control-dots {
  bottom: 20px !important;
  padding: 0 !important;
}
.dot {
  transform: scale(1.4);
}

.dot.selected {
  transform: scale(1.7);
  transition: all 200ms;
}

.dot:focus {
  outline: none !important;
}
