.button:hover > .buttonArrow,
.button:hover > .buttonText {
  filter: invert(100%);
}
@media (max-width: 991px) {
  .button {
    background-color: white !important;
    padding: 20px !important;
    width: auto !important;
  }
  .button > .buttonArrow {
    filter: invert(100%);
  }
  .button:hover > .buttonArrow {
    filter: invert(50%);
  }
}
@media all and (-ms-high-contrast: none) {
  .button:hover > .buttonArrow,
  .button:hover > .buttonText {
    opacity: 0.5;
  }
}
.button:hover {
  cursor: pointer;
}
@media (max-width: 767px) {
  .image {
    padding-bottom: 0px !important;
  }
  .text {
    font-size: 16px !important;
  }
}
@media (max-width: 479px) {
  .title {
    font-size: 28px !important;
    line-height: 32px !important;
  }
}
