.lds-dual-ring {
  margin: auto;
  width: 36px;
  height: 36px;
  align-self: center;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  margin: 1px;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
}

.spinner-white:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  margin: 1px;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite !important;
  border: 5px solid white;
  border-color: white transparent white transparent !important;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
