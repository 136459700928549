.af-class-quote-summary .af-class-floorplan-card-col.af-class-padded-small.af-class-no-border {
  flex: 1 1 auto !important;
}
.af-class-quote-summary .af-class-card-details-row > div {
  flex-basis: auto !important;
}
@media (max-width: 991px) {
  .af-class-quote-summary-unti-col .af-class-community-placard.af-class-margin-bottom {
    padding-right: 0px !important;
  }
  .af-class-quote-summary-details-col .af-class-div-block-397 {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .af-class-quote-summary-unti-col .af-class-community-placard.af-class-margin-bottom {
    flex-direction: row;
    max-width: 450px;
  }
}

@media (max-width: 767px) {
  .af-class-quote-summary-unti-col .af-class-community-placard.af-class-margin-bottom > div:last-child{
    flex: 1 1 auto !important;
  }
  .af-class-quote-summary-details-col .af-class-div-block-397 {
    align-items: flex-start;
  }
  .af-view .af-class-floorplan-card-profile.af-class-quote-summary {
    max-width: none;
  }
}
@media (max-width: 479px) {
  .af-view .af-class-floorplan-card-col.af-class-padded-small {
    width: 25%
  }
  .af-class-quote-summary .af-class-card-details-row > div {
    flex: 1 1 auto !important;
  }
}

@media (max-width: 767px) and (-ms-high-contrast: none) {
  .af-class-quote-summary-details-col .af-class-div-block-397 > ul {
    max-width: 100%;
  }
}
@media (max-width: 479px) and (-ms-high-contrast: none) {
  .af-class-quote-summary-details-col .af-class-div-block-397 > ul {
    max-width: 100%;
  }
}
.af-class-floorplan-card-image-col.af-class-profile.image-print-summary {
  padding: 0px;
}
.af-view .af-class-div-block-272.top-row-print-summary {
  border-bottom: none;
}
.af-view .af-class-card-details-row.row-print-summary {
  border-top: none;
  margin-bottom: 10px;
}
