.floorplanRowContainer {
  display: flex;
  max-width: 1600px;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 70px;
}

.gridRowContainer {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.gridRowContainer > span {
  flex: auto !important;
}

.af-class-the-grid-wrapper,
.af-class-sticky-card-column {
  width: 100% !important;
}

.floorplanBodyContainer {
  position: relative;
}

.floorplanRowContainer > span.af-view:first {
  flex: 0 0 40% !important;
}
.floorplanRowContainer > span.af-view:last-child {
  flex: 0 0 60% !important;
}

.floorplanRowContainer > span > div {
  width: 100% !important;
}

.gridStickyContainer {
  width: 40% !important;
}

.gridGridContainer {
  width: 60% !important;
}
.buttonWrapper {
  margin-top: 20px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-around;
}
.buttonWrapper > a {
  width: 40%;
  padding: 10px;
  color: white;
  text-decoration: none;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  border-radius: 3px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
}
.proceedButton {
  background: green;
}
.viewQuotesButton {
  background: grey;
}
@media (min-width: 992px) {
  .floorplanBodyContainer {
    max-width: 60%;
    width: 60%;
  }
}
@media (max-width: 1075px) and (min-width: 992px) {
  .gridRowContainer,
  .floorplanRowContainer {
    margin-top: 140px !important;
  }
}
@media (max-width: 991px) {
  .floorplanRowContainer {
    margin-top: 85px;
  }

  .gridRowContainer,
  .floorplanRowContainer {
    flex-direction: column;
  }

  .gridStickyContainer,
  .gridGridContainer {
    width: 100% !important;
  }

  .af-class-sticky-card-column {
    height: auto !important;
  }

  .gridRowContainer > span,
  .floorplanRowContainer > span.af-view:first {
    flex: 0 0 100% !important;
  }

  .floorplanRowContainer > span.af-view:last-child {
    flex: 0 0 100% !important;
  }
}

.lds-dual-ring {
  width: 64px !important;
  height: 64px;
  margin: 20px auto auto;
  align-self: center;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #008d94;
  border-color: #008d94 transparent #008d94 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.floorplan-grid__overlay-close {
  display: none;
}
@media (max-width: 767px) {
  .af-view .af-class-div-block-235 {
    width: 100%;
  }
}
@media all and (-ms-high-contrast: none) {
  floorplanBodyContainer {
    width: 100% !important;
  }
  .floorplanRowContainer > span.af-view {
    flex-basis: 40% !important;
  }
  .floorplanBodyContainer {
    flex-basis: auto !important;
  }
  .floorplanBodyContainer > span.af-view {
    flex-basis: auto !important;
  }
  .af-class-floorplann-unit-row.af-class-selected > span.af-view {
    flex-basis: auto !important;
  }
}
@media (max-width: 991px) and (-ms-high-contrast: none) {
  .floorplanRowContainer > span.af-view {
    flex-basis: auto !important;
  }
}

@media (max-width: 767px) {
  .gridRowContainer > span {
    padding-bottom: 30px;
  }
  .gridRowContainer {
    margin-top: 140px;
  }
}
