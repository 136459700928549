.af-view .af-class-footer-top {
  margin-bottom: 2% !important;
  padding-bottom: 2% !important;
}
.af-class-wrapper.af-class-footer {
  padding-top: 3% !important;
  padding-bottom: 3% !important;
}
.af-view .af-class-container.af-class-footer.af-class-flex {
  padding-top: 0 !important;
}

.af-class-div-block-4 {
  flex-direction: column !important;
  align-items: flex-start !important;
}

.af-class-footer-legal {
  font-size: 12pt;
  color: white;
  padding-top: 1em;
  font-weight: bold;
}
