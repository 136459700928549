.card:hover > div:last-child > div:last-child .buttonArrow {
  transform: translateX(5px);
}
.buttonText {
  transition: transform 300ms ease-in-out;
}
.buttonArrow {
  margin-left: 5px;
  width: 8px;
  height: 13px;
  transition: transform 300ms ease-in-out;
}
.specialCardWrapper {
  flex-basis: 0% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media (min-width: 1024px) {
  .twoOrLessCards {
    width: 80% !important;
  }
}
