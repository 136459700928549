.af-class-home--about {
    margin-top: 12px !important;
    margin-bottom: 20px;
}


@media (max-width: 479px) and (-ms-high-contrast:none) {
    .af-view .af-class-div-block-2,
    .af-view .af-class-two-thirds-column.af-class-home--about-img-bg {
        flex-basis: auto !important;
    }
}
.other-page-wrapper {
    max-width: 1440px !important;
}
.sustainability-page-wrapper
.af-class-div-block-2 {
    align-items: normal !important;
}
@media (max-width: 767px) {
    .other-page-wrapper {
      flex-direction: column;
    }
    .other-page-wrapper > .af-class-div-block-2 {
        padding: 30px;
    }
    .other-page-wrapper > .af-class-div-block-2 > .af-class-inner-content-container {
        max-width: none;
    }
  }
@media (max-width: 767px) and (-ms-high-contrast:none) {
    .other-page-wrapper > .af-class-two-thirds-column.af-class-home--about-img-bg,
    .other-page-wrapper > .af-class-div-block-2 {
        flex-basis: auto;
    }
}

@media (min-width: 2122px) 
{
    .af-class-home--about
    {
        margin-top: 230px !important;
    }
}


@media (min-width: 2560px) 
{
    .af-class-home--about
    {
        margin-top: 245px !important;
    }
}
