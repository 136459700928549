.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    align-self: center;
    margin: auto;
}

.container > span {
    width: auto !important;
    min-width: 300px;
    flex: none !important;
}
@media (max-width: 479px) {
    .container > span {
        width: 100% !important;
    }
  }
