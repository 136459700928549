.floorplans-footer-cta-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  max-width: 1400px;
  margin: 50px auto;
}
.floorplans-footer-cta-background-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-position: center center;
}
.floorplans-footer-cta-title {
  width: 80%;
  margin: 100px auto 0px;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 82px;
  text-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

.floorplans-footer-cta-subtitle {
  width: 40%;
  margin: 30px auto 0;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
}
.floorplans-footer-cta-button {
  display: flex;
  flex-direction: row;
  margin: 30px 0 0 0;
  padding: 12px 10px;
  background-color: #ecd925;
  font-weight: 800;
  width: auto;
  font-size: 14px;
  transition: all 0.11s;
  color: #000;
}
.floorplans-footer-cta-button-text {
  display: flex;
  color: white;
  transition: all 300ms ease-in-out;
}
.floorplans-footer-cta-button-arrow {
  transition: all 300ms ease-in-out;
  padding-right: 10px;
  height: 12px;
  margin-top: 4px;
}
.floorplans-footer-cta-button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.floorplans-footer-cta-button:hover .floorplans-footer-cta-button-arrow {
  transform: translate(120px);
  transition: all 300ms ease-in-out;
}

.floorplans-footer-cta-button:hover .floorplans-footer-cta-button-text {
  transform: translate(-10px);
  transition: all 300ms ease-in-out;
}
@media (max-width: 1024px) {
  .floorplans-footer-cta-title {
    font-size: 28px;
    line-height: 40px;
  }
  .floorplans-footer-cta-subtitle {
    margin-top: 30px;
  }
}
@media (max-width: 479px) {
  .floorplans-footer-cta-title {
    font-size: 20px;
    width: 100%;
  }
  .floorplans-footer-cta-subtitle {
    font-size: 14px;
    width: 80%;
    margin-top: 10px;
  }
}
@media (-ms-high-contrast: none) {
  .floorplans-footer-cta-button:hover .floorplans-footer-cta-button-text {
    transform: translate(-10px);
    transition: all 300ms ease-in-out;
  }
  .floorplans-footer-cta-button:hover .floorplans-footer-cta-button-arrow {
    transform: translate(0px);
    transition: all 300ms ease-in-out;
  }
}
