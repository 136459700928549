.image,
.imageDevelopment {
  width: 450px;
  height: 600px;
  object-fit: cover;
  margin-left: -2vw;
  padding-right: 20px;
}


.sliderWrapper,
.sliderWrapperDevelopment {
  max-width: 50vw;
  flex: 1;
}

.button {
  background-color: #fff;
}

.button:hover {
  opacity: 0.8;
}

@media only screen and (min-width: 768px) {
  .sliderWrapperDevelopment {
    height: unset;
    max-height: 30vw;
    align-self: center
  }
  .imageDevelopment {
    height: unset;
    max-height: 30vw;
  }
}

@media only screen and (max-width: 767px) {
  .sliderWrapper,
  .sliderWrapperDevelopment {
    max-width: 100vw;
    max-height: 50vw;
  }
  .image,
  .imageDevelopment  {
    height: 600px !important;
    max-height: 50vw;
    margin-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .image,
  .imageDevelopment  {
    width: 420px;
    height: 400px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 481px) {
  .image,
  .imageDevelopment {
    width: 480px;
    height: 465px;
  }
}

@media only screen and (min-width: 992px) {
  .image,
  .imageDevelopment {
    width: 430px;
    height: 625px;
  }
}

.button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 20px;
}

.leftArrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}


.dot {
  height: 10px;
  width: 10px;
  background-color: #d2d2d2;
  border-radius: 50%;
  margin-left: 10px;
  transition: all 125ms;
  cursor: pointer !important;
}

.selectedDot {
  transform: scale(1.2);
  background-color: #3f3f3f;
}

.subtitle {
  padding-bottom: 10px;
}

.subtitle > span {
  border-bottom: 3px solid #ecd925;;
  padding-bottom: 5px;
}

@media all and (-ms-high-contrast:none){
  img.image {
    box-shadow: 0 0 0 1px !important;
  }
  img.image[data-object-fit="contain"] {
    object-fit: contain !important;
  }
  img.image[data-object-fit="cover"] {
    object-fit: cover !important;
  }
  .sliderWrapper,
  .sliderWrapperDevelopment {
    max-width: 60vw;
  }
}
