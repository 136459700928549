.sliderContainer {
  font-size: 16px;
  padding: 2em 4em 4em;
  position: relative;
  overflow-x: hidden;
  font-family: 'Outfit', sans-serif;
}

.sliderContainer a {
  text-decoration: none;
  color: unset;
}

.sliderContainer a:visited {
  text-decoration: none;
  color: unset;
}

.sliderTitle {
  font-size: 3em;
  position: relative;
  margin: 1em 0.5em;
}

.sliderTitleSubtext {
  font-size: 0.625rem;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: -24px;
}

.sliderContent {
  display: flex;
  flex-direction: row;
  transition: all 200ms;
}

.slide {
  position: relative;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  height: 40em;
  margin-right: 8em;
}

.slideBackground {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 0;
  min-height: 100%;
  aspect-ratio: 16 / 9;
}

.slideBlurbContainer {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  z-index: 2;
  height: 100%;
  position: absolute;
  right: 0;
  width: 45%;
  min-width: 600px;
  padding: 4em;
}

.slideBlurbTitle {
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 2px solid #ecd925;
  width: fit-content;
  margin-bottom: 3rem;
}

.slideBlurb {
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 800;
}

.slideBlurb > p > a {
  text-decoration: underline;
  font-weight: bold;
}

.slideChange {
  height: 4em;
  width: 3em;
  position: absolute;
  cursor: pointer;
  transition: all 200ms;
  z-index: 3;
  top: calc(50% - 2em);
}

.nextSlide {
  right: 1em;
}

.prevSlide {
  left: 1em;
}

.slideChange:hover {
  transform: scale(1.2);
}

.slideArrow {
  background-color: white;
  height: 100%;
  width: 100%;
}

.nextSlideArrow {
  clip-path: polygon(30% 0%, 80% 50%, 30% 100%, 20% 100%, 70% 50%, 20% 0%);
}

.prevSlideArrow {
  clip-path: polygon(70% 0%, 20% 50%, 70% 100%, 80% 100%, 30% 50%, 80% 0%);
}

.sliderIndicatorContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 6em;
  width: 14em;
  left: calc(50% - 7em);
  z-index: 3;
  align-items: center;
  justify-content: space-between;
}

.sliderIndicator {
  height: 1em;
  width: 1em;
  background-color: white;
  opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
  transition: all 200ms;
}

.sliderIndicator[iscurrentslide='true'] {
  opacity: 1;
  transform: scale(1.2);
  box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
}

.sliderIndicator:hover {
  opacity: 1;
}

@media only screen and (max-width: 766px) {
  .sliderContainer {
    padding: 1.5em;
  }

  .sliderTitle {
    margin: 1em 0;
  }
}

@media only screen and (max-width: 720px) {
  .sliderContainer {
    padding: 1.95em;
  }

  .slide {
    height: 28em;
  }

  .slideBlurbTitle {
    margin: 1em 0;
  }

  .slideBlurbContainer {
    bottom: 0;
    width: 100%;
    min-width: 100%;
    height: 55%;
    min-height: fit-content;
    font-size: 10px;
    padding: 1em 2em;
  }

  .slideChange {
    top: 5em;
    height: 3em;
    width: 2em;
  }

  .sliderIndicator {
    display: none;
  }
}
