.af-class-sticky-floorplan-wrapper {
  border-bottom: 1px solid #D6D6D6;
}

.af-class-floorplan-link-list>span>li {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.af-class-floorplan-link-list>span {
  flex: 0 1 100px !important;
  flex-basis: 100px !important;
}

.react-calendar,
.react-date-picker__calendar.react-date-picker__calendar--open {
  z-index: 100;
}

.react-calendar__month-view__days__day--weekend {
  color: #333333 !important;
}

.information-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0;
  margin: 0 0 0 25px;
  border-left: 1px solid rgba(151, 151, 151, 1);
}

.information-links {
  padding: 9px 12px;
  border: 1px solid rgba(123, 164, 218, 1);
  border-radius: 5px;
  margin: 8px 8px;
  width: 196px;
}

.information-links .af-class-link {
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(76, 105, 143, 1);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.information-links .af-class-link svg {
  margin-right: 8px;
  margin-bottom: 0;
  transform: translateY(1px);
}

@media (min-width: 480px) and (max-width: 890px) {

  .react-calendar,
  .react-date-picker__calendar.react-date-picker__calendar--open {
    transform: translate(-10%, 0);
  }
}

@media (max-width: 350px) {

  .react-calendar,
  .react-date-picker__calendar.react-date-picker__calendar--open {
    transform: translate(5%, 0);
  }
}

@media (max-width: 767px) {
  .react-calendar {
    width: 300px;
  }

  .react-date-picker__calendar {
    left: -100%;
    width: 300px;
  }

  .af-class-sticky-floorplan-wrapper .af-class-div-block-180 {
    flex-direction: column;
    justify-content: flex-start;
  }

  .af-class-sticky-floorplan-wrapper .af-class-div-block-208 {
    justify-content: space-around;
    padding-bottom: 15px !important;
    width: 90%;
  }

  .af-class-sticky-floorplan-wrapper .af-class-div-block-180 .af-class-text-block-55 {
    width: 100%;
    padding-bottom: 4px;
  }
}

@media (max-width: 961px) {
  .information-links-container {
    padding: 0;
    margin: 10px 0 10px 0;
    border-left: none;
    flex-wrap: wrap;
    overflow-x: hidden;
  }

  .af-view .af-class-div-block-177 {
    padding-top: 0px;
    padding-bottom: 0px;
    flex-direction: column;
  }

  .af-view .af-class-div-block-208 {
    width: 100%;
    padding: 10px 10px 10px 15px;
    flex-direction: column;
  }

  #additionalFilters,
  #bathroomFilters,
  #bedroomFilters {
    width: 100%;
  }

  #additionalFilters div,
  #bathroomFilters div,
  #bedroomFilters div {
    margin-left: 0;
    margin-top: 21px;
    margin-right: 10px
  }

  .af-class-div-block-180 {
    width: 100%;
    margin: 10px 0 !important;
    padding-right: 10px;
  }

  #property-floorplans-datepicker {
    width: 100%;
  }

  .react-calendar {
    width: 300px;
  }

  .react-date-picker__calendar {
    left: -100%;
    width: 300px;
  }

  .af-class-sticky-floorplan-wrapper .af-class-div-block-180 {
    flex-direction: column;
    justify-content: flex-start;
  }

  .af-class-sticky-floorplan-wrapper .af-class-div-block-208 {
    justify-content: space-around;
    padding-bottom: 15px !important;
  }
}

@media (max-width: 767px) {
  .af-class-div-block-208 {
    flex-direction: column;
  }

  #additionalFilters,
  #bathroomFilters,
  #bedroomFilters {
    width: 100%;
  }

  .af-class-sticky-floorplan-wrapper .af-class-div-block-180 .af-class-text-block-55 {
    width: 100%;
    padding-bottom: 4px;
  }
}