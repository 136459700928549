.af-class-div-block-334 {
    padding-left: 5% !important;
}
.af-class-timeline-header-wrap .af-class-section-tagline,
.af-class-div-block-342 .af-class-section-tagline  {
    color: #7ba4da;
}
@media only screen and (max-width: 991px) {
    .af-class-div-block-334 {
        padding-left: 15% !important;
    }
}

@media (max-width: 767px) {
    .af-view .af-class-div-block-334 {
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 30%) !important;
        padding-top: 200px;
    }
    .af-class-div-block-336 {
        background-size: contain !important;
        background-position: top !important;
        background-repeat: no-repeat !important;
    }
}
@media (max-width: 479px) {
    .af-view .af-class-div-block-334 {
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 8%) !important;
        padding-top: 60px !important;
    }
}