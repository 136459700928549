.af-class-review-container .af-class-icon-quote.af-class-opening.w-embed,
.af-class-review-container .af-class-icon-quote.af-class-closing.w-embed {
  top: 0px;
}
.af-class-slider-review-container {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
      supported by Chrome and Opera */
}
.af-view .af-class-slider-container .af-class-div-block-444 {
  transform: translate(57%, 0);
}
.af-class-slider-review-container .af-class-slider-header {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
@media (max-width: 479px) {
  .af-class-slider-review-container .af-class-icon-quote.af-class-closing.w-embed {
    right: 35% !important;
  }
}
@media (max-width: 320px) {
  .af-class-slider-review-container .af-class-icon-quote.af-class-closing.w-embed {
    width: 60px;
    right: 32% !important;
  }
}
