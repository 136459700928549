.wrapper {
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% !important;
}

.container > span {
  flex: none !important;
  width: 25% !important;
  height: auto !important;
}

@media (max-width: 778px) {
  .container > span {
    width: 50% !important ;
  }
}

@media (min-width: 779px) and (max-width: 1050px) {
  .container > span {
    width: 33.3% !important;
  }
}

@media (max-width: 487px) {
  .container > span {
    width: 100% !important;
  }
  .wrapper {
    padding-top: 0px !important;
  }
}

.container > span > div {
  width: 100% !important;
}
