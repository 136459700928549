.af-view .af-class-about-header-blur-wrapper {
  padding: 0 40px;
  max-width: 550px;
}

@media (max-width: 991px) {
  .af-view .af-class-about-header-blur-wrapper {
    left: 0;
    border-radius: 0px;
    transform: translateX(0);
    margin: 0 auto;
  }
  .af-view .af-class-about-header-title {
    font-size: 56px;
  }
}
@media (max-width: 479px) {
  .af-view .af-class-about-header-blur-wrapper {
    padding: 0 30px;
    max-width: 100%;
  }
  .af-view .af-class-about-header-title {
    font-size: 44px;
  }
}
@media (min-width: 1440px) {
  .af-class-about-header,
  .af-class-about-page-header  {
    min-height: 900px;
    background-position: center bottom !important;
  }
}
