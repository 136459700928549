.sliderWrapper {
    flex: 1;
    max-width: 75vw;
    background-color: #f1f5fb;
}

.image {
    height: 350px;
    margin: 0 55px 0 -40px;
    object-fit: cover;
    width: 450px;
}

.slideContainer {
    cursor: pointer;
}

.slideContainer:focus,
.image:focus {
    outline: none !important;
}

.button {
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 60px;
    -webkit-appearance: none;
}

.button:focus {
    outline: none;
}

.leftArrow {
    transform: rotate(180deg);
}

.buttonWrapper {
    position: absolute;
    top: 150px;
}

.arrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms;
}

.arrowContainer:hover {
    opacity: 0.88;
}
.arrow {
    width: 20px;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #d2d2d2;
    border-radius: 50%;
    margin-left: 10px;
    transition: all 125ms;
    cursor: pointer !important;
}

.selectedDot {
    transform: scale(1.2);
    background-color: #3f3f3f;
}
@media (max-width: 840px) {
    .pagination {
        left: -2px !important;
    }
}
@media (max-width: 775px) {
    .pagination {
        left: -1px !important;
    }
}
@media (max-width: 767px){
    .sliderWrapper {
        max-width: 100vw;
    }
    .prevArrowWrapper {
        position: absolute;
        left: 0;
    }
    .nextArrowWrapper {
        position: absolute;
        right: 0;
    }
    .pagination {
        left: unset !important;
        right: unset !important;
        position: relative !important;
        transform: none !important;
        margin: auto;
    }
    .image {
        margin: 0 10px;
    }
}
@media (max-width: 479px) {
    .image {
        margin: 0px;
    }
}
