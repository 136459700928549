.grid {
  width: 100% !important;
}
.grid > floorplansFloorplanCardViewList0 {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}
.grid > floorplansFloorplanCardViewList0 > span {
  flex: 1 0 31% !important;
  height: auto !important;
  display: block !important;
}
.title {
  z-index: 0 !important;
}
@media (min-width: 1201px) {
  .banner {
    width: 80vw !important;
  }
  .banner {
    object-fit: cover;
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  .title {
    transform: translateX(20%) translateY(-75%) !important;
  }
}
@media (max-width: 767px) and (-ms-high-contrast: none) {
  .grid > floorplansFloorplanCardViewList0 > span {
    flex: 1 0 auto !important;
  }
}
@media (max-width: 600px) and (-ms-high-contrast: none) {
  .title {
    left: 30% !important;
  }
}
.banner {
  width: 45vw;
}
@media (max-width: 1200px) {
  .banner {
    width: 70vw;
  }
}
@media (max-width: 991px) {
  .banner {
    width: 100vw;
  }
  .grid > floorplansFloorplanCardViewList0 > span {
    flex: 1 0 45% !important;
  }
}
@media (max-width: 650px) {
  .grid > floorplansFloorplanCardViewList0 > span {
    flex: 1 0 55% !important;
  }
}
