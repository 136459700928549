@media screen and (max-width: 720px) {
    .largeMap {
      display: none;
    }

    .mobileMap {
      display: block;
    }
  }

  @media screen and (min-width: 721px) {
    .largeMap {
      display: block;
    }

    .mobileMap {
      display: none;
    }
  }
