.af-class-blue-highlight{
  position: relative;
  background-color: #CADBF0;
  padding: 0px 4px;
  margin: 0px -5px;
}

.af-class-div-block-78 {
  padding-bottom: 0!important;
  padding-top: 50px !important;
}

.af-class-content-booklet.af-class-reversed {
  padding-bottom: 0px !important;
}