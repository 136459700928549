.button {
  position: relative;
}

.button > .buttonText {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.button > .buttonArrow {
  transform: translate(10px, -50%);
  transition: all 300ms ease-in-out;
}

.button:hover > .buttonText {
  transform: translateX(-10px);
}

.button:hover > .buttonArrow {
  position: absolute;
  right: 30px;
  left: 80%;
}

.imageContainer {
  max-height: 600px !important;
}


.homeCareersContainer {
    padding: 40px 50px !important;
}

.subtitle {
    color: #7ba4da !important;
    font-size: 10px !important;
    font-weight: 500 !important;
    letter-spacing: 1px !important;
    line-height: 12px !important;
    margin-bottom: 0px !important;
    text-transform: uppercase !important;
}
@media (max-width: 767px) {
.homeCareersContainer {
    transform: translateY(0) !important;
  }
}
@media (max-width: 475px) {
  .homeCareersContainer {
    width: 70vw !important;
    margin: auto;
    bottom: 30px !important;
    }
  }
@media (max-width: 320px) {
  .homeCareersContainer {
    width: 80vw !important;
  }
}