.af-class-date-picker > span {
    flex: none !important;
    width: auto !important;
}
.af-view .af-class-arrow-left--chevron {
    transform: rotate(90deg) translate(0px, -6px);
}

@media (max-width: 479px) {
    .af-view .af-class-grid-step-wrapper.af-class-date-picker {
        align-items: center;
    }
}
@media all and (-ms-high-contrast: none) {
    .af-class-date-range-wrap {
        flex-basis: auto !important;
    }
    .af-view .af-class-arrow-left--chevron {
        transform: rotate(90deg) translate(0px, -4px);
    }
}
